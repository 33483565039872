import React, { Component } from 'react';
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import './style.css';
// defaultValue
class SmartSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.intervalId = null;
    this.progress = 0;
    this.timeout = 500;
    this.myTextInput = null;
  }

  componentWillReceiveProps(nextProps) {
    const { value } = this.state;
    const { defaultValue } = nextProps;
    if (value !== defaultValue) {
      this.setState({
        value: defaultValue || '',
      });
    }
  }

  onChangeSearchWordHandler = (e) => {
    this.setState({
      value: e.target.value,
    });
    clearInterval(this.intervalId);
    this.endInterval = 0;
    this.progress = 0;
    this.progress = 0;
    this.intervalId = setInterval(() => {
      this.endInterval += 10;
      this.progress = (this.endInterval / this.timeout) * 100;

      this.myTextInput.style.width = `${Math.min(this.progress, 100)}%`;

      if (this.endInterval >= this.timeout) {
        clearInterval(this.intervalId);
        this.myTextInput.style.width = '0px';
        this.onSearchHandler();
      }
    }, 10);
  };

  onClearValueHandler = () => {
    this.setState({
      value: '',
    });
    this.props.onSearch('');
  };

  onSearchHandler = () => {
    this.props.onSearch(this.state.value);
  };

  showClearButton = () => {
    const { value } = this.state;
    if (value === '') {
      return false;
    }

    return (
      <Button
        variant="primary"
        onClick={this.onClearValueHandler}
      >
        <i className="far fa-times-circle" />
      </Button>
    );
  };

  render() {
    return (
      <div className="ssi-wrapper">
        <InputGroup>
          <FormControl
            type="text"
            placeholder="Szukaj..."
            value={this.state.value}
            onChange={this.onChangeSearchWordHandler}
          />
          <InputGroup.Append>
            {this.showClearButton()}
            <Button
              variant="success"
              onClick={this.onSearchHandler}
            >
              Szukaj
            </Button>
          </InputGroup.Append>
            <div className="ssi-progress-wrapper">
              <div className="ssi-progress-bar" ref={ref => this.myTextInput = ref} />
            </div>
        </InputGroup>
      </div>
    );
  }
}

export default SmartSearchInput;
