const initState = {
  isOpen: false,
  dialogOpenName: '',
  onConfirmData: null,
};


export default (state = initState, action) => {
  switch (action.type) {
    case 'CONFIRM_DIALOG_CONFIRM_DIALOG': {
      const newState = { ...state };
      newState.isOpen = true;
      newState.dialogOpenName = action.data.dialogOpenName;
      newState.onConfirmData = action.data.data;
      return newState;
    }
    case 'CONFIRM_DIALOG_CLOSE_DIALOG': {
      const newState = { ...state };
      newState.isOpen = false;
      newState.onConfirmData = null;
      newState.dialogOpenName = '';
      return newState;
    }
    default:
      return state;
  }
};
