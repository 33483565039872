import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css';
import logoWhite from './img/logo-white.svg';
import config from '../../config';

class Logo extends Component {
  render() {
    const envName = config.env === 'prod'
      ? ''
      : 'env: dev'
    return (
      <div className="nav-brand navbar-brand">
        <img src={logoWhite} alt="DentMate" />
        {envName}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  login: { ...state.login },
});


export default connect(mapStateToProps)(Logo);
