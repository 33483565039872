import React, { Component } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import connect from 'react-redux/lib/connect/connect';
import Loader from '../../components/Loader';
import PatientAction from '../../actions/PatientAction';
import PatientCard from './PatientCard';
import Visit from './Visit';
import PatientJambotron from '../../components/Patient/PatientJambotron';



class Card extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    PatientAction.getPatient(this.props.match.params.patientId);
  }

  render() {
    const { patientState, user: { user } } = this.props;


    if (!patientState.isLoading && !patientState.success) {
      return (

        <Row className="show-grid">
          <Col xs={12}>
            <Alert variant="warning">
              Pacjenta nie znaleziono
            </Alert>
          </Col>
        </Row>

      );
    }

    if (!patientState.patient.id
      || (patientState.patient.id && +this.props.match.params.patientId !== patientState.patient.id)
    ) {
      return <Loader />;
    }

    return (
      <div className="work-space">
        <PatientJambotron
          patient={patientState.patient}
        />

        <Switch>
          <Route exac path="/panel/patients/patient/:patientId/card/visit/new/:scheduleId" component={Visit} />
          <Route exac path="/panel/patients/patient/:patientId/card/visit" component={Visit} />
          <Route path="/panel/patients/patient/:patientId/card" render={(props) => <PatientCard {...props} patient={patientState.patient} />} />
        </Switch>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  patientState: { ...state.patient },
  user: { ...state.user },
});

export default connect(mapStateToProps)(Card);
