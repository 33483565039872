import React from 'react';
import ToothClass from './ToothClass';

class TripleRoot extends ToothClass {
  render() {

    return (
      <g id="roots">
        <path
          d="m 35.694942,52.859272 c -0.639969,-0.274273 -1.279938,-0.457121 -2.011331,-0.457121 l -8.502442,0.548545 c -1.737058,0.09142 -3.382692,0.182848 -5.11975,0.09142 v -5.211175 h 0.274272 c 3.931237,0 6.765384,-24.958782 4.571206,-31.449894 C 22.621294,9.4328179 25.821138,3.5816747 27.192499,5.0444605 38.894786,17.203868 30.575192,41.796954 34.32358,49.568004 c 0.639969,1.188513 1.005665,2.194178 1.371362,3.291268 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(1, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(1),
            strokeWidth: this.getStrokeWith(1),
          }}
        />
        <path
          d="m 24.815472,16.289626 c 0.548545,1.55421 0.731393,4.114085 0.731393,7.131081 -0.09142,5.394023 -0.731393,11.702287 -1.645634,16.547765 -0.914241,4.479781 -2.194178,7.771049 -3.656964,7.771049 h -0.182848 -0.09143 -0.182848 c -2.285603,0 -4.022661,-6.948232 -4.845478,-14.536434 0,-0.182848 0,-0.274272 -0.09142,-0.45712 0,-0.274273 -0.09142,-0.548545 -0.09142,-0.822817 0,-0.182848 0,-0.365697 -0.09142,-0.548545 -0.548545,-6.21684 -0.457121,-12.342255 0.45712,-15.084979 0.639969,-2.01133 0.822817,-3.931236 0.731393,-5.668295 1.188514,-4.388357 3.016996,-11.06231727 4.022661,-10.42234848 2.102755,1.37136168 3.656965,8.13674608 4.114085,9.96522848 -0.09142,1.737058 0.09143,3.839813 0.822817,6.125415 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(2, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(2),
            strokeWidth: this.getStrokeWith(2),
          }}
        />
        <path
          d="M 15.855909,52.950696 6.8963463,52.402151 c -1.0056653,0 -1.8284823,0.274272 -2.6512993,0.731393 C 4.6107434,51.94503 5.067864,50.847941 5.7078328,49.659428 9.5476456,41.888378 1.045203,17.295292 12.74749,5.1358846 14.027427,3.6730988 17.410119,9.524242 15.124516,16.472475 12.930338,22.872163 15.398789,47.92237 19.695722,47.92237 h 0.274272 v 5.211174 c -1.371361,0 -2.742723,-0.09142 -4.114085,-0.182848 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(3, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(3),
            strokeWidth: this.getStrokeWith(3),
          }}
        />
      </g>
    );
  }
}

export default TripleRoot;
