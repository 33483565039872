import React, { Component } from 'react';
import connect from 'react-redux/lib/connect/connect';
import { Alert } from 'react-bootstrap';
import Loader from '../../components/Loader';
import ClinicAction from '../../actions/ClinicAction';
import TableView from '../../components/TableView';
import LinkButton from '../../components/Button/LinkButton';

class ClinicsList extends Component {
  tableColumns = [
    {
      text: 'Nazwa kliniki',
      property: 'name',
    },
    {
      text: 'Miasto',
      property: 'city',
    },
    {
      text: 'Adres',
      property: 'id',
      onText: item => `${item.street} ${item.streetNumber}`,
    },
  ];

  buttons = [
    {
      button: item => (
        <LinkButton
          key={`p-edit-${item.id}`}
          to={`/panel/clinics/${item.id}/detail`}
          className="link-edit-clinic"
          variant="dark"
        >
          Szczegóły
        </LinkButton>
      ),
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isError: false,
      clinics: [],
    };
  }

  componentDidMount() {
    const { user } = this.props;
    ClinicAction.promiseGetUserOwnClinics(user.id).then((data) => {
      this.setState({ isLoading: false, clinics: data.data });
    })
      .catch(() => this.setState({ isLoading: false, isError: true }));
  }

  returnData() {
    const { isLoading, isError, clinics } = this.state;

    if (isError) {
      return (
        <Alert variant="danger">
          Coś poszło nie tak.
        </Alert>
      );
    }

    if (isLoading) {
      return <Loader />;
    }

    return (
      <TableView
        paginator={false}
        columns={this.tableColumns}
        items={clinics}
        buttons={this.buttons}
      />
    );
  }

  render() {
    return (
      <div>
        {this.returnData()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: { ...{ ...state.user }.user },
});

export default connect(mapStateToProps)(ClinicsList);
