import React, { Component } from 'react';
import RootOverlay from './RootOverlay';
import './page-overlay.css';

class PageOverlay extends Component {
  onCloseHandler = (e) => {
    e.stopPropagation();
    this.props.onClose(e);
  }

  render() {
    return (
      <RootOverlay>
        <div
          className="page-overlay"
          onClick={this.onCloseHandler}
        >
          <div
            className="page-overlay-content"
            onClick={(e) => e.stopPropagation()}
          >
            {this.props.children}
          </div>
        </div>
      </RootOverlay>
    );
  }
}

export default PageOverlay;
