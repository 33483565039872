import React, { Component } from 'react';
import {
  Form,
  InputGroup,
} from 'react-bootstrap';

import InputMessages from '../../containers/FormWizard/InputMessages';

class Input extends Component {
  constructor(props) {
    super(props);
    const { inputElement } = this.props;

    this.state = {
      inputValue: inputElement.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { inputElement } = this.props;

    if (nextProps.inputElement.value !== inputElement.value) {
      this.setState({ inputValue: nextProps.inputElement.value });
    }
  }

  onChangeHandler = (value) => {
    this.props.onChange(value);
    this.setState({ inputValue: value });
  };

  renderInput = (inputElement, disabled, inputValue, maxlength, className) => {
    const { transformations } = this.props;
    let classes = className || '';
    if (inputElement.require) {
      classes += ' required';
    }

    if (transformations && transformations.length) {
      transformations.forEach(transformFunction => inputValue = transformFunction(inputValue));
    }

    return (
      <Form.Control
        type={inputElement.type || 'text'}
        component={inputElement.type === 'textarea' ? 'textarea' : 'input'}
        placeholder={inputElement.placeholder || ''}
        disabled={disabled}
        value={inputValue}
        onChange={e => this.onChangeHandler(e.target.value)}
        maxLength={maxlength || null}
        className={classes || null}
        {...inputElement.attributes}
      />
    );
  };

  renderInputWithAddon = (inputElement, disabled, inputValue, maxlength, className) => (
    <InputGroup>
      {inputElement.addonLeft && <InputGroup.Text>{inputElement.addonLeft}</InputGroup.Text>}
      {this.renderInput(inputElement, disabled, inputValue, maxlength, className)}
      {inputElement.addonRight && <InputGroup.Text>{inputElement.addonRight}</InputGroup.Text>}
    </InputGroup>
  );

  returnInput = (inputElement, disabled, inputValue, maxlength, className) => {
    if (inputElement.addonLeft || inputElement.addonRight) {
      return this.renderInputWithAddon(inputElement, disabled, inputValue, maxlength, className);
    }
    return this.renderInput(inputElement, disabled, inputValue, maxlength, className);
  };

  render() {
    const {
      inputElement,
      disabled,
      maxlength,
      className,
    } = this.props;
    const {
      validationMessages,
      label,
    } = inputElement;
    const { inputValue } = this.state;
    return (
      <Form.Group
        disabled={disabled}
      >
        {label && <Form.Label>{label}:</Form.Label>}
        {this.returnInput(inputElement, disabled, inputValue, maxlength, className)}
        <InputMessages messages={validationMessages} />
      </Form.Group>
    );
  }
}

export default Input;
