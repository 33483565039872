import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Main from './routes';
import * as serviceWorker from './serviceWorker';


import plTranslations from './translations/pl/translations.json';


i18next.init({
  interpolation: { escapeValue: false },
  lng: 'pl',
  defaultNS: 'common',
  resources: {
    pl: {
      common: plTranslations,
    },
  },
});


ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Main />
  </I18nextProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
