import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Dropdown, DropdownButton } from 'react-bootstrap';
import InputMessages from '../../containers/FormWizard/InputMessages';

import './style.css';

class Select extends Component {
  onChangeHandler = (value) => {
    this.props.onChange(value);
  };

  render() {
    const { inputElement, t } = this.props;
    const {
      validationMessages,
      label,
      require,
      value
    } = inputElement;

    const requireText = require ? '*' : '';
    let selectedLabel = null;
    const selectedLabels = inputElement.values.filter((item) => {
        return `${item.value}` === `${value}`;
    });

    if (selectedLabels.length) {
      selectedLabel = t(selectedLabels[0].label);
    }

    return (
      <Form.Group>
        <div className="select-box-elements">
          <Form.Label>
            {t(label)}
            {requireText}
            :
          </Form.Label>
          <DropdownButton
            title={(selectedLabel || inputElement.placeholder) || ''}
            onSelect={value => this.onChangeHandler(value)}
          >
            {inputElement.values.map(item => (<Dropdown.Item eventKey={item.value} key={item.value}>{t(item.label)}</Dropdown.Item>))}
          </DropdownButton>
        </div>
        <InputMessages messages={validationMessages} />
      </Form.Group>
    );
  }
}

export default withTranslation()(Select);
