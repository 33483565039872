import React, { Component } from 'react';
import Switch from 'react-switch';
import { Form } from 'react-bootstrap';
import InputMessages from '../../containers/FormWizard/InputMessages';

import './style.css';

class SwitchBox extends Component {
  onChangeHandler = (value) => {
    this.props.onChange(value);
  };

  render() {
    const { inputElement } = this.props;
    const {
      validationMessages,
      label,
    } = inputElement;
    return (
      <Form.Group>
        <div className="switch-box-elements">
          <span className="label-span">{label}</span>
          <Switch
            onChange={this.onChangeHandler}
            checked={inputElement.value}
            {...inputElement.attributes}
          />
        </div>
        <InputMessages messages={validationMessages} />
      </Form.Group>
    );
  }
}

export default SwitchBox;
