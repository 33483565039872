import BaseAction from './BaseAction';


class ConfirmDialogAction extends BaseAction {
  classPrefix = 'CONFIRM_DIALOG';

  openDialog = (dialogOpenName, data) => {
    const actionName = `${this.classPrefix}_CONFIRM_DIALOG`;
    this.makeDispatch(actionName, {
      dialogOpenName,
      data,
    });
  };

  closeDialog = () => {
    const actionName = `${this.classPrefix}_CLOSE_DIALOG`;
    this.makeDispatch(actionName, []);
  };
}

export default new ConfirmDialogAction();
