import React from 'react';
import ToothClass from './ToothClass';

class CrownB extends ToothClass {
  render() {
    return (
      <g id="crown">
        <path
          d="m 37.526098,53.608642 c 1.384859,1.088104 2.176206,2.571881 2.176206,4.352413 l -0.395673,7.616722 c -0.197837,3.85782 -0.197837,7.71564 0,11.474542 l 0.494592,7.319967 c 0,1.780532 -0.989185,3.363228 -2.176206,4.352412 L 20.01753,71.117211 Z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(4, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(4),
            strokeWidth: this.getStrokeWith(4),

          }}
        />
        <path
          d="m 5.5754334,89.713883 c -1.2859401,0 -2.2751248,-0.395674 -3.2643095,-1.088103 L 19.819693,71.117211 37.526098,88.724698 c -0.989184,0.593511 -1.978369,1.088103 -3.264309,1.088103 L 23.380757,89.120372 c -2.077287,-0.09892 -4.253494,-0.09892 -6.330781,0 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(5, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(5),
            strokeWidth: this.getStrokeWith(5),
          }}
        />
        <path
          d="M 2.4100424,88.724698 C 1.0251839,87.636595 0.13491767,86.0539 0.13491767,84.273367 l 0.49459234,-9.69401 c 0.0989185,-2.176206 0.0989185,-4.352412 0,-6.627537 L 0.13491767,57.961055 c 0,-1.780532 0.79134774,-3.363228 2.17620623,-4.352413 L 19.819693,71.117211 Z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(6, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(6),
            strokeWidth: this.getStrokeWith(6),
          }}
        />
        <path
          d="M 20.01753,71.117211 2.4100424,53.608642 c 0.9891847,-0.59351 1.9783694,-1.088103 3.2643094,-1.088103 l 9.6940102,0.593511 c 3.363227,0.197837 6.627537,0.197837 9.891846,0 l 9.199418,-0.593511 c 1.28594,0 2.275124,0.395674 3.264309,1.088103 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(7, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(7),
            strokeWidth: this.getStrokeWith(7),
          }}
        />
        <circle
          cx="20.017529"
          cy="71.11721"
          r="9.0015812"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(8, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(8),
            strokeWidth: this.getStrokeWith(8),
          }}
        />
      </g>
    );
  }
}

export default CrownB;
