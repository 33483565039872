import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import 'react-credit-cards/es/styles-compiled.css';

import Input from '../../components/Inputs/Input';
import SwitchBox from '../../components/Inputs/SwitchBox';
import Radio from '../../components/Inputs/Radio';
import Loader from '../../components/Loader';
import SaveButton from '../../components/SaveButton';
import FormsAction from '../../actions/FormsAction';
import formSelector from '../../helpers/selectors/formSelector';
import DateTimeBox from '../../components/Inputs/DateTimeBox';
import Select from '../../components/Inputs/Select';
import SearchPatient from './SearchPatient';


import './style.css';
import DateTime from '../../helpers/DateTime';
import PhoneNumber from '../../components/Inputs/PhoneNumber';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsOk: false,
      formIsLoading: false,
    };

    FormsAction.registerForm('RegisterPatient').then(() => {
      this.setState({ formIsOk: true });
      this.updateFormValuesWhenFormIsLoaded();
      const { showRootClinic } = this.props;
      if (showRootClinic) {
        FormsAction.updateInput('RegisterPatient', 'rootClinicId', FormsAction.ATTRIBUTE_REQUIRE, true);
      }
    });
  }

  updateFormValuesWhenFormIsLoaded = () => {
    const { patient } = this.props;

    if (patient === undefined) {
      return;
    }
    const date = patient.birthDate ? new Date(patient.birthDate) : undefined;

    FormsAction.updateValue('RegisterPatient', 'patientName', patient.name);
    FormsAction.updateValue('RegisterPatient', 'patientLastName', patient.lastName);
    FormsAction.updateValue('RegisterPatient', 'patientEmail', patient.email);
    FormsAction.updateValue('RegisterPatient', 'patientPhone', patient.phone);
    FormsAction.updateValue('RegisterPatient', 'optionalPhoneNumber', patient.optionalPhone);
    FormsAction.updateValue('RegisterPatient', 'patientSex', patient.sex);
    FormsAction.updateValue('RegisterPatient', 'patientPersonalId', patient.personId);
    FormsAction.updateValue('RegisterPatient', 'patientBirthDate', DateTime.dateToDateString(date));

    const { guardian } = patient;

    if (guardian !== undefined && guardian.id) {
      FormsAction.updateValue('RegisterPatient', 'guardianIsNeeded', true);
      FormsAction.updateValue('RegisterPatient', 'guardianName', guardian.name);
      FormsAction.updateValue('RegisterPatient', 'guardianLastName', guardian.lastName);
      FormsAction.updateValue('RegisterPatient', 'guardianEmail', guardian.email);
      FormsAction.updateValue('RegisterPatient', 'guardianPhone', guardian.phone);
      if (guardian.patientAsGuardianId > 0) {
        FormsAction.updateValue('RegisterPatient', 'guardianInSystem', true);
        FormsAction.updateValue('RegisterPatient', 'guardianOutsideSystem', null);
      } else {
        FormsAction.updateValue('RegisterPatient', 'guardianInSystem', null);
        FormsAction.updateValue('RegisterPatient', 'guardianOutsideSystem', true);
      }
    }
  };

  onSubmitHandler = () => {
    const { registerForm, onSubmit } = this.props;
    FormsAction.checkFormValidation('RegisterPatient');

    if (registerForm.isValid) {
      onSubmit(registerForm);
    }
  };

  updateValue = (name, value) => {
    FormsAction.updateValue('RegisterPatient', name, value);
  };

  updateGuardianType = (name, value) => {
    if (name === 'guardianInSystem' && value === true) {
      FormsAction.updateValue('RegisterPatient', 'guardianInSystem', true);
      FormsAction.updateValue('RegisterPatient', 'guardianOutsideSystem', null);
      FormsAction.updateAttribute('RegisterPatient', 'guardianName', FormsAction.ATTRIBUTE_DISABLED, true);
      FormsAction.updateAttribute('RegisterPatient', 'guardianLastName', FormsAction.ATTRIBUTE_DISABLED, true);
      FormsAction.updateAttribute('RegisterPatient', 'guardianPhone', FormsAction.ATTRIBUTE_DISABLED, true);
      FormsAction.updateAttribute('RegisterPatient', 'guardianEmail', FormsAction.ATTRIBUTE_DISABLED, true);
    } else {
      FormsAction.updateValue('RegisterPatient', 'guardianInSystem', null);
      FormsAction.updateValue('RegisterPatient', 'guardianOutsideSystem', true);
      FormsAction.updateValue('RegisterPatient', 'guardianUserId', null);
      FormsAction.updateAttribute('RegisterPatient', 'guardianName', FormsAction.ATTRIBUTE_DISABLED, false);
      FormsAction.updateAttribute('RegisterPatient', 'guardianLastName', FormsAction.ATTRIBUTE_DISABLED, false);
      FormsAction.updateAttribute('RegisterPatient', 'guardianPhone', FormsAction.ATTRIBUTE_DISABLED, false);
      FormsAction.updateAttribute('RegisterPatient', 'guardianEmail', FormsAction.ATTRIBUTE_DISABLED, false);
    }
  };

  onSelectCallback = (item) => {
    FormsAction.updateValue('RegisterPatient', 'guardianUserId', +item.id);
    FormsAction.updateValue('RegisterPatient', 'guardianName', item.name);
    FormsAction.updateValue('RegisterPatient', 'guardianLastName', item.lastName);
    FormsAction.updateValue('RegisterPatient', 'guardianPhone', item.phoneNumber);
    FormsAction.updateValue('RegisterPatient', 'guardianEmail', item.email);
  };

  onChangeGuardianIsNeeded = (value) => {
    this.updateValue('guardianIsNeeded', value);

    if (value) {
      FormsAction.updateInput('RegisterPatient', 'guardianName', FormsAction.ATTRIBUTE_REQUIRE, true);
      FormsAction.updateInput('RegisterPatient', 'guardianLastName', FormsAction.ATTRIBUTE_REQUIRE, true);
      FormsAction.updateInput('RegisterPatient', 'guardianPhone', FormsAction.ATTRIBUTE_REQUIRE, true);
      FormsAction.updateInput('RegisterPatient', 'guardianEmail', FormsAction.ATTRIBUTE_REQUIRE, true);
    } else {
      FormsAction.updateInput('RegisterPatient', 'guardianName', FormsAction.ATTRIBUTE_REQUIRE, false);
      FormsAction.updateInput('RegisterPatient', 'guardianLastName', FormsAction.ATTRIBUTE_REQUIRE, false);
      FormsAction.updateInput('RegisterPatient', 'guardianPhone', FormsAction.ATTRIBUTE_REQUIRE, false);
      FormsAction.updateInput('RegisterPatient', 'guardianEmail', FormsAction.ATTRIBUTE_REQUIRE, false);
    }
  };

  showRootClinic = () => {
    const { showRootClinic, userClinics, registerForm } = this.props;

    if (!showRootClinic) {
      return null;
    }

    registerForm.fields.rootClinicId.values = userClinics.userAllClinics.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });

    return (
      <Row>
        <Col md={12} sm={12}>
          <FormGroup controlId="formControlsSelect">
            <Select
              onChange={value => this.updateValue('rootClinicId', value)}
              inputElement={registerForm.fields.rootClinicId}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }

  render() {
    const { formIsOk } = this.state;
    if (!formIsOk) {
      return <Loader />;
    }

    const { registerForm, formIsLoading } = this.props;
    const guardianBoxDisplay = registerForm.fields.guardianIsNeeded.value
      ? 'block'
      : 'none';

    const guardianSearchBoxDisplay = registerForm.fields.guardianInSystem.value
      ? 'block'
      : 'none';

    const guardianBoxStyle = {
      display: guardianBoxDisplay,
    };

    const guardianSearchBoxStyle = {
      display: guardianSearchBoxDisplay,
    };

    return (
      <div className="register-form-patient">
        {this.showRootClinic()}
        <Row>
          <Col md={6} sm={12}>
            <div className="borderSeparatorLeft">
              <h4>
                Dane podstawowe
              </h4>

              <Row>
                <Col md={6} sm={12}>
                  <Form.Label>Imie<small>*</small></Form.Label>
                  <Input
                    inputElement={registerForm.fields.patientName}
                    onChange={value => this.updateValue('patientName', value)}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>Nazwisko<small>*</small></Form.Label>
                  <Input
                    inputElement={registerForm.fields.patientLastName}
                    onChange={value => this.updateValue('patientLastName', value)}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6} sm={12}>
                  <Form.Label>PESEL<small>*</small></Form.Label>
                  <Input
                    inputElement={registerForm.fields.patientPersonalId}
                    onChange={value => this.updateValue('patientPersonalId', value)}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>Data urodzenia</Form.Label>
                  <DateTimeBox
                    inputElement={registerForm.fields.patientBirthDate}
                    onChange={value => this.updateValue('patientBirthDate', value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Select
                    onChange={value => this.updateValue('patientSex', value)}
                    inputElement={registerForm.fields.patientSex}
                  />
                </Col>
                <Col md={6} sm={12} />
              </Row>

              <h4>
                Dane opiekuna
              </h4>
              <Row>
                <Col md={6} sm={12}>
                  <SwitchBox
                    inputElement={registerForm.fields.guardianIsNeeded}
                    onChange={this.onChangeGuardianIsNeeded}
                  />
                </Col>
                <Col md={6} sm={12} />
              </Row>
              <div className="guardian-box" style={guardianBoxStyle}>
                <Row>
                  <Col md={6} sm={12}>
                    <Radio
                      inputElement={registerForm.fields.guardianInSystem}
                      onChange={value => this.updateGuardianType('guardianInSystem', value)}
                    />
                  </Col>
                  <Col md={6} sm={12} />
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Radio
                      inputElement={registerForm.fields.guardianOutsideSystem}
                      onChange={value => this.updateGuardianType('guardianOutsideSystem', value)}
                    />
                  </Col>
                  <Col md={6} sm={12} />
                </Row>
                <div className="search-guardian-box" style={guardianSearchBoxStyle}>
                  <SearchPatient
                    onSelect={this.onSelectCallback}
                  />
                </div>
                <Row>
                  <Col md={6} sm={12}>
                    <Input
                      inputElement={registerForm.fields.guardianName}
                      onChange={value => this.updateValue('guardianName', value)}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <Input
                      inputElement={registerForm.fields.guardianLastName}
                      onChange={value => this.updateValue('guardianLastName', value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <PhoneNumber
                      inputElement={registerForm.fields.guardianPhone}
                      onChange={value => this.updateValue('guardianPhone', value)}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <Input
                      inputElement={registerForm.fields.guardianEmail}
                      onChange={value => this.updateValue('guardianEmail', value)}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="borderSeparatorRight">
              <h4>
                Dane kontaktowe
              </h4>
              <Form.Label>Numer telefonu<small>*</small></Form.Label>
              <Row>
                <Col md={6} sm={12}>
                  <PhoneNumber
                    inputElement={registerForm.fields.patientPhone}
                    onChange={value => this.updateValue('patientPhone', value)}
                  />
                </Col>
                <Col md={6} sm={12} />
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <PhoneNumber
                    inputElement={registerForm.fields.optionalPhoneNumber}
                    onChange={value => this.updateValue('optionalPhoneNumber', value)}
                  />
                </Col>
                <Col md={6} sm={12} />
              </Row>
              <Form.Label>E-mail</Form.Label>
              <Row>
                <Col md={6} sm={12}>
                  <Input
                    inputElement={registerForm.fields.patientEmail}
                    onChange={value => this.updateValue('patientEmail', value)}
                  />
                </Col>
                <Col md={6} sm={12} />
              </Row>
            </div>
          </Col>
        </Row>
        <div className="margin-div" />
        <Row bsClass="row">
          <Col xs={{ span: 6, offset: 3 }}>
            <SaveButton
              variant="success"
              size="large"
              block
              disabled={!registerForm.isValid}
              text="Zapisz"
              onSave={this.onSubmitHandler}
              isLoading={formIsLoading}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  registerForm: formSelector(state.forms, 'RegisterPatient'),
  register: state.register,
});


export default connect(mapStateToProps)(RegisterForm);
