import React, { Component } from 'react';

import './element-overall.css';

class ElementOverallNotInteractive extends Component {
  onClickCallback = () => {
    const { onClick } = this.props;

    if (typeof onClick === 'function') {
      onClick();
    }
  }

  render() {
    const { buttonText } = this.props;
    return (
      <div className="element-overall">
        <div>
          {buttonText}
        </div>
      </div>
    );
  }
}

export default ElementOverallNotInteractive;
