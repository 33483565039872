import React, { Component } from 'react';
import FileAction from '../../actions/FileAction';
import Loader from '../Loader';
import './image-viewer.css';

class ImageViewer extends Component {

  isLoaded = false;

  constructor(props) {
    super(props);

    this.state = {
      pdfIsReady: false,
      attachment: null,
    };
  }

  componentDidMount() {
    this.downloadDocument();
  }

  downloadDocument() {
    const { attachment } = this.props;
    FileAction.download(attachment.id)
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            attachmentIsReady: true,
            attachment: response.data,
          });
        }
      });
  }

  render() {
    const { attachmentIsReady, attachment, pages, page, scale } = this.state;
    if (!attachmentIsReady) {
      return <Loader />;
    }

    return (
      <div className="image-viewer-content">
        <img src={`data:image/png;base64, ${attachment}`} alt="image" />
      </div>
    );
  }
}

export default ImageViewer;
