import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import moment from 'moment';
import 'moment/locale/pl';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import plLocale from '@fullcalendar/core/locales/pl';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import ScheduleAction from '../../actions/ScheduleAction';
import CalendarAuthorization from '../../helpers/Calender/CalendarAuthorization';
import DateTime from '../../helpers/DateTime';
import ClinicAction from '../../actions/ClinicAction';
import UserWorkingDay from '../User/UserWorkingDay';
import CalendarAction from '../../actions/CalendarAction';
import NewEventModal from './NewEventModal';
import UserWorkingDayAction from '../../actions/UserWorkingDayAction';
import prepareBusinessHours from './Library/prepareBusinessHours';
import CustomButtonSelectClinic from './Helpers/CustomButtonSelectClinic';
import CustomButtonSelectDentist from './Helpers/CustomButtonSelectDentist';
import EventWrapper from './Helpers/EventWrapper';
import EventPopup from './Helpers/EventPopup';
import monthEventsView from '../../helpers/Calender/monthEventsView';
import eventsFilter from '../../helpers/Calender/eventsFilter';
import './style.css';


class Calender extends Component {
  calendarComponentRef = React.createRef();
  lastSelectedDate = null;
  currentSelectedStartDate = null;
  currentSelectedEndDate = null;
  calendarSettingsIsLoaded = false;
  firstLoadDates = false;
  popupTop = 0;
  popupLeft = 0;

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      newEventFrom: '',
      newEventTill: '',

      showPopupInfo: false,
      eventToShow: {},
      lastViewType: '',
    };
    this.myCalenderDiv = React.createRef();
    this.lastSelectedDate = moment();
  }

  componentDidMount() {
    const { user: { user, selectedUser }, userClinics: { userAllClinics, userAllClinicsLoading } } = this.props;

    const node = this.myCalenderDiv.current;
    const windowHeight = window.innerHeight - 50;
    this.setState({
      divHeight: `${windowHeight - node.getBoundingClientRect().top}`,
    });

    if (user.id && userAllClinics.length === 0 && !userAllClinicsLoading) {
      ClinicAction.getUserClinics(user.id);
    }

    if (selectedUser.id && userAllClinics.length && !this.calendarSettingsIsLoaded) {
      CalendarAction.getCalendarSettingsAndUpdateState(selectedUser.id, userAllClinics[0].id);
      this.calendarSettingsIsLoaded = true;
    }
  }

  componentWillReceiveProps(nextProps) {
    const { schedule, user, userClinics } = this.props;

    let selectFor = user.selectedUser.id;

    if (user.selectedUser && user.selectedUser.id !== nextProps.user.selectedUser.id) {
      selectFor = nextProps.user.selectedUser.id;
    }

    if ((
      selectFor
      && nextProps.userClinics.selectedClinic
      && userClinics.selectedClinic
      && nextProps.userClinics.selectedClinic.id !== userClinics.selectedClinic.id
    )
      || (selectFor && selectFor !== user.selectedUser.id)
    ) {
      this.calendarSettingsIsLoaded = true;
      CalendarAction.getCalendarSettingsAndUpdateState(selectFor, nextProps.userClinics.selectedClinic.id);
    }

    if ((nextProps.schedule.shouldBeReload
      && nextProps.schedule.shouldBeReload !== schedule.shouldBeReload)
      || (user.selectedUser && user.selectedUser.id !== nextProps.user.selectedUser.id)
    ) {
      const begin = DateTime.momentStartOfWeek(this.lastSelectedDate);
      const end = DateTime.momentEndOfWeek(this.lastSelectedDate);
      this.currentSelectedStartDate = begin;
      this.currentSelectedEndDate = end;

      this.setState({
        showModal: false,
      });
    }
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  /** adding new event */
  onSelectHandler = (e) => {
    const { showPopupInfo } = this.state;

    if (showPopupInfo) {
      this.hidePopup();
      return;
    }

    this.setState({
      showModal: true,
      newEventFrom: e.start,
      newEventTill: e.end,
    });
  };

  onCalenderChangeDate = (dateInfo) => {
    const { user } = this.props;
    const { lastViewType } = this.state;
    this.currentSelectedStartDate = dateInfo.start;
    this.currentSelectedEndDate = dateInfo.end;

    if (lastViewType !== dateInfo.view.type) {
      this.setState({
        lastViewType: dateInfo.view.type,
      });
    }

    if (user.selectedUser.id) {
      this.firstLoadDates = true;
      ScheduleAction.getListByUser(
        user.selectedUser.id,
        DateTime.dateToDateString(dateInfo.start),
        DateTime.dateToDateString(dateInfo.end),
      );
    }
  };

  updateEvent = (newEvent) => {
    const { user } = this.props;
    const saveEvent = {
      ...newEvent._def.extendedProps,
      dateFrom: DateTime.dateToDateTimeString(newEvent.start),
      dateTill: DateTime.dateToDateTimeString(newEvent.end),
    }

    ScheduleAction.promiseUpdateEvent(
      newEvent.id,
      saveEvent
    )
    .then(() => {
      ScheduleAction.refreshLastGetListForNewUser(user.selectedUser.id);
    });


    return true;
  }

  handleEventClick = (e) => {
    if (e.view.type === 'dayGridMonth') {
      return;
    }

    const dentEvent = { id: e.event.id, ...e.event.extendedProps }

    const wrapperElement = document.getElementById('rbc-calendar-wrapper-id');
    const wrapperViewPort = wrapperElement.getBoundingClientRect();
    const element = e.el.parentElement;
    const viewPort = element.getBoundingClientRect();
    this.popupTop = viewPort.top - wrapperViewPort.top;
    this.popupLeft = viewPort.left - wrapperViewPort.left;
    this.width = element.offsetWidth;
    this.setState({
      showPopupInfo: true,
      eventToShow: dentEvent,
    });
  }

  hidePopup = () => {
    this.setState({
      showPopupInfo: false,
    });
  }

  getNearestFreeTermin = () => {
    const { user, userClinics } = this.props;
    CalendarAction.promiseGetNearestFreeTermin(user.selectedUser.id, userClinics.selectedClinic.id)
      .then((response) => {
        if (response) {
          const calendarApi = this.calendarComponentRef.current.getApi()
          calendarApi.gotoDate(response.data);
        }
      });
  }

  renderPopupInfo = () => {
    const { showPopupInfo, eventToShow } = this.state;
    if (!showPopupInfo || !eventToShow.id) {
      return null;
    }

    return (
      <EventPopup
        top={this.popupTop}
        left={this.popupLeft}
        width={this.width}
        dentEvent={eventToShow}
        showPopupInfo={showPopupInfo}
        onCloseEventPopup={() => {
          this.setState({
            eventToShow: {},
          })
        }}
        onCancelEventPopup={() => {
          this.setState({
            eventToShow: {},
          })
        }}
      />
    )
  }

  render() {
    const {
      user,
      schedule,
      calender,
      userClinics: { selectedClinic },
    } = this.props;
    const {
      showModal,
      newEventFrom,
      newEventTill,
      lastViewType,
    } = this.state;

    const timeMin = new Date();
    const timeMax = new Date();

    if (!selectedClinic.id) {
      timeMin.setHours(0, 0);
      timeMax.setHours(23, 0);
    } else {
      const from = DateTime.splitTime(selectedClinic.openFrom);
      const to = DateTime.splitTime(selectedClinic.openTo);
      timeMin.setHours(from.hours, from.minutes);
      timeMax.setHours(to.hours, to.minutes);
    }

    const formattedTimeStep = calender.settings.timeStep < 10
      ? `0${calender.settings.timeStep}}`
      : calender.settings.timeStep;

    let events = schedule.events;

    if (lastViewType === 'dayGridMonth') {
      events = monthEventsView(selectedClinic, events);
    }

    events = eventsFilter(events, selectedClinic.id, user.selectedUser.id);

    const customButtons = {
      nearestFreeTermin: {
        bootstrapFontAwesome: 'fa-calendar-day',
        click: () => {
          this.getNearestFreeTermin();
        }
      },
      selectClinic: {
        text: (<CustomButtonSelectClinic />),

        click: () => {}
      },
      selectDentist: {
        text: (<CustomButtonSelectDentist />),

        click: () => {}
      },
    };
    let hederToolbarEnd = 'today,timeGridDay,timeGridWeek,dayGridMonth,nearestFreeTermin';
    // let hederToolbarEnd = 'today,timeGridDay,timeGridWeek,dayGridMonth';
    if (user.user.type === 'dentist') {
      hederToolbarEnd = 'today,timeGridDay,timeGridWeek,dayGridMonth,nearestFreeTermin,configModal';
      // hederToolbarEnd = 'today,timeGridDay,timeGridWeek,dayGridMonth,configModal';
      customButtons.configModal = {
        bootstrapFontAwesome: 'fa-cog',
        click: () => {
          UserWorkingDayAction.openModal();
        }
      }
    }

    return (
      <div
        className="rbc-calendar-wrapper"
        id="rbc-calendar-wrapper-id"
        ref={this.myCalenderDiv}
      >
        <FullCalendar
          themeSystem="bootstrap"
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, bootstrapPlugin]}
          customButtons={customButtons}
          headerToolbar={{
            start: 'selectClinic,selectDentist', // will normally be on the left. if RTL, will be on the right
            center: 'prev title next',
            end: hederToolbarEnd // will normally be on the right. if RTL, will be on the left
          }}
          slotMinTime={DateTime.dateToTimeString(timeMin)}
          slotMaxTime={DateTime.dateToTimeString(timeMax)}
          firstDay={1}
          allDaySlot={false}
          slotDuration={`00:${formattedTimeStep}:00`}
          expandRows={true}
          selectable={true}
          height={'95%'}
          eventStartEditable={true}
          selectOverlap={false}
          eventOverlap={false}
          slotEventOverlap={false}
          selectConstraint="businessHours"
          eventConstraint="businessHours"
          locale={plLocale}
          nowIndicator={true}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
          }}
          editable={true}
          displayEventEnd={false}

          events={events}
          select={event => this.onSelectHandler(event, schedule.events)}
          titleFormat={
            { year: 'numeric', month: 'long', day: 'numeric' }
          }
          datesSet={this.onCalenderChangeDate}
          businessHours={prepareBusinessHours(calender.settings.clinicWorkingHoursCollection)}
          eventDrop={(e) => this.updateEvent(e.event)}
          eventResize={(e) => this.updateEvent(e.event)}
          eventClick={this.handleEventClick}
          eventContent={(e) => {
            return <EventWrapper fcEvent={e} calendarRef={this.calendarComponentRef} />
          }}
          eventClassNames={(e) => e.event.extendedProps.status}
          eventAllow={(e, b) => CalendarAuthorization.canEditScheduledEvent(b.extendedProps.status)}
          ref={this.calendarComponentRef}
          dateClick={(e) => {
            if (e.view.type === 'dayGridMonth') {
              const calendarApi = this.calendarComponentRef.current.getApi();
              calendarApi.changeView('timeGridDay');
              calendarApi.gotoDate(e.date);
            }
          }}
        />
        {this.renderPopupInfo()}
        <UserWorkingDay />
        <NewEventModal
          showModal={showModal}
          newEventFrom={newEventFrom}
          newEventTill={newEventTill}
          onCloseModal={this.handleCloseModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  schedule: state.schedule,
  user:  state.user ,
  userClinics: state.userClinics,
  calender: state.calender,
});

export default connect(mapStateToProps)(Calender);
