import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JewUpPart from './JewUpPart';
import JewDownPart from './JewDownPart';
import JewTeethPart from './JewTeethPart';

class Jew extends Component {
  static JEW_PART_RATIO = 0.09; // top and bottom jew bar

  render() {
    const {
      jewContainer,
      graphModeTeeth,
      onToothSelect,
      selectedTeeth,
      teeth,
      clickable,
      proceduresList,
    } = this.props;
    const jewContainerStyle = {
      width: jewContainer.width,
      height: jewContainer.height,
    };

    const jewPartHeight = jewContainer.height * Jew.JEW_PART_RATIO;
    const jewJewTeethPartHeight = jewContainer.height - (jewPartHeight * 2);

    return (
      <div
        className="jew-container"
        style={jewContainerStyle}
      >
        <JewUpPart
          width={jewContainer.width}
          height={jewPartHeight}
        />
        <JewTeethPart
          proceduresList={proceduresList}
          width={jewContainer.width}
          height={jewJewTeethPartHeight}
          top={jewPartHeight}
          graphModeTeeth={graphModeTeeth}
          onToothSelect={onToothSelect}
          selectedTeeth={selectedTeeth}
          teeth={teeth}
          clickable={clickable}
        />
        <JewDownPart
          width={jewContainer.width}
          height={jewPartHeight}
        />
      </div>
    );
  }
}

Jew.propTypes = {
  graphModeTeeth: PropTypes.string,
  jewContainer: PropTypes.object,
  proceduresList: [],
};

Jew.defaultProps = {
  graphModeTeeth: 'milky',
  jewContainer: {},
  proceduresList: [],
};


export default Jew;
