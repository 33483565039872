import React from 'react';
import ToothClass from './ToothClass';

class Crown extends ToothClass {
  render() {
    return (
      <g id="crown">
        <path
          d="m 2.378226,53.572128 c -1.3238674,1.018359 -2.24039108,2.647735 -2.24039108,4.378946 l 0.5091798,7.637697 c 0.20367192,3.869766 0.20367192,7.739533 0,11.507463 l -0.5091798,7.33219 c 0,1.731211 0.91652368,3.360586 2.24039108,4.378946 L 19.995847,71.189749 Z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(4, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(4),
            strokeWidth: this.getStrokeWith(4),
          }}
        />

        <path
          d="m 34.456553,89.825729 c 1.120196,0 2.240392,-0.407343 3.055079,-1.018359 L 19.995847,71.291585 2.378226,88.80737 c 0.9165237,0.611016 1.9348833,1.018359 3.2587508,1.018359 L 16.63526,89.214714 c 2.03672,-0.101836 4.277111,-0.101836 6.31383,0 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(5, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(5),
            strokeWidth: this.getStrokeWith(5),
          }}
        />
        <path
          d="m 37.511632,88.80737 c 1.425704,-1.01836 2.342227,-2.647735 2.342227,-4.378946 L 39.34468,74.754007 c -0.101836,-2.240391 -0.101836,-4.378946 0,-6.721173 l 0.509179,-9.979924 c 0,-1.731211 -0.814687,-3.360587 -2.240391,-4.378946 L 20.097683,71.189749 Z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(6, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(6),
            strokeWidth: this.getStrokeWith(6),
          }}
        />
        <path
          d="M 19.995847,71.189749 37.511632,53.572128 c -0.916523,-0.611016 -1.934883,-1.01836 -3.25875,-1.01836 l -9.674417,0.611016 c -3.360586,0.203672 -6.619337,0.203672 -9.878088,0 L 5.5351408,52.553768 c -1.2220315,0 -2.3422271,0.407344 -3.2587507,1.01836 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(7, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(7),
            strokeWidth: this.getStrokeWith(7),
          }}
        />
        <ellipse
          cx="19.995848"
          cy="71.189751"
          rx="8.961565"
          ry="4.8881264"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(8, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(8),
            strokeWidth: this.getStrokeWith(8),
          }}
        />
      </g>
    );
  }
}

export default Crown;
