import JwtStorage from '../helpers/JwtStorage';

const initState = {
  successCode: -1, // -1 neutral, 1 success, 2 fail
  message: '',
  jwtToken: '',
  isLoading: false,
  isLogged: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_POST_START': {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }
    case 'LOGIN_POST_FAILED': {
      const newState = { ...state };
      newState.isLoading = false;
      newState.successCode = 2;
      newState.isLogged = false;
      JwtStorage.removeJwt();
      return newState;
    }
    case 'LOGIN_POST_SUCCEED': {
      const newState = { ...state };
      newState.isLoading = false;
      newState.successCode = 1;
      newState.isLogged = true;
      newState.jwtToken = action.data.token;

      // set jwtToken in localStorage
      // localStorage.setItem('jwtToken', newState.jwtToken);
      JwtStorage.setJwtToken(newState.jwtToken);
      JwtStorage.setJwtRefreshToken(action.data.refresh_token);

      return newState;
    }
    case 'LOGIN_LOGOUT': {
      const newState = { ...state };
      newState.isLogged = false;
      JwtStorage.removeJwt();
      localStorage.clear();
      return newState;
    }
    case 'LOGIN_LOGIN': {
      const newState = { ...state };
      newState.isLogged = true;
      return newState;
    }
    default:
      return state;
  }
};
