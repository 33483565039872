import React, { Component } from 'react';
import { Button, InputGroup, Form } from 'react-bootstrap';
import LocationItem from './LocationItem';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: '',
      locations: [],
      ulStyle: { display: 'none' },
    };

    // const geocoder = new window.google.maps.Geocoder();
  }

  onSelectPlaceHandler = (item) => {
    this.setState({
      ulStyle: { display: 'none' },
    });
    this.props.onSelect(item);
  };

  onChangeSearchWordHandler = (e) => {
    this.setState({
      searchWord: e.target.value,
    });

    this.searcher(e.target.value);
  };

  onSearchHandler = () => {
    this.searcher(this.state.searchWord);
  };

  searcher = (newAddress) => {
    this.props.geocoder.geocode({ address: newAddress }, (results, status) => {
      if (status === 'OK') {
        this.setState({
          locations: results,
          ulStyle: { display: 'block' },
        });
      }
    });
  }

  showLocations = () => {
    if (this.state.locations.length > 0) {
      return (<ul style={this.state.ulStyle} >
        {this.state.locations.map(item => (<LocationItem
          item={item}
          key={item.place_id}
          onChange={this.onSelectPlaceHandler}
        />))}
      </ul>);
    }

    return '';
  }

  render() {
    return (<div className="google-search-bar">
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Szukaj..."
          value={this.state.searchWord}
          onChange={this.onChangeSearchWordHandler}
        />
        <span className="input-group-btn">
          <Button onClick={this.onSearchHandler}>
            Szukaj
          </Button>
        </span>
      </InputGroup>
      {this.showLocations()}
    </div>);
  }
}
export default SearchBar;
