import React, { Component } from 'react';
import { Alert, Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import DateTime from '../../helpers/DateTime';

class EventsTableList extends Component {
  tableRows = () => {
    const { visits, t } = this.props;

    if (!visits || visits.length <= 0) {
      return (
        <tr>
          <td colSpan={5}>
            <Alert variant="info">Brak zaplanowanych wizyt</Alert>
          </td>
        </tr>
      );
    }

    return visits.map((visit) => (
      <tr key={visit.id}>
        <td>{DateTime.dateTimeStringToDateReverseString(visit.dateTill)}</td>
        <td>{DateTime.dateTimeStringToTimeString(visit.dateFrom)}</td>
        <td>{`${visit.doctor.name} ${visit.doctor.lastName}`}</td>
        <td>{t(visit.visitTypeName)}</td>
        <td>{visit.description}</td>
      </tr>
    ));
  };

  render() {
    return (
      <Table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Godzina</th>
            <th>Lekarz</th>
            <th>Rodzaj wizyty</th>
            <th>Dodatkowe informacje</th>
          </tr>
        </thead>
        <tbody>{this.tableRows()}</tbody>
      </Table>
    );
  }
}

export default withTranslation()(EventsTableList);
