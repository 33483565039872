import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Button, Modal } from 'react-bootstrap';
import NewEvent from '../../components/Schedule/NewEvent';
import SaveButton from '../../components/SaveButton';
import formSelector from '../../helpers/selectors/formSelector';
import FormsAction from '../../actions/FormsAction';
import ScheduleAction from '../../actions/ScheduleAction';
import ConfirmDialog from '../../components/ConfirmDialog';
import UserAction from '../../actions/UserAction';
import UserEnum from '../../helpers/Enum/UserEnum';
import ConfirmDialogAction from '../../actions/ConfirmDialogAction';
import store from '../../store';


class NewEventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchForSameUser: false,
      isAdding: false,
    };
  }

  sendFormHandler = () => {
    const { scheduleEventForm, scheduleEventForm: { fields } } = this.props;
    FormsAction.checkFormValidation(NewEvent.SCHEDULE_EVENT_FORM);
    if (!scheduleEventForm.isValid) {
      return;
    }

    if (fields.userId.value >= 1) {
      this.sendRequest();
      return;
    }

    this.setState({
      searchForSameUser: true,
    });
    UserAction.promiseSearchPatient(
      '',
      fields.name.value,
      fields.lastName.value,
      '',
      '',
      UserEnum.TYPE_PATIENT,
    ).then((data) => {
      this.setState({
        searchForSameUser: false,
      });

      if (data && data.status === 200 && data.data.length) {
        ConfirmDialogAction.openDialog('add-Patient-with-same-name');
      } else {
        this.sendRequest();
      }
    });
  };

  sendRequest = () => {
    const { scheduleEventForm, userClinics: { selectedClinic }, onCloseModal } = this.props;
    FormsAction.checkFormValidation(NewEvent.SCHEDULE_EVENT_FORM);

    if (scheduleEventForm.isValid) {
      ConfirmDialogAction.closeDialog();
      const { user } = this.props;
      const clinicId = selectedClinic.id;
      this.setState({
        isAdding: true,
      });
      ScheduleAction.postAction(
        user.user.id,
        user.selectedUser.id,
        clinicId,
        scheduleEventForm
      ).then((response) => {

        console.log(response);

        if (!response) {
          return;
        }

        this.setState({
          isAdding: false,
        });

        if (response.status === 201) {
          ScheduleAction.refreshLastGetListForNewUser(user.selectedUser.id);
          onCloseModal();
        }

        if (response.status === 409) {
          const item = {
            id: Date.now(),
            type: 'warning',
            title: 'Konflikt z inną wizytą',
            message: '',
          };
          store.getStore().dispatch({ type: 'NOTIFICATIONS_PUSH', data: item });
        }
      });
    }
  }

  handleCloseModal = () => {
    const { onCloseModal } = this.props;
    onCloseModal();
  }

  render() {
    const {
      showModal,
      newEventFrom,
      newEventTill,
      scheduleEventForm,
    } = this.props;

    const { searchForSameUser, isAdding } = this.state;

    return (
      <div>
        <Modal show={showModal} onHide={this.handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Nowa rejestracja</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewEvent
              from={newEventFrom}
              till={newEventTill}
            />
          </Modal.Body>
          <Modal.Footer>
            <SaveButton
              onSave={this.sendFormHandler}
              variant="success"
              text="Rezerwuj"
              disabled={!scheduleEventForm.isValid}
              isLoading={isAdding || searchForSameUser}
            />
            <Button
              onClick={this.handleCloseModal}
              variant="danger"
            >
              Anuluj
            </Button>
          </Modal.Footer>
        </Modal>
        <ConfirmDialog
          name="add-patient-with-same-name"
          title="Pomyśl dwa razy"
          cancelTextButton="Nie"
          confirmTextButton="Tak"
          onConfirm={() => this.sendRequest()}
        >
          Pacjent o takim Imieniu i Nazwisku już istnieje. Czy na pewno chcesz dodać nowego pacjenta?
        </ConfirmDialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: { ...state.user },
  userClinics: state.userClinics,
  calender: state.calender,
  scheduleEventForm: formSelector(state.forms, NewEvent.SCHEDULE_EVENT_FORM),
});

export default connect(mapStateToProps)(NewEventModal);
