import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import InputMessages from '../../containers/FormWizard/InputMessages';

class Radio extends Component {
  constructor(props) {
    super(props);

    const { inputElement } = this.props;

    this.state = {
      checked: inputElement.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { checked } = this.state;
    const { inputElement } = nextProps;
    if (checked !== inputElement.value) {
      this.setState({
        checked: inputElement.value,
      });
    }
  }

  onChangeHandler = (value) => {
    this.props.onChange(value);
    this.setState({ checked: value });
  };

  render() {
    const { inputElement, disabled } = this.props;
    const {
      validationMessages,
      label,
    } = inputElement;
    const { checked } = this.state;
    return (
      <Form.Group>
        <Form.Check
          type="radio"
          checked={checked}
          onChange={e => (this.onChangeHandler(e.target.checked))}
          disabled={disabled}
          {...inputElement.attributes}
          label={label}
        />
        <InputMessages messages={validationMessages} />
      </Form.Group>
    );
  }
}

export default Radio;
