import mainConfig from './main.config.json';
let localConfig = {};

try {
  localConfig = require('./local.config.json');
} catch (e) {
}

const settings = {...mainConfig, ...localConfig}
export default settings;
