import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ButtonToolbar } from 'react-bootstrap';
import Checkbox from '../Inputs/Checkbox';
import './style.css';


class TableItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isChecked !== this.props.isChecked) {
      this.setState({
        isChecked: nextProps.isChecked,
      });
    }
  }

  onSelectAllHandler = (isChecked) => {
    this.setState({
      isChecked,
    });
    this.props.onSelect(isChecked, this.props.rowItem);
  };

  showCell = rowItem => this.props.columns.map(columnItem => this.renderCell(columnItem, rowItem));

  selectAllCell = () => {
    if (this.props.selectAll) {
      return (
        <td style={this.checkboxStyle}>
          <Checkbox
            onChange={this.onSelectAllHandler}
            checked={this.state.isChecked}
          />
        </td>
      );
    }
    return null;
  };

  onRowClickHandler = (rowItem) => {
    const { onRowClick } = this.props;
    if(this.isFunction(onRowClick)) {
      onRowClick(rowItem);
    }
  };

  buttonsCell = (rowItem) => {
    if (this.props.buttons && this.props.buttons.length) {
      return (
        <td className="text-right action-icons table-buttons">
          <ButtonToolbar>
            {this.props.buttons.map(buttonItem => (buttonItem.button(rowItem)))}
          </ButtonToolbar>
        </td>
      );
    }

    return null;
  };

  checkboxStyle = {
    width: '20px',
    verticalAlign: 'middle',
  };

  isFunction = functionToCheck => (typeof functionToCheck === 'function');

  rowStyle = (rowItem) => {
    if (typeof this.props.onRowStyle === 'function') {
      return this.props.onRowStyle(rowItem);
    }
    return {};
  }

  renderCell = (column, itemRow) => {
    const { t } = this.props;
    return (
      <td
        style={column.styleCell}
        key={`${itemRow.id}-${column.property ? column.property : Math.random()}`}
      >
        {column.property ? this.isFunction(column.onText) ? column.onText(itemRow) : t(itemRow[column.property]) : null}
      </td>
    );
  };

  render() {
    const { rowItem } = this.props;
    return (
      <tr
        key={rowItem}
        style={this.rowStyle(rowItem)}
        onClick={() => this.onRowClickHandler(rowItem)}
      >
        {this.selectAllCell()}
        {this.showCell(rowItem)}
        {this.buttonsCell(rowItem)}
      </tr>
    );
  }
}
export default withTranslation()(TableItems);
