import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import UserCardMenu from './UserCardMenu';




class PatientJambotron extends Component {

  render() {
    const { user } = this.props;
    return (
      <Jumbotron className="form-container patient-header-jumbo">
        <div className="patient-jambotron-content">
          <h3>
            {user.name}
            &nbsp;
            {user.lastName}
          </h3>
        </div>
        <div className="patient-jambotron-menu">
          <UserCardMenu
            user={user}
          />
        </div>
      </Jumbotron>
    );
  }
}

export default PatientJambotron;
