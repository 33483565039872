import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JewRender from '../JewRender';
import { Badge } from 'react-bootstrap';


class TeethWrapper extends Component {
  onClickHandler = (toothNumber, multiSelect) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(toothNumber, multiSelect);
    }
  }

  onLabelClickHandler = (event) => {
    const { onLabelClick } = this.props;
    if (onLabelClick) {
      event.stopPropagation();
      event.preventDefault();
      // event.ctrlKey

      const labelName = this.getLabelName();

      onLabelClick(labelName, event.ctrlKey);
    }
  }

  upPart = (children) => {
    const labelName = this.getLabelName();
    const { selectedTeeth, viewMode, teethNumber } = this.props;
    let className = 'teeth-wrapper-container';

    if (selectedTeeth[+labelName]) {
      className = 'teeth-wrapper-container active';
    }

    if (viewMode === JewRender.JEW_RENDER_MODE_MILKY && teethNumber > 5) {
      return (
        <div
          className={className}
          role="button"
        >
          <div className="teeth-part">

          </div>
          <div className="label-part">

          </div>
        </div>
      );
    }

    const selectedTooth = selectedTeeth[+labelName] || {};

    return (
      <div
        className={className}
        onClick={() => this.onClickHandler(labelName)}
        role="button"
        tabIndex={0}
      >
        <div className="teeth-part">
          {React.cloneElement(children, { ...{ labelName, selectedTooth } })}
        </div>
        <div className="label-part">
          <button onClick={this.onLabelClickHandler}>
            <Badge pill variant="light">
              {labelName}
            </Badge>
          </button>
        </div>
      </div>
    );
  }

  downPart = (children) => {
    const labelName = this.getLabelName();
    const { selectedTeeth, viewMode, teethNumber, marginBottom } = this.props;
    const style = { marginBottom: marginBottom };
    let className = 'teeth-wrapper-container';

    if (viewMode === JewRender.JEW_RENDER_MODE_MILKY && teethNumber > 5) {
      return (
        <div
          className={className}
          role="button"
        >
          <div className="label-part">

          </div>
          <div className="teeth-part" style={style}>

          </div>
        </div>
      );
    }

    if (selectedTeeth[+labelName]) {
      className = 'teeth-wrapper-container active';
    }

    const selectedTooth = selectedTeeth[+labelName] || {};

    return (
      <div
        className={className}
        onClick={() => this.onClickHandler(labelName)}
        role="button"
        tabIndex={0}
      >
        <div className="label-part">
          <button onClick={this.onLabelClickHandler}>
            <Badge pill variant="light">
              {labelName}
            </Badge>
          </button>
        </div>
        <div className="teeth-part" style={style}>
          {React.cloneElement(children, { ...{ labelName, selectedTooth } })}
        </div>
      </div>
    );
  }

  getLabelName = () => {
    const {
      part,
      viewMode,
      direction,
      teethNumber,
    } = this.props;

    let startingValue = 0;

    if (viewMode === JewRender.JEW_RENDER_MODE_PERMANENT && direction === 'right' && part === 'up') {
      startingValue = 20;
    } else if (viewMode === JewRender.JEW_RENDER_MODE_PERMANENT && direction === 'left' && part === 'up') {
      startingValue = 10;
    } else if (viewMode === JewRender.JEW_RENDER_MODE_PERMANENT && direction === 'left' && part === 'down') {
      startingValue = 40;
    } else if (viewMode === JewRender.JEW_RENDER_MODE_PERMANENT && direction === 'right' && part === 'down') {
      startingValue = 30;
    } else if (viewMode === JewRender.JEW_RENDER_MODE_MILKY && direction === 'right' && part === 'up') {
      startingValue = 60;
    } else if (viewMode === JewRender.JEW_RENDER_MODE_MILKY && direction === 'right' && part === 'down') {
      startingValue = 70;
    } else if (viewMode === JewRender.JEW_RENDER_MODE_MILKY && direction === 'left' && part === 'down') {
      startingValue = 80;
    } else if (viewMode === JewRender.JEW_RENDER_MODE_MILKY && direction === 'left' && part === 'up') {
      startingValue = 50;
    }

    return startingValue + teethNumber;
  };



  renderTeeth = () => {
    const { part, children } = this.props;
    if (part === 'down') {
      return this.downPart(children);
    }

    return this.upPart(children);
  };

  render() {
    const { width, height } = this.props;
    const style = {
      width,
      height,
    };

    return (
      <div
        className="teeth-wrapper"
        style={style}
      >
        {this.renderTeeth()}
      </div>
    );
  }
}

TeethWrapper.propTypes = {
  viewMode: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  teethNumber: PropTypes.number,
  direction: PropTypes.string,
  part: PropTypes.string,
};

TeethWrapper.defaultProps = {
  viewMode: 'milky',
  direction: 'right',
  part: 'up',
  width: 0,
  height: 0,
  teethNumber: 0,
};


export default TeethWrapper;
