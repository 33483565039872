
export default (events, selectedClinicId, selectedDoctorId) => {
  if (!selectedClinicId || !selectedDoctorId) {
    return [];
  }

  if (!events.length) {
    return [];
  }

  return events.filter((event) => {
      if (event.status === 'cancelled') {
        return false;
      }

      if (event.status === 'month-view-event') {
        return true;
      }

      if (+event.doctorId) {
        return +event.clinicId === +selectedClinicId && +event.doctorId === selectedDoctorId;
      }

      return +event.idClinic === +selectedClinicId && event.idUser === selectedDoctorId;
  });
}
