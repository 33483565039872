import React, { Component } from 'react';
import { Jumbotron, Card } from 'react-bootstrap';
import PatientCardMenu from './PatientCardMenu';
import VisitCards from './VisitCards';
import UserAction from '../../actions/UserAction';
import './style.css';
import Schedule from '../../helpers/Enum/Schedule';

class PatientJambotron extends Component {

  constructor(props) {
    super(props);

    this.state = {
      visits: [],
    }
  }


  componentDidMount() {
    const { patient } = this.props;
    UserAction.promiseGetPatientDetails(patient.id).then((response) => {
      if (response.status === 200) {
        this.setState({
          visits: response.data.scheduleEventCollection.elements,
        })
      }
    });
  }

  render() {
    const { patient } = this.props;
    const { visits } = this.state;

    return (
      <Jumbotron className="form-container patient-header-jumbo">
        <div className="patient-jambotron-content">
          <div className="patient-jambotron-content-left">
            <h3>
              {patient.name}
              &nbsp;
              {patient.lastName}
            </h3>
          </div>
          <div className="patient-jambotron-content-right">
              <VisitCards visits={!visits || visits.filter((visit) => visit.status !== Schedule.STATUS_CANCELLED)} />
          </div>
        </div>
        <div className="patient-jambotron-menu">
          <PatientCardMenu
            patient={patient}
          />
        </div>
      </Jumbotron>
    );
  }
}

export default PatientJambotron;
