import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JewTeethPart from '../JewTeethPart';
import TeethTemplateUp from './TeethTemplateUp';


class TeethUpPart extends Component {
  render() {
    const {
      width,
      height,
      teethPartHTop,
      graphModeTeeth,
      onToothSelect,
      selectedTeeth,
      teeth,
      clickable,
      proceduresList,
    } = this.props;

    const style = {
      width,
      height,
    };

    const heightOfTeethTemplate = height - teethPartHTop;
    const widthOfTeethTemplate = width - JewTeethPart.LEFT_FREE_SPACE;

    const styleOfTeethTemplate = {
      top: heightOfTeethTemplate * 0.29,
      height: heightOfTeethTemplate,
      width: widthOfTeethTemplate,
      left: JewTeethPart.LEFT_FREE_SPACE,
    };

    return (
      <div
        className="jew-teeth-up-container"
        style={style}
      >
        <div
          className="jew-teeth-up-container-teethes"
          style={styleOfTeethTemplate}
        >
          <TeethTemplateUp
            height={heightOfTeethTemplate}
            width={widthOfTeethTemplate}
            graphModeTeeth={graphModeTeeth}
            onToothSelect={onToothSelect}
            selectedTeeth={selectedTeeth}
            teeth={teeth}
            clickable={clickable}
            proceduresList={proceduresList}
          />
        </div>
      </div>
    );
  }
}

TeethUpPart.propTypes = {
  graphModeTeeth: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  teethPartHTop: PropTypes.number,
  proceduresList: PropTypes.array,
};

TeethUpPart.defaultProps = {
  graphModeTeeth: 'milky',
  width: 0,
  height: 0,
  teethPartHTop: 0,
  proceduresList: [],
};

export default TeethUpPart;
