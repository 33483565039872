import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Col, Row, ButtonGroup, Button } from 'react-bootstrap';
import FormsAction from '../../actions/FormsAction';
import UserAction from '../../actions/UserAction';
import VisitAction from '../../actions/VisitAction';
import Loader from '../Loader';
import formSelector from '../../helpers/selectors/formSelector';
import Input from '../Inputs/Input';
import PhoneNumber from '../Inputs/PhoneNumber';
import Select from '../Inputs/Select';
import DateTime from '../../helpers/DateTime';
import './style.css';
import UserEnum from '../../helpers/Enum/UserEnum';
import { FirstLetterUpper } from '../../helpers/transformations';

class NewEvent extends Component {
  static SCHEDULE_EVENT_FORM = 'ScheduleEventForm';

  constructor(props) {
    super(props);
    this.state = {
      formIsOk: false,
      selectedPatient: 0,
      searchResults: {collection: []},
    };

    FormsAction.registerForm(NewEvent.SCHEDULE_EVENT_FORM).then(() => {
      this.setState({ formIsOk: true });
    });
    const { visitTypes } = this.props;
    if (!visitTypes.length) {
      VisitAction.getVisitTypes();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.formIsOk === true && this.state.formIsOk === false) {
      if (nextProps.from && nextProps.till) {
        const FROM = DateTime.dateToDateTimeString(nextProps.from);
        const TILL = DateTime.dateToDateTimeString(nextProps.till);
        FormsAction.updateValue(NewEvent.SCHEDULE_EVENT_FORM, 'dateTimeFrom', FROM);
        FormsAction.updateValue(NewEvent.SCHEDULE_EVENT_FORM, 'dateTimeTo', TILL);
      }
    }
  }

  updateValue = (name, value) => {
    FormsAction.updateValue(NewEvent.SCHEDULE_EVENT_FORM, name, value);
  };

  disableElement = (name) => {
    FormsAction.updateAttribute(NewEvent.SCHEDULE_EVENT_FORM, name, 'disabled', true);
  };

  enableElement = (name) => {
    FormsAction.updateAttribute(NewEvent.SCHEDULE_EVENT_FORM, name, 'disabled', false);
  };

  updateName = (value) => {
    FormsAction.updateValue(NewEvent.SCHEDULE_EVENT_FORM, 'name', value);
    this.searchUsers();
  }

  updateLastName = (value) => {
    FormsAction.updateValue(NewEvent.SCHEDULE_EVENT_FORM, 'lastName', value);
    this.searchUsers();
  }

  updateEmail = (value) => {
    FormsAction.updateValue(NewEvent.SCHEDULE_EVENT_FORM, 'email', value);
    this.searchUsers();
  }

  updatePersonId = (value) => {
    FormsAction.updateValue(NewEvent.SCHEDULE_EVENT_FORM, 'personId', value);
    this.searchUsers();
  }

  searchUsers = () => {
    const { scheduleEventForm: { fields } } = this.props;

    UserAction.promiseSearchPatient(
      '',
      fields.name.value,
      fields.lastName.value,
      fields.personId.value,
      fields.email.value,
      UserEnum.TYPE_PATIENT,
    ).then((data) => {
      if (data && data.status === 200) {
        this.setState({
          searchResults: data.data,
        });
      }
    });
  }

  onSelectUser = (item) => {
    const { selectedPatient } = this.state;


    if (item.id === selectedPatient) {
      this.updateValue('name', '');
      this.enableElement('name');

      this.updateValue('lastName', '');
      this.enableElement('lastName');

      this.updateValue('email', '');
      this.enableElement('email');

      this.updateValue('phoneNumber', '');
      this.enableElement('phoneNumber');

      this.updateValue('optionalPhoneNumber', '');
      this.enableElement('optionalPhoneNumber');

      this.updateValue('personId', '');
      this.enableElement('personId');

      this.updateValue('userId', 0);

      this.setState({
        selectedPatient: 0,
      });

      return;
    }

    this.setState({
      selectedPatient: item.id
    });

    console.log(item);

    this.updateValue('name', item.name);
    this.disableElement('name');

    this.updateValue('lastName', item.lastName);
    this.disableElement('lastName');

    this.updateValue('email', item.email);
    this.disableElement('email');

    this.updateValue('phoneNumber', item.phoneNumber);
    this.disableElement('phoneNumber');

    this.updateValue('optionalPhoneNumber', item.optionalPhoneNumber);
    this.disableElement('optionalPhoneNumber');

    this.updateValue('personId', item.personId);
    this.disableElement('personId');

    this.updateValue('userId', item.id);

  }

  render() {
    const { scheduleEventForm, visitTypes, t } = this.props;
    const { formIsOk, searchResults, selectedPatient } = this.state;
    if (!formIsOk || !visitTypes.length) {
      return <Loader />;
    }

    return (
      <div>
        <Row>
          <Col md={6} sm={12}>
            <Input
              inputElement={scheduleEventForm.fields.personId}
              onChange={value => this.updatePersonId(value)}
            />
          </Col>
          <Col md={6} sm={12}>
            <Row>
              <Col md={6} sm={12}>
                <Input
                  inputElement={{
                    ...scheduleEventForm.fields.dateTimeFrom,
                    value: DateTime.dateTimeStringToTimeString(scheduleEventForm.fields.dateTimeFrom.value, false),
                  }}
                  disabled
                />
              </Col>
              <Col md={6} sm={12}>
                <Input
                  inputElement={{
                    ...scheduleEventForm.fields.dateTimeTo,
                    value: DateTime.dateTimeStringToTimeString(scheduleEventForm.fields.dateTimeTo.value, false),
                  }}
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Input
              inputElement={scheduleEventForm.fields.name}
              onChange={value => this.updateName(value)}
              transformations={[FirstLetterUpper]}
            />
          </Col>
          <Col md={6} sm={12}>
            <Input
              inputElement={scheduleEventForm.fields.lastName}
              onChange={value => this.updateLastName(value)}
              transformations={[FirstLetterUpper]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <Input
              inputElement={scheduleEventForm.fields.email}
              onChange={value => this.updateEmail(value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <PhoneNumber
              inputElement={scheduleEventForm.fields.phoneNumber}
              onChange={value => this.updateValue('phoneNumber', value)}
            />
          </Col>
          <Col md={6} sm={12}>
            <PhoneNumber
              inputElement={scheduleEventForm.fields.optionalPhoneNumber}
              onChange={value => this.updateValue('optionalPhoneNumber', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <Select
              inputElement={{
                ...scheduleEventForm.fields.visitType,
                values: [
                  { value: 0, label: 'Wybierz rodzaj wizyty' },
                  ...visitTypes.map(visitType => ({ value: visitType.id, label: visitType.name })),
                ],
              }}
              onChange={value => this.updateValue('visitType', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <Input
              inputElement={scheduleEventForm.fields.description}
              onChange={value => this.updateValue('description', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} sm={12}>
            <h5 className="users-suggestions">Propozycje:</h5>
            <div className="search-user-box">
              <ButtonGroup vertical>
                {searchResults.collection.map(item => (
                  <Button
                    key={item.id}
                    onClick={() => this.onSelectUser(item)}
                    variant={selectedPatient === item.id ? 'info' : 'primary'}
                  >
                    {item.name}
                    &nbsp;
                    {item.lastName}
                    |&nbsp;tel.&nbsp;
                    {item.phoneNumber}
                    |&nbsp;PESEL:&nbsp;
                    {item.personId}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  scheduleEventForm: formSelector(state.forms, NewEvent.SCHEDULE_EVENT_FORM),
  visitTypes: [ ...state.visit.visitTypes ],
});


export default connect(mapStateToProps)(NewEvent);
