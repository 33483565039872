import React, { Component } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import JewRender from '../../../components/Jaw/JewRender';
import TeethRecognition from '../../../components/Teeth/TeethRecognition';
import ToothProperties from '../../../components/Teeth/ToothProperties';
import RecognitionAction from '../../../actions/RecognitionAction';
import ProcedureAction from '../../../actions/ProcedureAction';
import VisitAction from '../../../actions/VisitAction';
import TeethManager from '../../../helpers/Teeth/TeethManager';
import DButton from '../../../components/Button/DButton';

class TeethDiagram extends Component {

  constructor(props) {
    super(props);
    this.state = {
      teethMode: JewRender.JEW_RENDER_MODE_PERMANENT,
      recognitionList: [],
      proceduresList: [],
      toothIsSelected: false,
      selectedTooth: {},
      selectedTeeth: {},
    };

    this.teethManager = new TeethManager();
  }

  componentDidMount() {
    RecognitionAction.promiseGetRecognitions().then((data) => {
      this.setState({
        recognitionList: data.data,
      });
    });

    ProcedureAction.promiseGetProcedures().then((data) => {
      this.setState({
        proceduresList: data.data,
      });
    });
  }

  onToothSelectHandler = (toothNumber, toothNumberPartNumber, multiSelect) => {


    let selectedTeeth = { ...this.state.selectedTeeth };
    if (multiSelect === false) {
      selectedTeeth = {};
    }

    if (!selectedTeeth[toothNumber]) {
      selectedTeeth[toothNumber] = {
        toothNumber,
        parts: {},
      }
    }

    if (Array.isArray(toothNumberPartNumber)) {
      toothNumberPartNumber.forEach(partNumber => this.markToothPartAsSelected(selectedTeeth, toothNumber, partNumber));
    } else {
      this.markToothPartAsSelected(selectedTeeth, toothNumber, toothNumberPartNumber);
    }

    /*if (!selectedTeeth[toothNumber].parts[toothNumberPartNumber]) {
      selectedTeeth[toothNumber].parts[toothNumberPartNumber] = {
        partNumber: toothNumberPartNumber,
      }
    }*/

    this.setState({
      selectedTeeth: { ...selectedTeeth },
      toothIsSelected: true,
    });
  }

  markToothPartAsSelected = (selectedTeeth, toothNumber, toothNumberPartNumber) => {
    if (!selectedTeeth[toothNumber].parts[toothNumberPartNumber]) {
      selectedTeeth[toothNumber].parts[toothNumberPartNumber] = {
        partNumber: toothNumberPartNumber,
      }
    }
  }

  onSelectRecognitionHandler = (recognitionItem) => {
    const { selectedTeeth } = this.state;

    Object.entries(selectedTeeth).forEach(([key, tooth]) => {
      if (!tooth.parts) {
        return;
      }
      Object.entries(tooth.parts).forEach(([key2, toothPart]) => {
        VisitAction.addRecognitionToothPart(
          tooth.toothNumber,
          toothPart.partNumber,
          recognitionItem,
        );
      });
    });
  }

  onSelectProcedureHandler = (procedureItem) => {
    const { selectedTeeth } = this.state;
    Object.entries(selectedTeeth).forEach(([key, tooth]) => {
      if (!tooth.parts) {
        return;
      }
      Object.entries(tooth.parts).forEach(([key2, toothPart]) => {
        VisitAction.addProcedureToothPart(
          tooth.toothNumber,
          toothPart.partNumber,
          procedureItem,
        );
      });
    });
  }

  onRemoveRecognitionHandler = (recognitionItem) => {
    const { selectedTeeth } = this.state;
    Object.entries(selectedTeeth).forEach(([key, tooth]) => {
      if (!tooth.parts) {
        return;
      }
      Object.entries(tooth.parts).forEach(([key2, toothPart]) => {
        VisitAction.removeRecognitionToothPart(
          tooth.toothNumber,
          toothPart.partNumber,
          recognitionItem,
        );
      });
    });
  }

  onRemoveProcedureHandler = (procedureItem) => {
    const { selectedTeeth } = this.state;
    Object.entries(selectedTeeth).forEach(([key, tooth]) => {
      if (!tooth.parts) {
        return;
      }
      Object.entries(tooth.parts).forEach(([key2, toothPart]) => {
        VisitAction.removeProcedureToothPart(
          tooth.toothNumber,
          toothPart.partNumber,
          procedureItem,
        );
      });
    });
  }

  onChangeToothDescription = (tooth, description) => {
    VisitAction.changeToothDescription(tooth.toothNumber, description);
  }

  onChangeJawMode = (newMode) => {
    this.setState({
      teethMode: newMode,
    });
  }

  getTeethFromVisit = (teeth) => {
    const { selectedTeeth } = this.state;
    const collection = [];

    Object.entries(selectedTeeth).forEach(([key, toothSelected]) => {
      if (teeth && teeth[toothSelected.toothNumber]) {
        collection.push({ ...teeth[toothSelected.toothNumber] });
      } else {
        collection.push(this.teethManager.createEmptyToothWithNumber(toothSelected.toothNumber));
      }
    });

    return collection;
  }

  render() {
    const {
      teethMode,
      recognitionList,
      proceduresList,
      toothIsSelected,
      selectedTeeth,
    } = this.state;

    const { user: { user } } = this.props;
    const { visit } = this.props.visit;
    const { teeth } = visit;

    return (
      <div>
        <Row style={{ display: 'flex' }}>
          <Col md={4} style={{ flex: 1, maxHeight: '100%' }}>
            <TeethRecognition
              recognitionList={recognitionList}
              proceduresList={proceduresList}
              toothIsSelected={toothIsSelected}
              onSelectRecognition={item => this.onSelectRecognitionHandler(item)}
              onSelectProcedure={item => this.onSelectProcedureHandler(item)}
              onRemoveRecognition={this.onRemoveRecognitionHandler}
              onRemoveProcedure={this.onRemoveProcedureHandler}
              selectedTeeth={this.getTeethFromVisit(teeth)}
            />
          </Col>
          <Col md={8} sm={12}>
            <div>
              <ButtonToolbar>
                <ButtonGroup>
                  <DButton
                    variant={teethMode === JewRender.JEW_RENDER_MODE_PERMANENT ? 'success' : null}
                    onClick={() => this.onChangeJawMode(JewRender.JEW_RENDER_MODE_PERMANENT)}
                  >
                    Zęby stałe
                  </DButton>
                  <DButton
                    variant={teethMode === JewRender.JEW_RENDER_MODE_MILKY ? 'success' : null}
                    onClick={() => this.onChangeJawMode(JewRender.JEW_RENDER_MODE_MILKY)}
                  >
                    Zęby mleczne
                  </DButton>
                </ButtonGroup>
              </ButtonToolbar>
              <JewRender
                proceduresList={proceduresList}
                graphModeTeeth={teethMode}
                selectedTeeth={selectedTeeth}
                onToothSelect={this.onToothSelectHandler}
                teeth={teeth}
                clickable={visit.status !== 'closed' || visit.doctor.id === user.id}
              />
              <small>
                Przytrzymaj klawisz "ctrl", aby zaznaczyć kilka elementów/zębów
              </small>
            </div>
          </Col>
        </Row>
        <ToothProperties
          editable={true}
          teeth={teeth}
          onChangeDescription={this.onChangeToothDescription}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  visit: state.visit,
  user: { ...state.user },
});

export default connect(mapStateToProps)(TeethDiagram);
