import React, { Component } from 'react';
import SwitchButton from '../../SwitchButton';
import { withTranslation } from 'react-i18next';

class NotificationSettingItem extends Component {
  render() {
    const { notificationSettingItem, t, onChange } = this.props;
    return (
      <div>
        <SwitchButton
          name={t(notificationSettingItem.notificationType)}
          default={notificationSettingItem.enabled}
          onChange={(newValue) => {
            const newNotificationSettingItem = {...notificationSettingItem}
            newNotificationSettingItem.enabled = newValue;
            onChange(newNotificationSettingItem);
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(NotificationSettingItem);
