import React from 'react';
import ToothClass from './ToothClass';

class SingleBRoot extends ToothClass {
  render() {
    const { side } = this.props;
    let transform = null;
    if (side === 'left') {
      transform = 'scale(-1, 1) translate(-40, 0)';
    }
    return (
      <g id="roots">
        <path
          d="m 5.3170441,52.265835 12.0266689,0.859048 c 1.610714,0.214762 3.114048,0.214762 4.51,0 l 12.778336,-0.859048 c 0.322143,0 0.751667,0 1.07381,0.214762 C 34.524668,47.433691 28.511334,41.957262 28.403953,27.782974 28.08181,12.427495 23.034904,1.9041602 22.820142,1.3672553 20.672523,-3.0353645 18.09538,4.4813034 16.377284,10.279876 c -2.255,7.301906 -4.939525,24.805004 -6.335477,31.247862 -1.1811909,6.120715 -4.510001,7.194525 -5.7985726,10.845478 0.3221429,-0.107381 0.8590478,-0.107381 1.0738097,-0.107381 z"
          className="svg-teeth-default"
          transform={transform}
          onClick={(event) => this.onClickHandler(1, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(1),
            strokeWidth: this.getStrokeWith(1),
          }}
        />
      </g>
    );
  }
}

export default SingleBRoot;
