import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, FormControl } from 'react-bootstrap';
import VisitAction from '../../../actions/VisitAction';


class Notes extends Component {
  onChangeNotesHandler = (e) => {
    VisitAction.updateNotes(e.target.value);
  }

  render() {
    const { visit } = this.props;
    return (
      <div>
        <FormGroup controlId="formControlsTextarea">
          <Form.Label>Notatka do wizyty</Form.Label>
          <FormControl
            className="visit-note-area"
            value={visit.visit.description}
            onChange={this.onChangeNotesHandler}
          />
        </FormGroup>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  visit: state.visit,
});

export default connect(mapStateToProps)(Notes);
