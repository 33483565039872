import BaseAction from './BaseAction';

class FileAction extends BaseAction {
  upload = (files, progressCallback) => {
    const url = '/api/file/upload';

    const formData = new FormData();
    formData.append('file_uploader[submit_form_hack]', 'och yes, hack me now');
    files.forEach(file => formData.append("file_uploader[files][]", file));
    return this.promiseUploadFile(url, formData, progressCallback);
  }

  download = (attachmentId) => {
    const url = `/api/file/download/${attachmentId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }
}

export default new FileAction;
