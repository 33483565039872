import React, { Component } from 'react';
import FileViewer from '../../components/FileViewer';
import IconButton from '../../components/Button/IconButton';
import ConfirmDialogAction from '../../actions/ConfirmDialogAction';

class AttachmentListItem extends Component {

  getIcon = (fileName) => {
    const extension = fileName.split('.').pop();

    switch (extension) {
      case 'pdf':
        return <i className="far fa-file-pdf" />
      case 'jpg':
      case 'jpeg':
      case 'png':
        return <i className="far fa-file-image" />
      default:
        return <i className="far fa-file" />
    }
  }

  render() {
    const { attachment } = this.props;


    return (
      <tr>
        <td>
          <div className="icon" >
            {this.getIcon(attachment.fileName)}
          </div>
        </td>
        <td>
          <div className="file-name">
            {attachment.fileName}
          </div>
        </td>
        <td>
          {attachment.addedBy.name} {attachment.addedBy.lastName}
        </td>
        <td>
          {attachment.createAt}
        </td>

        <td className="actions">
          <FileViewer
            attachment={attachment}
            tooltip="Pokaż załącznik"
          >
            <i class="fas fa-eye" />
          </FileViewer>
          <IconButton
            onClick={() => ConfirmDialogAction.openDialog('confirm-delete-attachment', attachment)}
            variant="danger"
            tooltip="Usuń załącznik"
          >
            <i className="fas fa-trash" />
          </IconButton>
        </td>
      </tr>
    );
  }
}

export default AttachmentListItem;
