import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Jumbotron, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import SettingMainPage from './Setting/SettingMainPage';
import ReportMainPage from './Report/ReportMainPage';
import UserJambotron from '../../components/User/UserJambotron';


class ProfileMainPage extends Component {
  render() {
    const { user: { user } } = this.props;
    return (
      <div className="work-space">
        <UserJambotron
          user={user}
        />
        <Jumbotron className="form-container patient-jumbo">
          <Row>
            <Col sm={12}>
              <Switch>
                <Route exac path="/panel/profile/me/detail" component={SettingMainPage} />
                <Route exac path="/panel/profile/me/users" component={SettingMainPage} />
                <Route exac path="/panel/profile/me/settings" component={SettingMainPage} />
                <Route exac path="/panel/profile/me/reports" component={ReportMainPage} />
              </Switch>
            </Col>
          </Row>
        </Jumbotron>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProfileMainPage);