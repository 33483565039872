import UserEnum from '../Enum/UserEnum';


class CalendarAuthorization {
  canMakeAVisit = (visit, user) => visit.idUser === user.id;

  canShowCalendarSettings = user => user.type !== UserEnum.TYPE_OFFICE_WORKER;

  canEditScheduledEvent = (status) => {
    const allowedStatuses = ['active', 'approved', 'closed'];

    return allowedStatuses.includes(status);
  }
}
export default new CalendarAuthorization();
