import UserEnum from '../helpers/Enum/UserEnum';
import UserModel from '../Model/User/UserModel';

const initState = {
  user: {},
  selectedUser: {},
  selectedUserWorkingDays: [],
  isLoading: false,
  success: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'USER_GET_SELF_START': {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }
    case 'USER_GET_SELF_FAILED': {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }
    case 'USER_GET_SELF_SUCCEED': {
      const newState = { ...state };
      newState.isLoading = false;
      newState.success = true;
      newState.user = Object.setPrototypeOf(action.data, UserModel.prototype);

      if (action.data.type === UserEnum.TYPE_DENTIST && !state.selectedUser.id) {
        newState.selectedUser = action.data;
      }

      return newState;
    }
    case 'USER_ACTION_CHANGE_SELECTED_USER': {
      const newState = { ...state };
      if (action.data.type === UserEnum.TYPE_DENTIST) {
        newState.selectedUser = action.data;
      }
      return newState;
    }
    case 'USER_ACTION_CHANGE_SELECTED_USER_WORKING_DAYS': {
      const newState = { ...state };
      newState.selectedUserWorkingDays = action.data;
      return newState;
    }
    case 'USER_LOGOUT': {
      const newState = { ...state };
      newState.user = {};
      return newState;
    }
    default:
      return state;
  }
};
