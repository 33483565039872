import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventInfoPopup from '../../../components/Calender/EventInfoPopup';
import ScheduleAction from '../../../actions/ScheduleAction';
import onClickOutside from 'react-onclickoutside';

class EventPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventIsLoading: true,
      fullEvent: {},
      showModal: false,
    };
  }

  handleClickOutside = () => {
    const { confirmDialog, onCloseEventPopup } = this.props;
    if (confirmDialog.isOpen) {
      return;
    }

    if (onCloseEventPopup) {
      onCloseEventPopup();
    }

    this.setState({
      showModal: false,
    });
  };

  componentDidMount() {
    this.getEvent(this.props.dentEvent.id);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.dentEvent.id !== this.props.dentEvent.id) {
      this.getEvent(nextProps.dentEvent.id);
    }
    if (nextProps.showPopupInfo && !this.state.showModal) {
      this.setState({
        showModal: true,
      });
    }

    if (nextProps.confirmDialog.isOpen !== this.props.confirmDialog.isOpen && !nextProps.confirmDialog.isOpen && this.state.showModal) {
      this.setState({
        showModal: false,
      });
    }
  }

  getEvent = (eventId) => {
    this.setState({
      fullEvent: {},
      eventIsLoading: true,
      showModal: true,
    });

    ScheduleAction.promiseGetEvent(eventId)
      .then((data) => {
        this.setState({
          fullEvent: data.data,
          eventIsLoading: false,
        });
      });
  };

  renderPopup = () => {
    const { dentEvent, user: { user }, top, left, width, onCancelEventPopup } = this.props;
    const { eventIsLoading, fullEvent, showModal } = this.state;
    if (!dentEvent.id || !showModal) {
      return null;
    }
    return (
      <EventInfoPopup
        event={dentEvent}
        isLoading={eventIsLoading}
        onCancelEventPopup={onCancelEventPopup}
        fullEvent={fullEvent}
        user={user}
        top={top + 20}
        left={left}
        width={width}
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderPopup()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  confirmDialog: state.confirmDialog,
  user: state.user,
});

export default connect(mapStateToProps)(onClickOutside(EventPopup));
