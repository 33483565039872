import FormsAction from '../../../actions/FormsAction';

class AddNewClinicForm {
  static FORM_NAME = 'AddNewClinicForm';

  static updateValue(name, value) {
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, name, value);
  }

  static checkFormValidation() {
    FormsAction.checkFormValidation(AddNewClinicForm.FORM_NAME);
  }

  static fillFormFromData(data) {
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'name', data.name);
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'city', data.city);
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'latitude', data.latitude);
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'latitude', data.latitude);
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'longitude', data.longitude);
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'openFrom', data.openFrom);
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'openTo', data.openTo);
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'postCode', data.postCode);
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'street', data.street);
    FormsAction.updateValue(AddNewClinicForm.FORM_NAME, 'streetNumber', data.streetNumber);
  }
}

export default AddNewClinicForm;
