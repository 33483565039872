import AbstractModel from '../AbstractModel';

class Event extends AbstractModel {
  static STATUS_ACTIVE = 'active';

  _start;

  _end;

  _id;

  _idClinic;

  _idUser;

  _lastName;

  _name;

  _status;

  _title;

  _className;

  _allDay = false;


  constructor(start, end, id, idClinic, idUser, lastName, name, status, title, className, allday) {
    super();
    this._start = start;
    this._end = end;
    this._id = id;
    this._idClinic = idClinic;
    this._idUser = idUser;
    this._lastName = lastName;
    this._name = name;
    this._status = status;
    this._title = title;
    this._className = className;
    this._allDay = allday;
  }

  get start() {
    return this._start;
  }

  set start(value) {
    this._start = value;
  }

  get end() {
    return this._end;
  }

  set end(value) {
    this._end = value;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get idClinic() {
    return this._idClinic;
  }

  set idClinic(value) {
    this._idClinic = value;
  }

  get idUser() {
    return this._idUser;
  }

  set idUser(value) {
    this._idUser = value;
  }

  get lastName() {
    return this._lastName;
  }

  set lastName(value) {
    this._lastName = value;
  }

  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }

  get status() {
    return this._status;
  }

  set status(value) {
    this._status = value;
  }

  get title() {
    return this._title;
  }

  set title(value) {
    this._title = value;
  }

  get className() {
    return this._className;
  }

  set className(value) {
    this._className = value;
  }

  get allDay() {
    return this._allDay;
  }

  set allDay(value) {
    this._allDay = value;
  }
}

export default Event;
