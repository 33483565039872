import React, { Component } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import HorizontalTimeline from '../../../../containers/Timeline/HorizontalTimeline';
import { Alert } from 'react-bootstrap';
import { forEach } from 'react-bootstrap/ElementChildren';


class ClinicReportView extends Component {

  static MONTHS_NAMES = [
    'Grudzień',
    'Styczeń',
    'Luty',
    'Marzec',
    'Kwieceń',
    'Maj',
    'Czerwiec',
    'Lipiec',
    'Sierpień',
    'Wrzesień',
    'Październik',
    'Listopad',
    'Grudzień',
  ];

  constructor(props) {
    super(props);

    const {clinicItem} = this.props;

    this.state = {
      currentYear: this.getLatestYear(clinicItem.smsYear),
    }
  }

  getYears = (smsYear) => {
    return smsYear.map(yearItem => ({year: yearItem.year}));
  };

  getLatestYear = (smsYear) => {
    const newSmsYear = [...smsYear];
    const lastYear = newSmsYear.pop();
    if (lastYear && lastYear.year) {
      return lastYear.year;
    }

    return null;
  }

  onChangeHandler = (item) => {
    this.setState({
      currentYear: item.year,
    });
  }


  getDataChartData = (smsYear, currentYear) => {
    const selectedYear = smsYear.filter((smsYearItem) => smsYearItem.year === currentYear);

    if (!selectedYear.length) {
      return [];
    }

    const chartData = selectedYear[0].smsMoth.map((monthItem) => {
      return {
        name: ClinicReportView.MONTHS_NAMES[monthItem.month],
        month: monthItem.month,
        value: monthItem.count,
      }
    });

    if (chartData.length === 12) {
      return chartData;
    }

    const d =  ClinicReportView.MONTHS_NAMES.map((monthName, index) => {
      const dataSet = chartData.filter(e => e.month === index);

      if (dataSet.length) {
        return dataSet[0];
      }

      return {
        name: ClinicReportView.MONTHS_NAMES[index],
        value: 0,
      }
    });

    d.shift();
    return d;
  }

  renderChart = (smsYear, currentYear) => {
    if (!currentYear) {
      return (<Alert variant="success">Brak Danych</Alert>)
    }

    return (
      <ResponsiveContainer width="100%" aspect={3} height="100%">
        <BarChart
          width={500}
          height={300}
          data={this.getDataChartData(smsYear, currentYear)}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Bar dataKey="value" fill="#007bff" />
        </BarChart>
      </ResponsiveContainer>
    );
  }

  render() {
    const {clinicItem} = this.props;
    const {currentYear} = this.state;

    return (
        <div>
          <h3>{clinicItem.clinicName}</h3>
          <HorizontalTimeline
            keyPrefix={clinicItem.clinicName}
            years={this.getYears(clinicItem.smsYear)}
            current={currentYear}
            onChange={this.onChangeHandler}
          />
          <div style={{minHeight: '400px', paddingTop: '10px'}}>
            {this.renderChart(clinicItem.smsYear, currentYear)}
          </div>
        </div>
    );
  }
}

export default ClinicReportView;
