import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import './style.css';


class Footer extends Component {
    render() {
        return (
            <Navbar clasName="footer" style={{ background: "var(--dark-blue)", color: "#fff" }}>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        &copy; {new Date().getFullYear()} DentMate
                    </Navbar.Text>
                </Navbar.Collapse>

            </Navbar >
        );
    }
}

export default Footer;