import BaseAction from './BaseAction';

class FormsAction extends BaseAction {
  classPrefix = 'FORMS';

  ATTRIBUTE_DISABLED = 'disabled';

  ATTRIBUTE_REQUIRE = 'require';

  post = (inputs) => {
    const url = '/register';
    this.request('post', url, inputs);
  };

  updateValue = (formName, key, value) => {
    let newValue = value;

    if (newValue instanceof String || typeof newValue === 'string') {
      newValue = value.trim();
    }

    this.updateInput(formName, key, 'value', newValue);
  };

  resetForm = (formName) => {
    const actionName = `${this.classPrefix}_RESET_FORM_VALUES`;
    this.makeDispatch(actionName, { formName });
  }

  updateInput = (formName, inputName, propName, value) => {
    const actionName = `${this.classPrefix}_UPDATE_INPUT`;
    const valueData = {
      formName,
      inputName,
      propName,
      value,
    };
    this.makeDispatch(actionName, valueData);
  };

  updateAddValidationMessage = (formName, inputName, messages) => {
    const actionName = `${this.classPrefix}_ADD_VALIDATION_MESSAGES_TO_FILED`;
    const valueData = {
      formName,
      filedName: inputName,
      messages,
    };
    this.makeDispatch(actionName, valueData);
  };

  updateAttribute = (formName, inputName, attribute, value) => {
    const actionName = `${this.classPrefix}_UPDATE_ATTRIBUTE`;
    const valueData = {
      formName,
      inputName,
      attribute,
      value,
    };
    this.makeDispatch(actionName, valueData);
  };

  checkFormValidation = (formName) => {
    const actionName = `${this.classPrefix}_CHECK_VALIDATION`;
    const valueData = {
      formName,
    };
    this.makeDispatch(actionName, valueData);
  };



  registerForm = async (formName) => {
    const actionName = `${this.classPrefix}_REGISTER_FORM`;
    const { default: { ...module } } = await import(`../helpers/Forms/${formName}/form.js`);
    const valueData = {
      formName,
      formFields: module,
    };

    this.makeDispatch(actionName, valueData);
  }
}

export default new FormsAction();
