import BaseAction from './BaseAction';
import StringFunction from '../helpers/StringFunction';

class VisitAction extends BaseAction {
  classPrefix = 'Visit';

  getVisitForScheduleItemId = (scheduleId) => {
    const url = `/api/visit/get/for-schedule-item/${scheduleId}`;
    // this.request(BaseAction.METHOD_GET, url);

    const promise = this.promiseDoRequest(BaseAction.METHOD_GET, url);
    promise.then((data) => {
      if (data.status === 200) {
        this.makeDispatch('VISIT_GET_VISIT_FOR_SCHEDULE_ITEM_ID_SUCCEED', data.data);
      }
    }).catch(() => {
      this.makeDispatch('VISIT_GET_VISIT_FOR_SCHEDULE_ITEM_ID_FAILED', {});
    });

    return promise;
  };

  getVisitTypes = () => {
    const url = '/api/visit/get-visit-types';
    this.request(BaseAction.METHOD_GET, url);
  }

  promiseAddVisit = (patientId, clinicId, scheduleId) => {
    const url = '/api/visit/';

    const formData = {
      visitForm: {
        patientId,
        clinicId,
        scheduleId,
      },
    };

    const promise = this.promiseDoRequest(BaseAction.METHOD_POST, url, formData);
    promise.then((data) => {
      if (data.status === 201) {
        this.makeDispatch('PATIENT_VISIT_WAS_OPEN', scheduleId);
      }
    }).catch(() => {

    });

    return promise;
  };

  promiseGetPatientHistoryList = (patientId) => {
    const url = `/api/visit/get/patient-history/${patientId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  };

  promiseGetScheduleItemIdOfLatestOpenVisit = (doctorId, patientId) => {
    const url = `/api/visit/get/latest-open-visit-schedule-item-id/${doctorId}/${patientId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  addRecognitionToothPart = (toothNumber, toothPartNumber, recognition) => {
    const data = {
      toothNumber,
      toothPartNumber,
      recognition,
    };

    this.makeDispatch('VISIT_ADD_RECOGNITION_TOOTH_PART', data);
  };

  addProcedureToothPart = (toothNumber, toothPartNumber, procedure) => {
    const data = {
      toothNumber,
      toothPartNumber,
      procedure,
    };

    this.makeDispatch('VISIT_ADD_PROCEDURE_TOOTH_PART', data);
  };

  removeRecognitionToothPart = (toothNumber, toothPartNumber, recognition) => {
    const data = {
      toothNumber,
      toothPartNumber,
      recognition,
    };

    this.makeDispatch('VISIT_REMOVE_RECOGNITION_TOOTH_PART', data);
  };

  removeProcedureToothPart = (toothNumber, toothPartNumber, procedure) => {
    const data = {
      toothNumber,
      toothPartNumber,
      procedure,
    };

    this.makeDispatch('VISIT_REMOVE_PROCEDURE_TOOTH_PART', data);
  };

  changeToothDescription = (toothNumber, description) => {
    const data = {
      toothNumber,
      description
    };
    this.makeDispatch('VISIT_UPDATE_TOOTH_DESCRIPTION', data);
  }

  updateNotes = (notes) => {
    this.makeDispatch('VISIT_UPDATE_NOTES', notes);
  }

  loadVisitFromData = (visit) => {
    this.makeDispatch('VISIT_LOAD_VISIT_FROM_VISIT_DATA', visit);
  }

  promiseCloseVisit = (visit) => {
    const url = '/api/visit/close';
    const formData = {
      visitId: visit.id,
      teeth: this.getTeethCollection({ ...visit.teeth }),
      description: visit.description || '',
    };

    const promise = this.promiseDoRequest(BaseAction.METHOD_POST, url, formData);
    promise.then((data) => {
      if (data.status === 201) {
        this.makeDispatch('VISIT_CLOSE_VISIT');
      }
    })
    .catch(() => {

    });

    return promise;
  }

  saveVisitOnLocalStorage = (scheduleId, userId, clinicId, patientId, visitData) => {
    localStorage.setItem(this.createHashForSaveVisit(scheduleId, userId, clinicId, patientId), JSON.stringify(visitData));
  }

  loadVisitFromLocalStorage = (scheduleId, userId, clinicId, patientId) => {
    const value =  localStorage.getItem(this.createHashForSaveVisit(scheduleId, userId, clinicId, patientId));
    if (!value) {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  removeVisitFromLocalStorage = (scheduleId, userId, clinicId, patientId) => {
    localStorage.removeItem(this.createHashForSaveVisit(scheduleId, userId, clinicId, patientId));
  }

  clearVisit = () => {
    this.makeDispatch('VISIT_CLEAR_VISIT');
  }


  /**
  *  private methods
  */
  getTeethCollection = (teeth) => {
    const toothParts = [];
    Object.entries(teeth).forEach((item) => {
      if (item[1].updated) {
        const toothValueObject = {
          toothNumber: item[1].toothNumber,
          toothRecognition: {
            recognitionId: 0,
            description: item[1].tootRecognition.description,
          },
          toothParts: this.getTeethPartCollection({ ...item[1].parts }),
        };

        toothParts.push(toothValueObject);
      }
    });

    return toothParts;
  }

  getTeethPartCollection = (parts) => {
    const partsCollection = [];
    Object.entries(parts).forEach((item) => {
      if (item[1].updated) {
        const toothPartValueObject = {
          toothPartNumber: item[1].partNumber,
          toothPartName: `name-${item[1].partNumber}`,
          toothPartRecognition: {
            recognitionId: item[1].recognition ? item[1].recognition.recognitionId || 0 : 0,
            description: '',
          },
          toothPartProcedure: {
            procedureId: item[1].procedure ? item[1].procedure.procedureId || 0 : 0,
            description: '',
          }
        };
        partsCollection.push(toothPartValueObject);
      }
    });

    return partsCollection;
  }

  createHashForSaveVisit = (scheduleId, userId, clinicId, patientId) => {
    return StringFunction.md5(`patient_visit_${scheduleId}_${userId}_${clinicId}_${patientId}`);
  }
}

export default new VisitAction();
