
class StringHelper {
  static findValue(item, value) {
    let a = item;
    const s = value.split('.');
    s.forEach((key) => {
      a = a[key];
    });
    return a;
  }

  static findValues(item, valuesToFind) {
    const properties = valuesToFind.split(';');
    const smallStringArray = [];

    properties.forEach((propertyName) => {
      const value = StringHelper.findValue(item, propertyName);
      smallStringArray.push(value);
    });

    return smallStringArray.join(' ');
  }
}

export default StringHelper;
