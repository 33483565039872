import React, { Component } from 'react';
import { Alert, Table, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import config from '../../config';

class ToothProperties extends Component {
  onChangeDescriptionHandler = (value, tooth) => {
    const { onChangeDescription } = this.props;
    onChangeDescription(tooth, value);
  }
  row = () => {
    const { teeth } = this.props;
    if (!teeth) {
      return this.emptyRow();
    }


    const collection = [];
    Object.entries(teeth).forEach(([key, toothItem]) => {
      if (
        (
          toothItem.tootRecognition
          && (
            toothItem.tootRecognition.description
            || toothItem.tootRecognition.recognitionId
          )
        )
        || Object.entries(toothItem.parts).length
      ) {
        collection.push(this.toothRow(toothItem));
      }
    });

    if (collection.length) {
      return collection;
    } else {
      this.emptyRow();
    }

    return this.emptyRow();
  }

  toothRow = (tooth) => {
    const toothPartRecognitions = this.getToothPartInterventionList(tooth.parts, true);
    const toothPartProcedures = this.getToothPartInterventionList(tooth.parts, false);

    const activeName = 0 === tooth.toothNumber
      ? 'active'
      : ''

    return (
      <tr key={tooth.toothNumber} className={activeName}>
        <td>
          {tooth.toothNumber}
        </td>
        <td>
          <ul>
            {toothPartRecognitions.map(item => this.prepareListElement(tooth, item, 'recognition'))}
          </ul>
        </td>
        <td>
          <ul>
            {toothPartProcedures.map(item => this.prepareListElement(tooth, item, 'procedure'))}
          </ul>
        </td>
        <td>
          {this.getComment(tooth)}
        </td>
      </tr>
    );
  }

  prepareListElement = (tooth, item, action) => {
    let background = item.color;
    if (action === 'procedure') {
      background = `url(${config.api}${config.procedureApiImageDirectoryPath}/${item.operationId}.png)`;
    }

    const style = {
      background: background,
    }

    return (
      <li
        key={`${action}-${tooth.toothNumber}-${item.id}`}
      >
        <div className="tooth-operation-legend" style={style}>

        </div>
        <div>{item.name}</div>
      </li>
    )
  };

  getComment = (tooth) => {
    const { editable } = this.props;

    if (!editable) {
      return tooth.tootRecognition ? tooth.tootRecognition.description : '';
    }

    return (
      <FormControl
        componentClass="textarea"
        onChange={(e) => this.onChangeDescriptionHandler(e.target.value, tooth)}
        value={tooth.tootRecognition ? tooth.tootRecognition.description : ''}
      />
    );
  }

  emptyRow = () => {
    const { textIfEmpty } = this.props;

    let text = <span><strong>Wybierz ząb</strong> z diagramu, aby zobaczyć szczegóły</span>;
    if (textIfEmpty) {
      text = textIfEmpty;
    }

    return (
      <tr>
        <td colSpan={4}>
          <Alert variant="info">
            {text}
          </Alert>
        </td>
      </tr>
    );
  }

  getToothPartInterventionList = (toothParts, isRecognition) => {
    if (!toothParts) {

      return [];
    }

    const newCollection = [];
    const objectExist = {};

    Object.keys(toothParts).forEach((key) => {
      if (isRecognition) {
        if (toothParts[key].recognition && toothParts[key].recognition.recognitionId) {
          const idKey = toothParts[key].recognition.recognitionId;

          if (objectExist[idKey]) {
            return;
          }

          objectExist[idKey] = true;
          newCollection.push({
            name: toothParts[key].recognition.name,
            id: `${key}-${toothParts[key].recognition.recognitionId}`,
            operationId: toothParts[key].recognition.recognitionId,
            color: toothParts[key].recognition.color,
          });
        }
      } else {
        if (toothParts[key].procedure && toothParts[key].procedure.procedureId) {
          const idKey = toothParts[key].procedure.procedureId;
          if (objectExist[idKey]) {
            return;
          }

          objectExist[idKey] = true;
          newCollection.push({
            name: toothParts[key].procedure.name,
            id: `${key}-${toothParts[key].procedure.procedureId}`,
            operationId: toothParts[key].procedure.procedureId,
            color: toothParts[key].procedure.color,
          });
        }
      }
    });

    return newCollection;
  }

  render() {
    return (
      <div className="teeth-properties-list">
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <td>
                Ząb
              </td>
              <td>
                Rozpoznanie
              </td>
              <td>
                Procedura
              </td>
              <td>
                Notatka/Komentarz
              </td>
            </tr>
          </thead>
          <tbody>
            {this.row()}
          </tbody>
        </Table>
      </div>
    );
  }
}

ToothProperties.propTypes = {
  editable: PropTypes.bool,
  teeth: PropTypes.object,
  toot: PropTypes.object,
  onChangeDescription: PropTypes.func,
  textIfEmpty: PropTypes.string
};

export default ToothProperties;
