import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import Loader from '../../components/Loader';
import Input from '../../components/Inputs/Input';
import SaveButton from '../../components/SaveButton';
import GoogleMap from '../../components/GoogleMap';
import AddNewClinicForm from '../../helpers/Forms/AddNewClinicForm';
import FormsAction from '../../actions/FormsAction';
import DateFormat from '../../helpers/Enum/DateFormat';

import formSelector from '../../helpers/selectors/formSelector';

import 'rc-time-picker/assets/index.css';


class ClinicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsOk: false,
    };

    const { onFormLoaded } = this.props;

    FormsAction.registerForm(AddNewClinicForm.FORM_NAME).then(() => {
      this.setState({ formIsOk: true });
      if (typeof onFormLoaded === 'function') {
        onFormLoaded(AddNewClinicForm);
      }
    });
  }

  updateValue = (name, value) => {
    AddNewClinicForm.updateValue(name, value);
  };

  onSubmitHandler = () => {
    const { addNewClinicForm, onSubmitHandler } = this.props;
    // const { clinicId } = this.state;
    AddNewClinicForm.checkFormValidation();
    if (addNewClinicForm.isValid) {
      onSubmitHandler(addNewClinicForm);
    }
  };

  onOpenFormChange = (momentValue) => {
    momentValue.set({ second: 0, millisecond: 0 });
    const time = momentValue.format(DateFormat.TIME_FORMAT);
    this.updateValue('openFrom', time);
  };

  onOpenToChange = (momentValue) => {
    momentValue.set({ second: 0, millisecond: 0 });
    const time = momentValue.format(DateFormat.TIME_FORMAT);
    this.updateValue('openTo', time);
  };

  onSelectLocationHandler = (location) => {
    const streetNumber = this.findFromAddressComponents(location.address_components, ['premise', 'street_number']);
    const street = this.findFromAddressComponents(location.address_components, ['route']);
    const city = this.findFromAddressComponents(location.address_components, ['locality']);
    const postCode = this.findFromAddressComponents(location.address_components, ['postal_code']);

    this.updateValue('street', street);
    this.updateValue('city', city);
    this.updateValue('streetNumber', streetNumber);
    this.updateValue('postCode', postCode);
    this.updateValue('latitude', location.geometry.location.lat());
    this.updateValue('longitude', location.geometry.location.lng());
  };

  findFromAddressComponents = (components, type, short) => {
    const f = components.filter(locationItem => this.locationTypesFinder(type, locationItem.types));
    if (short) {
      return (f[0] && f[0].short_name) || '';
    }
    return (f[0] && f[0].long_name) || '';
  };

  locationTypesFinder = (toFind, types) => {
    for (const type in toFind) {
      if (types.indexOf(toFind[type]) >= 0) {
        return true;
      }
    }
    return false;
  };

  defaultMomentValue = (value) => {
    if (!value) {
      return null;
    }

    const splitTime = value.split(':');

    const m = moment();
    m.set({
      hour: splitTime[0],
      minute: splitTime[1],
    });

    return m;
  };

  render() {
    const { formIsOk } = this.state;
    const { isSaving } = this.props;
    if (!formIsOk) {
      return <Loader />;
    }

    const { addNewClinicForm, buttonLabel } = this.props;

    return (
      <Row>
        <Col md={6} sm={12}>
          <div className="borderSeparatorLeft">
            <h4>
              Dane Gabinetu
            </h4>
            <Row>
              <Col md={12} sm={12}>
                <Input
                  inputElement={addNewClinicForm.fields.name}
                  onChange={value => this.updateValue('name', value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={9} sm={12}>
                <Input
                  inputElement={addNewClinicForm.fields.city}
                  onChange={value => this.updateValue('city', value)}
                />
              </Col>
              <Col md={3} sm={12}>
                <Input
                  inputElement={addNewClinicForm.fields.postCode}
                  onChange={value => this.updateValue('postCode', value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={9} sm={12}>
                <Input
                  inputElement={addNewClinicForm.fields.street}
                  onChange={value => this.updateValue('street', value)}
                />
              </Col>
              <Col md={3} sm={12}>
                <Input
                  inputElement={addNewClinicForm.fields.streetNumber}
                  onChange={value => this.updateValue('streetNumber', value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <div className="form-group">
                  <label className="control-label">Godzina otwarcia:</label>
                  <TimePicker
                    value={this.defaultMomentValue(addNewClinicForm.fields.openFrom.value)}
                    showSecond={false}
                    className="form-control"
                    onChange={this.onOpenFormChange}
                    minuteStep={15}
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <label className="control-label">Godzina zamknięcia:</label>
                <TimePicker
                  value={this.defaultMomentValue(addNewClinicForm.fields.openTo.value)}
                  showSecond={false}
                  className="form-control"
                  onChange={this.onOpenToChange}
                  minuteStep={15}
                />
              </Col>
            </Row>
            <Row bsClass="row">
              <Col md={12} sm={12}>
                <SaveButton
                  variant="primary"
                  bsSize="large"
                  block
                  disabled={!addNewClinicForm.isValid}
                  text={buttonLabel || 'Dodaj gabinet'}
                  onSave={this.onSubmitHandler}
                  isLoading={isSaving}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6} sm={12}>
          <h4>
            Wyszukaj na mapie
          </h4>
          <GoogleMap
            proportion={50}
            onSelect={this.onSelectLocationHandler}
            searchbar
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  addNewClinicForm: formSelector(state.forms, AddNewClinicForm.FORM_NAME),
});

ClinicForm.propTypes = {
  isSaving: PropTypes.bool,
  onSubmitHandler: PropTypes.func,
  onFormLoaded: PropTypes.func,
  buttonLabel: PropTypes.string,
};


export default connect(mapStateToProps)(ClinicForm);
