import React, { Component } from 'react';
import { Row, Col, Form, Jumbotron } from 'react-bootstrap';
import Input from '../../components/Inputs/Input';
import PhoneNumber from '../../components/Inputs/PhoneNumber';

class UserPart extends Component {
  updateValue = (name, value) => {
    const { onUpdateValue } = this.props;
    onUpdateValue(name, value);
  };

  render() {
    const { registerForm } = this.props;
    return (
      <Jumbotron>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h4>Dane personalne</h4>
        </div>
        <Row>
          <Col md={6} sm={12}>
            <Form.Label>Imię*</Form.Label>
            <Input
              inputElement={registerForm.fields.name}
              onChange={(value) => this.updateValue('name', value)}
            />
          </Col>
          <Col md={6} sm={12}>
            <Form.Label>Nazwisko*</Form.Label>
            <Input
              inputElement={registerForm.fields.lastName}
              onChange={(value) => this.updateValue('lastName', value)}
            />
          </Col>
        </Row>
        <Form.Label>Numer telefonu*</Form.Label>
        <Row>
          <Col md={6} sm={12}>
            <PhoneNumber
              inputElement={registerForm.fields.phoneNumber}
              onChange={(value) => this.updateValue('phoneNumber', value)}
            />
          </Col>
          <Col md={6} sm={12} />
        </Row>
        <Form.Label>Adres e-mail*</Form.Label>
        <Row>
          <Col md={6} sm={12}>
            <Input
              inputElement={registerForm.fields.email}
              onChange={(value) => this.updateValue('email', value)}
            />
          </Col>
          <Col md={6} sm={12} />
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Input
              inputElement={registerForm.fields.password}
              onChange={(value) => this.updateValue('password', value)}
            />
          </Col>
          <Col md={6} sm={12}>
            <Form.Label>Powtórz hasło*</Form.Label>
            <Input
              inputElement={registerForm.fields.password2}
              onChange={(value) => this.updateValue('password2', value)}
            />
          </Col>
        </Row>
        <p>Pola oznaczone * są wymagane</p>
      </Jumbotron>
    );
  }
}

export default UserPart;
