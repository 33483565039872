import axios from 'axios';
import store from '../store';
import config from '../config';
import '../axiosSettings';
import JwtStorage from '../helpers/JwtStorage';

class BaseAction {
  static METHOD_POST = 'post';

  static METHOD_GET = 'get';

  static METHOD_DELETE = 'delete';

  /** request method put is for update all resource */
  static METHOD_PUT = 'put';

  static METHOD_PATCH = 'patch';

  classPrefix = '';

  pendingRequests = {};

  makeDispatch = (actionName, data) => {
    store.getStore().dispatch({
      type: actionName,
      data,
    });
  };

  request = (method, url, params, startParams) => {
    const stackTrace = (new Error()).stack; // Only tested in latest FF and Chrome
    let callerName = stackTrace.replace(/^Error\s+/, ''); // Sanitize Chrome
    callerName = callerName.split('\n')[1]; // 1st item is this, 2nd item is caller
    callerName = callerName.replace(/^\s+at Object./, ''); // Sanitize Chrome
    callerName = callerName.replace(/ \(.+\)$/, ''); // Sanitize Chrome
    callerName = callerName.replace(/\@.+/, ''); // Sanitize Firefox
    callerName = this.clearCaller(callerName);

    const actionPrefix = this.toSnake(`${this.classPrefix}_${callerName}`);
    console.log(actionPrefix);
    if (method === 'delete') {
      this.makeDispatch('DELETE_CONFIRMATION_ADD', {
        method,
        url,
        params,
        dispatchActionPrefix: actionPrefix,
        startParams,
      });
    } else {
      this.doRequest(method, url, params, actionPrefix);
      this.makeDispatch(`${actionPrefix}_START`, startParams);
      this.pendingRequests[url] = true;
    }
  };

  doRequest = (method, url, params, dispatchActionPrefix) => {
    if (!this.pendingRequests[url]) {
      this.promiseDoRequest(method, url, params)
        .then((response) => {
          this.makeDispatch(`${dispatchActionPrefix}_SUCCEED`, response.data);
          delete this.pendingRequests[url];
        })
        .catch(() => {
          this.makeDispatch(`${dispatchActionPrefix}_FAILED`);
          delete this.pendingRequests[url];
        });
    }
  };

  promiseDoRequest = (method, url, params, get) => {
    const jwt = JwtStorage.getRawJwt();
    const headers = {};
    if (jwt) {
      headers.Authorization = `Bearer ${jwt}`;
    }

    const dd = get ? '&' : '?';

    let url2 = `${url}${dd}XDEBUG_SESSION_START=PHPSTORM`;

    if (method === BaseAction.METHOD_GET && params) {
      const data = new URLSearchParams(params).toString();
      url2 += `&${data}`;
    }

    return axios({
      method,
      url: `${config.api}${url2}`,
      data: params,
      headers,
    });
  };

  promiseUploadFile = (url, formData, progressCallback) => {
    const jwt = JwtStorage.getRawJwt();
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    if (jwt) {
      headers.Authorization = `Bearer ${jwt}`;
    }

    let url2 = `${url}?XDEBUG_SESSION_START=PHPSTORM`;

    return axios({
      method: BaseAction.METHOD_POST,
      url: `${config.api}${url2}`,
      data: formData,
      headers,
      onUploadProgress: (progressEvent) => progressCallback(progressEvent),
    });
  }

  toSnake = string => string.replace(/(?:^|\.?)([A-Z])/g, (x, y) => `_${y}`).replace(/^_/, '').toUpperCase();

  clearCaller = string => string.split('.', -1).slice(-1).pop();
}

export default BaseAction;
