
export default {
  11: [4, 5, 6, 7, 8],
  12: [4, 5, 6, 7, 8],
  13: [4, 5, 6, 7, 8],
  14: [4, 5, 6, 7, 8],
  15: [4, 5, 6, 7, 8],
  16: [4, 5, 6, 7, 8],
  17: [4, 5, 6, 7, 8],
  18: [4, 5, 6, 7, 8],
  21: [4, 5, 6, 7, 8],
  22: [4, 5, 6, 7, 8],
  23: [4, 5, 6, 7, 8],
  24: [4, 5, 6, 7, 8],
  25: [4, 5, 6, 7, 8],
  26: [4, 5, 6, 7, 8],
  27: [4, 5, 6, 7, 8],
  28: [4, 5, 6, 7, 8],
  31: [4, 5, 6, 7, 8],
  32: [4, 5, 6, 7, 8],
  33: [4, 5, 6, 7, 8],
  34: [4, 5, 6, 7, 8],
  35: [4, 5, 6, 7, 8],
  36: [4, 5, 6, 7, 8],
  37: [4, 5, 6, 7, 8],
  38: [4, 5, 6, 7, 8],
  41: [4, 5, 6, 7, 8],
  42: [4, 5, 6, 7, 8],
  43: [4, 5, 6, 7, 8],
  44: [4, 5, 6, 7, 8],
  45: [4, 5, 6, 7, 8],
  46: [4, 5, 6, 7, 8],
  47: [4, 5, 6, 7, 8],
  48: [4, 5, 6, 7, 8],
  51: [4, 5, 6, 7, 8],
  52: [4, 5, 6, 7, 8],
  53: [4, 5, 6, 7, 8],
  54: [4, 5, 6, 7, 8],
  55: [4, 5, 6, 7, 8],
  61: [4, 5, 6, 7, 8],
  62: [4, 5, 6, 7, 8],
  63: [4, 5, 6, 7, 8],
  64: [4, 5, 6, 7, 8],
  65: [4, 5, 6, 7, 8],
  71: [4, 5, 6, 7, 8],
  72: [4, 5, 6, 7, 8],
  73: [4, 5, 6, 7, 8],
  74: [4, 5, 6, 7, 8],
  75: [4, 5, 6, 7, 8],
  81: [4, 5, 6, 7, 8],
  82: [4, 5, 6, 7, 8],
  83: [4, 5, 6, 7, 8],
  84: [4, 5, 6, 7, 8],
  85: [4, 5, 6, 7, 8],
}
