import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationItem from './NotificationItem';
import notifications from './actions';
import './style.css';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.interval = 0;
  }

  componentDidUpdate() {
    this.startInterval();
  }

  startInterval = () => {
    if (this.props.items.length === 0 && this.interval !== 0) {
      clearInterval(this.interval);
    } else if (this.interval === 0) {
      this.interval = setInterval(() => {
        this.props.dropNotifications(this.props.items);
      }, 4000);
    } else {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.props.dropNotifications(this.props.items);
      }, 4000);
    }
  };

  render() {
    return (
      <div className="notifications">
        { this.props.items.map(item => (
          <NotificationItem item={item} key={item.id} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state.notifications,
});

const mapDispatchToProps = dispatch => ({
  dropNotifications: data => dispatch(notifications(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
