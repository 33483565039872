import { Component } from 'react';
import toothPartsMap from './ToothPartsMap';

class TeethTemplateClass extends Component {
  toothNumber = 0;
  multiSelect = false;
  toothPartNumber = 0;

  toothClickHandler = (toothNumber) => {
    if (this.toothPartNumber > 0) {
      this.toothSelectedHandler(toothNumber, this.toothPartNumber);
    } else {
      this.toothNumber = toothNumber;
      this.toothPartNumber = 0;
    }
  }

  toothPartClickHandler = (toothPartNumber, multiSelect) => {
    this.multiSelect = multiSelect;
    this.toothNumber = 0;
    if (this.toothNumber > 0) {
      this.toothSelectedHandler(this.toothNumber, toothPartNumber);
    } else {
      this.toothPartNumber = toothPartNumber;
    }
  }

  toothSelectedHandler = (toothNumber, toothNumberPartNumber) => {
    this.toothPartNumber = 0;
    this.toothNumber = 0;
    const { onToothSelect, clickable } = this.props;
    if (onToothSelect && clickable) {
      onToothSelect(toothNumber, toothNumberPartNumber, this.multiSelect);
    }
  }

  toothLabelClickHandler = (toothNumber, multiSelect) => {
    const { onToothSelect, clickable } = this.props;

    if (!clickable) {
      return;
    }

    if (toothPartsMap[toothNumber] && toothPartsMap[toothNumber].length) {
      onToothSelect(toothNumber,  toothPartsMap[toothNumber], multiSelect);
    }
  }
}

export default TeethTemplateClass;
