import React, { Component } from 'react';

import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';



class Menu extends Component {
  render() {
    const { clinicId } = this.props;
    return (
      <Navbar fluid>
        <Navbar.Collapse>
          <Nav pullLeft>
            <NavLink to={`/panel/clinics/${clinicId}/detail`} className="nav-item">
              Dane Gabinetu
            </NavLink>
            <NavLink to={`/panel/clinics/${clinicId}/users`} className="nav-item">
              Użytkownicy
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Menu;
