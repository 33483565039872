import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './style.css';
import Loader from '../Loader';
import ScheduleAction from '../../actions/ScheduleAction';
import ConfirmDialogAction from '../../actions/ConfirmDialogAction';
import ConfirmDialog from '../ConfirmDialog';
import CalendarAuthorization from '../../helpers/Calender/CalendarAuthorization';
import DisabledLink from '../Link/DisabledLink';

class EventInfoPopup extends Component {
  renderFullInfo = () => {
    const { isLoading, fullEvent, t } = this.props;
    if (isLoading) {
      return <Loader />;
    }

    return (
      <div>
        <div className="event-property-row">
          <span className="event-property-name">
            <i className="fas fa-phone-alt fa-lg" />
          </span>
          <span className="event-property-value">
            {fullEvent.phoneNumber}
          </span>
        </div>
        <div className="event-property-row">
          <span className="event-property-name">
            <i className="fas fa-id-card fa-lg" />
          </span>
          <span className="event-property-value">
            {fullEvent.personId}
          </span>
        </div>
        <hr />
        <div className="event-property-row">
          <span className="event-property-name">
            Rodzaj wizyty:
          </span>
          <span className="event-property-value">
            {t(fullEvent.visitTypeName)}
          </span>
        </div>
        <div className="event-property-row">
          <span className="event-property-name">
            Dodatkowe informacje:
          </span>
        </div>
        <div className="event-property-row">
          <span className="event-property-description">
            {fullEvent.description}
          </span>
        </div>
      </div>
    );
  };

  cancelEvent = (e) => {
    e.stopPropagation();
    ConfirmDialogAction.openDialog('cancel-visit');
  }

  approveEvent = (e) => {
    e.stopPropagation();
    ConfirmDialogAction.openDialog('approve-visit');
  }

  onConfirmCancel = (eventId) => {
    const { onCancelEventPopup } = this.props;
    ConfirmDialogAction.closeDialog();
    ScheduleAction.promiseCancelEvent(eventId)
      .then(() => {
        if (onCancelEventPopup) {
          onCancelEventPopup();
        }
        ScheduleAction.refreshLastGetList();
      });
  }

  onConfirmApprove = (eventId) => {
    const { onCancelEventPopup } = this.props;
    ConfirmDialogAction.closeDialog();
    ScheduleAction.promiseApproveEvent(eventId)
      .then(() => {
        if (onCancelEventPopup) {
          onCancelEventPopup();
        }
        ScheduleAction.refreshLastGetList();
      });
  }

  render() {
    const { event, user, t, top, left, width } = this.props;

    const style = {
      top,
      left,
      width,
    };

    return (
      <div className="event-info-popup" style={style}>
        <div className="event-property-row">
          <span className="event-property-name">
            <i className="fas fa-user fa-lg" />
          </span>
          <span className="event-property-value">
            {event.name}
            {' '}
            {event.lastName}
          </span>
        </div>
        {this.renderFullInfo()}
        <div className="event-info-popup-quick-menu">
          <div className="icon">
            <DisabledLink
              to={event.idPatient ? `/panel/patients/patient/${event.idPatient}/card/visit/new/${event.id}` : `/panel/patients/register/scheduled-event/${event.id}`}
              title="Przeprowadź wizytę"
              disable={CalendarAuthorization.canMakeAVisit(event, user) ? 0 : 1}
            >
              <i className="fas fa-tooth" />
            </DisabledLink>
          </div>
          <div className="icon">
            <NavLink
              to={event.idPatient ? `/panel/patients/patient/${event.idPatient}/card/personal-data` : `/panel/patients/register/scheduled-event/${event.id}`}
              title="Karta pacjenta"
            >
              <i className="fas fa-id-card-alt" />
            </NavLink>
          </div>
          <div className="bottom-icon">
            <div className="icon">
              <button
                onClick={(e) => this.approveEvent(e, event.id)}
                title={event.status === 'approved' ? 'Wizyta jest już zatwierdzona' :  'Zatwierdź wizytę'}
                disabled={event.status === 'approved'}
              >
                <i className="fas fa-calendar-check" />
              </button>
            </div>
            <div className="icon alert">
              <button
                onClick={(e) => this.cancelEvent(e, event.id)}
                title="Anuluj wizytę"
              >
                <i className="fas fa-calendar-times" />
              </button>
            </div>
          </div>
        </div>
        <ConfirmDialog
          name="cancel-visit"
          title="Potwierdzenie anulowania wizyty"
          cancelTextButton="Nie"
          confirmTextButton="Tak"
          onConfirm={() => this.onConfirmCancel(event.id)}
        >
          Czy na pewno chcesz anulować tę wizytę?
        </ConfirmDialog>
        <ConfirmDialog
          name="approve-visit"
          title="Potwierdzenie zatwierdzenia wizyty"
          cancelTextButton="Nie"
          confirmTextButton="Tak"
          onConfirm={() => this.onConfirmApprove(event.id)}
        >
          Czy na pewno chcesz zatwierdzić tę wizytę?
        </ConfirmDialog>
      </div>
    );
  }
}

export default withTranslation()(EventInfoPopup);
