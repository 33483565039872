import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Route, Switch } from 'react-router-dom';
import Login from '../Login';
import Calender from '../Calender';

import Clinics from '../Clinics';
import PanelMenu from '../../components/PanelMenu';
import Loader from '../../components/Loader';
import UserAction from '../../actions/UserAction';
import Notifications from '../Notifications';
import PatientRoute from '../Patient/PatientRoute';
import ProfileMainPage from '../../Pages/Profile/ProfileMainPage';

class MainPage extends Component {
  componentDidMount() {
    const { login } = this.props;
    if (login.isLogged) {
      UserAction.getSelf();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { login } = this.props;
    if (login.isLogged !== nextProps.login.isLogged && nextProps.login.isLogged) {
      UserAction.getSelf();
    }
  }

  render() {
    const { login, user } = this.props;

    if (!login.isLogged) {
      return (<Login />);
    }

    if (!user.user.id) {
      return (<Loader />);
    }

    return (
      <div>
        <PanelMenu
          user={user.user}
        />
        <Switch>
          <Route exac path="/panel/patients" component={PatientRoute} />
          <Route exac path="/panel/profile/me" component={ProfileMainPage} />
          <Route exac path="/panel/clinics" component={Clinics} />
          <Route exac path="/panel/calendar" component={Calender} />
        </Switch>
        <Notifications />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
  user: state.user,
});

export default connect(mapStateToProps)(MainPage);
