import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import InputMessages from '../../containers/FormWizard/InputMessages';
import DateTime from '../../helpers/DateTime';

import './style.css';

class DateTimeBox extends Component {
  onChangeHandler = (value) => {
    const stringDate = value ? value.format(DateTime.FORMAT_DATE) : '';
    this.props.onChange(stringDate);
  };

  render() {
    const { inputElement } = this.props;
    const {
      validationMessages,
      label,
    } = inputElement;

    let date;
    if (inputElement.value) {
      date = new Date(inputElement.value);
    }

    return (
      <Form.Group>
        {label}
        <DatePicker
          selected={date ? moment(date) : null}
          onChange={this.onChangeHandler}
          dateFormat={DateTime.FORMAT_DATE}
          className="form-control"
          {...inputElement.attributes}
        />
        <InputMessages messages={validationMessages} />
      </Form.Group>
    );
  }
}

export default DateTimeBox;
