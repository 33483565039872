import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Dropdown } from 'react-bootstrap';
import '../custom-toolbar.css';
import ClinicAction from '../../../actions/ClinicAction';
import UserAction from '../../../actions/UserAction';
import ScheduleAction from '../../../actions/ScheduleAction';
import UserEnum from '../../../helpers/Enum/UserEnum';
import DropdownWrapper from './DropdownWrapper';

class CustomButtonSelectDentist extends Component {
  constructor(props) {
    super(props);

    this.state = {
        show: false,
        usersClinicList: [],
    };
  }

  closeDropdown = () => {
    this.setState({
      show: false,
    });
  };

  handleClick = () => {
    this.setState({
      show: true,
    });
  };

  componentDidMount() {
    const { userClinics } = this.props;
    if (userClinics && userClinics.currentClinic && userClinics.selectedClinic.id) {
      this.fetchUsersForClinic(userClinics.selectedClinic.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userClinics) {
      if (nextProps.userClinics.selectedClinic && nextProps.userClinics.selectedClinic.id) {
        const nextCurrentClinicId = nextProps.userClinics.selectedClinic.id;
        const { userClinics } = this.props;
        if (userClinics && userClinics.selectedClinic && userClinics.selectedClinic.id) {
          if (nextCurrentClinicId !== userClinics.selectedClinic.id) {
            this.fetchUsersForClinic(nextCurrentClinicId);
          }
        } else if (nextCurrentClinicId > 0) {
          this.fetchUsersForClinic(nextCurrentClinicId);
        }
      }
    }
  }

  fetchUsersForClinic = (clinicId) => {
    const { user: { selectedUser } } = this.props;
    let setFirst = false;
    ClinicAction.promiseGetUsersOfClinic(clinicId)
      .then((response) => {
        if (response && response.data) {
          if (!selectedUser.id && !setFirst) {
            setFirst = true;
            if (response.data.length) {
              UserAction.changeSelectedUser(response.data[0].user);
              UserAction.changeSelectedUserWorkingDays(response.data[0].userHoursCollection);
            }
          }
          this.setState({
            usersClinicList: response.data,
          });
        }
      });
  }

  onSelectUser = (user, userHoursCollection) => {
    UserAction.changeSelectedUser(user);
    UserAction.changeSelectedUserWorkingDays(userHoursCollection);
    ScheduleAction.refreshLastGetListForNewUser(user.id);
    this.closeDropdown();
  };


  render() {
    const {show, usersClinicList} = this.state;
    const { user: { selectedUser } } = this.props;


    if (usersClinicList.length === 0) {
      return (
          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
      );
    }

    return (
      <span
        className="calendar-toolbar-custom-button-select"
        onClick={this.handleClick}
      >
        Lekarz:
        <span>{`${selectedUser.name} ${selectedUser.lastName}`}</span>
        <i className="fas fa-caret-down" />
        <DropdownWrapper
          onClickOutside={this.closeDropdown}
          show={show}
        >
          {usersClinicList.map(usersClinic => {
            if (usersClinic.userFunction !== UserEnum.TYPE_DENTIST) {
              return null;
            }
            return (
              <Dropdown.Item
                as={'li'}
                className='doctors-dropdown-list'
                eventKey={usersClinic.user.id}
                key={usersClinic.user.id}
                onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
                onSelect={() => this.onSelectUser(usersClinic.user, usersClinic.userHoursCollection)}
              >
                <i className="fas fa-user-md fa-lg" />
                &nbsp;
                &nbsp;
                {usersClinic.user.name}
                &nbsp;
                {usersClinic.user.lastName}
              </Dropdown.Item>
            );
          })}
        </DropdownWrapper>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  userClinics: state.userClinics,
  user: state.user,
});


export default connect(mapStateToProps)(CustomButtonSelectDentist);
