import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Card from './Card';
import Patients from './Patients';
import RegisterNewPatient from './RegisterNewPatient';

class PatientRoute extends Component {
  render() {
    return(
      <Switch>
        <Route exac path="/panel/patients/patient/:patientId/card" component={Card} />
        <Route exac path="/panel/patients/register/scheduled-event/:scheduledEventId" component={RegisterNewPatient} />
        <Route exac path="/panel/patients/register" component={RegisterNewPatient} />
        <Route exac path="/panel/patients" component={Patients} />
      </Switch>

    );
  }
}

export default PatientRoute;
