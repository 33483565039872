import React, { Component } from 'react';


class LocationItem extends Component {
  onClickHandler = () => {
    this.props.onChange(this.props.item);
  }
  render() {
    return (<li onClick={this.onClickHandler} >
      {this.props.item.formatted_address}
    </li>);
  }
}
export default LocationItem;
