import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import ClinicDetailMenu from './ClinicDetailMenu';

import '../Patient/style.css';

class ClinicJambotron extends Component {

  render() {
    const { clinic } = this.props;
    return (
      <Jumbotron className="form-container patient-header-jumbo">
        <div className="patient-jambotron-content">
          <h3>
            Klinika: {clinic.name}
          </h3>
        </div>
        <div className="patient-jambotron-menu">
          <ClinicDetailMenu
            clinic={clinic}
          />
        </div>
      </Jumbotron>
    );
  }
}

export default ClinicJambotron;
