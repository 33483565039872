import React, { Component } from 'react';
import { Toast } from 'react-bootstrap';
import createDOMPurify from 'dompurify'
import DateTime from '../../helpers/DateTime';
const DOMPurify = createDOMPurify(window);

class NotificationItem extends Component {

  intervalId = null;

  constructor(props) {
    super(props);
    this.state  = {
      howLongAgo: '',
    }
  }


  componentDidMount() {
    this.howLongAgo();
    this.intervalId = setInterval(this.howLongAgo, 1000);
  }

  componentWillUnmount() {
  }

  howLongAgo = () => {
    const { notification } = this.props;

    const currentDate = (new Date().getTime()) / 1000 ;
    const notificationDate = (new Date(notification.createAt).getTime()) / 1000;

    const diff = Math.round(currentDate - notificationDate);

    let humanReadableValue = '';

    if (diff < 60) {
      humanReadableValue = `${diff} sek.`;
    } else if (diff < 120) {
      humanReadableValue = `minute temu`;
    } else if (diff < 3600) {
      humanReadableValue = `${Math.round(diff / 60) } min.`;
    } else if (diff < 7200) {
      humanReadableValue = `godzinę temu`;
    } else if (diff < 3600 * 12) {
      humanReadableValue = DateTime.dateTimeStringToTimeString(notification.createAt);
    } else {
      humanReadableValue = notification.createAt;
    }

    this.setState({
      howLongAgo: humanReadableValue,
    });
  }

  render() {
    const { notification, onClose } = this.props;
    const { howLongAgo } = this.state;

    return (
      <Toast show={true} onClose={() => {onClose(notification)}}>
        <Toast.Header
          closeButton={!notification.readed}
        >
          <div className="title">
            {notification.subject}
          </div>

          <div className="data">
            <small>{howLongAgo}</small>
          </div>
        </Toast.Header>
        <Toast.Body>
          <div
            className="body-wrapper"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.content) }}
          />
        </Toast.Body>
      </Toast>
    );
  }
}

export default NotificationItem;
