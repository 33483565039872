import React, { Component } from 'react';

import DateTime from '../../../helpers/DateTime';
import './style.css';


class EventWrapper extends Component {

  goToDate = (date) => {
    const { calendarRef } = this.props;
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView('timeGridDay');
    calendarApi.gotoDate(date);
  }

  render() {
    const { fcEvent } = this.props;
    const { _def: { extendedProps } } = fcEvent.event;

    if (fcEvent.view.type === 'dayGridMonth') {
      return (
        <div
          className="custom-event-wrapper-month"
          onClick={() => this.goToDate(fcEvent.event.start)}
        >
          <div className="title">
            {fcEvent.event.title}
          </div>
        </div>
      );
    }


    return (
      <div
        className="custom-event-wrapper"
      >
        <div className="patient-info">
          {extendedProps.name}
          &nbsp;
          {extendedProps.lastName}
        </div>
        <div
          className="datetime"
        >
          {DateTime.dateToTimeString(fcEvent.event.start)}
          &nbsp;
          <small>-</small>
          &nbsp;
          {DateTime.dateToTimeString(fcEvent.event.end)}
        </div>
      </div>
    );
  }
}

export default EventWrapper;


