import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Jumbotron,
  Alert,
  Col
} from 'react-bootstrap';

import TeethDiagram from './Card/TeethDiagram';
import Notes from './Visit/Notes';
import ElementOverall from '../../components/Overall/ElementOverall';
import VisitAction from '../../actions/VisitAction';
import ScheduleAction from '../../actions/ScheduleAction';
import './style.css';
import SaveButton from '../../components/SaveButton';



class Visit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitForVisit: true,
      overAllText: 'Czekaj',
      scheduleItem: {},
      isSaving: false,
    };
  }

  componentDidMount() {
    const { match: { params }, user: { user } } = this.props;
    VisitAction.getVisitForScheduleItemId(params.scheduleId)
      .then((data) => {
        // load visit if exist
        if (data.data.clinic) {
          const savedVisit = VisitAction.loadVisitFromLocalStorage(params.scheduleId, user.id, data.data.clinic.id, data.data.patient.id);
          if (savedVisit) {
            VisitAction.loadVisitFromData(savedVisit);
          }
        }
      });

    ScheduleAction.promiseGetEvent(params.scheduleId).then((data) => {
      this.setState({
        waitForVisit: false,
        overAllText: 'Rozpocznij wizytę',
        scheduleItem: data.data,
      });
    });

    // here handle press reload
    window.onbeforeunload = () => {
      this.saveVisitOnLocalStorage();
    }

    // here handle close tab
    window.beforeunload = () => {
      this.saveVisitOnLocalStorage();
    }
  }

  componentWillUnmount() {
    this.saveVisitOnLocalStorage();
  }

  saveVisitOnLocalStorage = () => {
    const { scheduleItem } = this.state;
    const { visit, user: { user } } = this.props;
    if (visit.visitWasUpdated) {
      VisitAction.saveVisitOnLocalStorage(
        scheduleItem.id,
        user.id,
        scheduleItem.idClinic,
        scheduleItem.idPatient,
        visit.visit
      );
      VisitAction.clearVisit();
    }
  }

  startVisit = () => {
    this.setState({
      waitForVisit: true,
      overAllText: 'Rozpoczynam',
    });
    const { scheduleItem } = this.state;
    VisitAction.promiseAddVisit(
      scheduleItem.patientId,
      scheduleItem.clinicId,
      scheduleItem.id,
      '',
    ).then(() => {
      VisitAction.getVisitForScheduleItemId(scheduleItem.id);
    }).catch(() => {
      this.setState({
        waitForVisit: false,
        overAllText: 'Rozpocznij wizytę',
      });
    });
  }

  endVisit = () => {
    const { scheduleItem } = this.state;
    const { visit, user: { user } } = this.props;
    this.setState({
      isSaving: true,
    });
    VisitAction.promiseCloseVisit({ ...visit.visit })
      .then((data) => {
        this.setState({
          isSaving: false,
        });
        VisitAction.removeVisitFromLocalStorage(scheduleItem.id, user.id, scheduleItem.idClinic, scheduleItem.idPatient);
      });
  };

  showOverall() {
    const { visit } = this.props;
    const { waitForVisit, overAllText } = this.state;
    if (!visit.visit.id) {
      return (
        <ElementOverall
          buttonText={overAllText}
          onClick={this.startVisit}
          isLoading={waitForVisit}
          zIndex={1000}
        />
      );
    }
    return null;
  }

  render() {
    const { isSaving, scheduleItem } = this.state;
    const { user: { user } } = this.props;


    if (scheduleItem && scheduleItem.doctor && scheduleItem.doctor.id !== user.id) {
      return (
        <Alert variant="danger">
          Ta wizyta nie nalezy do Ciebie!
        </Alert>
      );
    }


    return (
      <div className="visit-main-container">
        {this.showOverall()}
        <Jumbotron className="form-container patient-jumbo">
          <Col xs={{ span: 2, offset: 10 }}>
            <SaveButton
              onSave={this.endVisit}
              text="Zakończ wizytę"
              isLoading={isSaving}
            />
          </Col>
          <Notes />
          <TeethDiagram />
          <Col xs={{ span: 4, offset: 5 }}>
            <SaveButton
              onSave={this.endVisit}
              text="Zakończ wizytę"
              isLoading={isSaving}
            />
          </Col>
        </Jumbotron>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  visit: state.visit,
  user: { ...state.user },
});


export default connect(mapStateToProps)(Visit);
