import BaseAction from './BaseAction';

class RegisterAction extends BaseAction {
  classPrefix = 'register';

  promiseRegisterAction = (registerFormData) => {
    const url = '/api/register';
    const data = {
        employeeData: {
          name: registerFormData.fields.name.value,
          lastName: registerFormData.fields.lastName.value,
          email: registerFormData.fields.email.value,
          password: registerFormData.fields.password.value,
          phoneNumber: registerFormData.fields.phoneNumber.value,
        },
        clinicData: {
          name: registerFormData.fields.cabinetName.value,
          city: registerFormData.fields.city.value,
          postcode: registerFormData.fields.postalCode.value,
          street: registerFormData.fields.street.value,
          houseNumber: registerFormData.fields.streetNumber.value,
        },
    };

    return this.promiseDoRequest('post', url, data);
  }
}

export default new RegisterAction();
