import React, { Component } from 'react';
import { Badge, Toast, Navbar } from 'react-bootstrap';
import { FaBell } from 'react-icons/fa';
import onClickOutside from 'react-onclickoutside';
import './style.css';
import UserAction from '../../actions/UserAction';
import NotificationItem from './NotificationItem';
import IconButton from '../../components/Button/IconButton';
import ScheduleAction from '../../actions/ScheduleAction';


class Notification extends Component {

  intervalId = null;

  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      unread: 0,
      show: false,
    };
  }

  componentDidMount() {
    this.getNotifications();
    this.intervalId = setInterval(this.getNotifications, 1000 * 60 * 5);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  dispatchNotificationAction = (notification) => {

    if (!notification.data) {
      return;
    }

    switch (notification.type) {
      case 'patient_approve_visit':
      case 'patient_cancel_visit':
        this.updateScheduleEvent(notification.data);
        break;
      default:
        return;
    }
  }


  updateScheduleEvent = (event) => {
    ScheduleAction.updateEvent(event);
  }

  getNotifications = () => {
    UserAction.promiseGetNotifications()
      .then((response) => {

        let unread = 0;

        response.data.forEach((item) => {
          if (!item.readed) {
            unread += 1;
            this.dispatchNotificationAction(item);
          }
        });

        this.setState({
          notifications: response.data,
          unread,
        });
      });
  };

  toggleList = (show) => {
    this.setState({
      show: !show,
    });
  }

  handleClickOutside = () => {
    this.setState({
      show: false,
    });
  }

  showBudge = (number) => {
    if (number <= 0) {
      return null;
    }

    return (
      <div className="badge-wrapper">
        <Badge variant="primary">{number}</Badge>
      </div>
    );
  }

  onCloseNotificationHandler = (notification) => {
    const { notifications, unread } = this.state;
    const newNotifications = notifications.filter((notificationItem) => notificationItem.id !== notification.id);
    this.setState({
      notifications: newNotifications,
      unread: unread - 1,
    });

    UserAction.promiseMarkNotificationsAsRead(notification.id).then(() => {
      this.getNotifications();
    });
  };



  render() {
    const { unread, notifications, show } = this.state;
    return (
      <div className="notification-wrapper">
        <div className="icon-wrapper">
          <IconButton
            onClick={() => this.toggleList(show)}
          >
            <FaBell />
          </IconButton>
          {this.showBudge(unread)}
        </div>
        <div className="toast-wrapper" style={{ display: show ? 'block' : 'none' }}>
          <Navbar className="notification-header" sticky="top">
            <h6>Powiadomienia</h6>
          </Navbar>
          {notifications.map((notification) =>
            <NotificationItem
              key={notification.id}
              notification={notification}
              onClose={this.onCloseNotificationHandler}
            />
          )}
        </div>
      </div>
    );
  }
}

export default onClickOutside(Notification);
