import React, { Component } from 'react';
import { connect } from 'react-redux';
import RegisterForm from './RegisterForm';
import UserAction from '../../actions/UserAction';
import ClinicAction from '../../actions/ClinicAction';
import Loader from '../../components/Loader';
import ScheduleAction from '../../actions/ScheduleAction';


class RegisterNewPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsLoading: false,
      needLoadEventData: false,
      showRootClinic: true,
      scheduledEventId: 0,
      scheduledEventClinicId: 0,
      patient: {},
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (this.props.match && this.props.match.params && this.props.match.params.scheduledEventId) {
      this.setState({
        needLoadEventData: true,
        showRootClinic: false,
        scheduledEventId: this.props.match.params.scheduledEventId,
      });
      this.loadEventData(this.props.match.params.scheduledEventId);
    }

    ClinicAction.getUserClinics(user.user.id);
  }

  loadEventData = (scheduledEventId) => {
    ScheduleAction.promiseGetEvent(scheduledEventId)
      .then((response) => {
        console.log(response.data);

        this.setState({
          patient: {
            name: response.data.name,
            lastName: response.data.lastName,
            email: response.data.email,
            phone: response.data.phoneNumber,
            optionalPhone: response.data.optionalPhoneNumber,
            personId: response.data.personId,
          },
          scheduledEventClinicId: response.data.clinicId
        });

        this.setState({
          needLoadEventData: false,
        });
      });
  }

  onSubmitHandler = (registerForm) => {
    if (registerForm.isValid) {
      this.setState({ formIsLoading: true });
      const { scheduledEventId, scheduledEventClinicId } = this.state;
      const { user } = this.props;

      UserAction.promiseRegisterPatient(scheduledEventId, user.user.id, registerForm, scheduledEventClinicId).then((data) => {
        this.setState({ formIsLoading: false });
        if (data && data.status <= 200) {
          window.location.href = `/panel/patients/patient/${data.data.patientId}/card`;
        }
      });
    }
  };


  renderForm = () => {
    const { userClinics } = this.props;
    const { formIsLoading, needLoadEventData, patient, showRootClinic } = this.state;

    if (needLoadEventData) {
      return <Loader />;
    }
    return (
      <RegisterForm
        patient={patient}
        formIsLoading={formIsLoading}
        onSubmit={this.onSubmitHandler}
        showRootClinic={showRootClinic}
        userClinics={userClinics}
      />
    );
  }

  render() {
    return (
      <div className="form-container container">

        <h1 className="text-center">
          Dodawanie nowego pacjenta
        </h1>
        {this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userClinics: state.userClinics,
  user: state.user,
});


export default connect(mapStateToProps)(RegisterNewPatient);
