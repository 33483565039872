import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Col, Jumbotron, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import PatientAction from '../../actions/PatientAction';
import TableView from '../../components/TableView';
import SearchPatientPanel from '../../components/Patient/SearchPatientPanel';
import ClinicAction from '../../actions/ClinicAction';

import './style.css';
import Pagination from '../../components/Pagination/Pagination';

class Patients extends Component {
  tableColumns = [
    {
      text: 'Imię',
      property: 'name',
    },
    {
      text: 'Nazwisko',
      property: 'lastName',
    },
    {
      text: 'PESEL',
      property: 'personId',
    },
    {
      text: 'E-mail',
      property: 'email',
    },
    {
      text: 'Nr Tel.',
      property: 'phoneNumber',
    },
  ];

  buttons = [
    {
      button: item => (
        <Link
          key={`p-edit-${item.id}`}
          to={`/panel/patients/patient/${item.id}/card/personal-data`}
          className="link-edit"
        >
          <Button
            className="patient-card-btn">
            Karta pacjenta
          </Button>
        </Link>
      ),
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      collection: {collection: [], meta: {}},
      page: 0,
      pageSize: 10,
      searchData: 10,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    ClinicAction.getUserClinics(user.user.id);

    this.searchPatientsRequest();
  }

  onSearchCallback = (searchData) => {
    this.setState({searchData}, this.searchPatientsRequest);
  }

  onPageChangeHandler = (page) => {
    this.setState({page}, this.searchPatientsRequest);
  }
  onPageSizeChangeHandler = (pageSize) => {
    this.setState({pageSize}, this.searchPatientsRequest);
  }

  searchPatientsRequest = () => {
    const searchData = { ...this.state.searchData};
    searchData.page = this.state.page;
    searchData.pageSize = this.state.pageSize;

    this.setState({isLoading: true});

    PatientAction.promiseGetAllMyPatients(searchData).then((response) => {
      this.setState({
        collection: response.data,
        isLoading: false,
      });
    }).catch(() => {
      this.setState({
        isLoading: false,
      });
    });
  };

  showResults = () => {
    const { collection, isLoading } = this.state;

    if (isLoading && collection.collection.length === 0) {
      return <Loader />;
    }

    if (collection.collection.length === 0) {
      return (
        <Alert variant="warning">
          Nie masz jeszcze żadnych pacjentów
        </Alert>
      );
    }

    return (
      <Row>
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <Jumbotron>
            <TableView
              columns={this.tableColumns}
              items={collection.collection}
              buttons={this.buttons}
            />
            <Pagination
              currentPage={this.state.page}
              pageSize={this.state.pageSize}
              totalRows={collection.meta.totalRows}
              onPageChange={this.onPageChangeHandler}
              onPageSizeChange={this.onPageSizeChangeHandler}
            />
          </Jumbotron>
        </Col>
      </Row>

    );
  }

  render() {
    const { userClinics } = this.props;

    return (
      <div>
        <SearchPatientPanel
          clinics={userClinics.userAllClinics}
          onSearch={this.onSearchCallback}
          rootRow
        />
        {this.showResults()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userClinics: state.userClinics,
  user: state.user,
});


export default connect(mapStateToProps)(Patients);
