import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import Logo from '../Logo';
import './style.css';


class LogoNavbar extends Component {
    render() {
        return (
            <Navbar className='justify-content-between' fixed="top" style={{ background: "var(--dark-blue)" }}>
                <Navbar.Brand href="/">
                    < Logo />
                </Navbar.Brand>
            </Navbar >
        );
    }
}

export default LogoNavbar;