import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import ConfirmDialogAction from '../../actions/ConfirmDialogAction';

class ConfirmDialog extends Component {
  static openDialog = () => {
    ConfirmDialogAction.openDialog();
  }

  static closeDialog = () => {
    ConfirmDialogAction.closeDialog();
  }

  showModalHeader = (modalHeader) => {
    if (!modalHeader) {
      return null;
    }
    return (
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">
          {modalHeader}
        </Modal.Title>
      </Modal.Header>
    );
  }

  onConfirmHandler = () => {
    const { onConfirm, confirmDialog } = this.props;
    if (onConfirm) {
      onConfirm(confirmDialog.onConfirmData);
    }
  }

  render() {
    const {
      confirmDialog,
      name,
      title,
      children,
      cancelTextButton,
      confirmTextButton,
    } = this.props;

    if (confirmDialog.dialogOpenName !== name) {
      return null;
    }

    return (
      <Modal
        show={confirmDialog.isOpen}

      >
        {this.showModalHeader(title)}
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={this.onConfirmHandler}
          >
            {confirmTextButton}
          </Button>
          <Button
            variant="danger"
            onClick={() => ConfirmDialog.closeDialog()}
          >
            {cancelTextButton}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  confirmDialog: state.confirmDialog,
});

export default connect(mapStateToProps)(ConfirmDialog);
