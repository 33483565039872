import React, { Component } from 'react';
import ModuleViewWithMenu from '../../../layout/ModuleViewWithMenu';
import Documents from './Scans/Documents';
import Images from './Scans/Images';
import PatientCards from './Scans/PatientCards';

const menuItems = (patientId) => ([
  {
    label: 'patients_cards',
    to: `/panel/patients/patient/${patientId}/card/scans/patient-cards`,
    route: `/panel/patients/patient/:patientId/card/scans/patient-cards`,
    component: PatientCards

  },
  {
    label: 'documents',
    to: `/panel/patients/patient/${patientId}/card/scans/documents`,
    route: `/panel/patients/patient/:patientId/card/scans/documents`,
    component: Documents
  },
  {
    label: 'images',
    to: `/panel/patients/patient/${patientId}/card/scans/images`,
    route: `/panel/patients/patient/:patientId/card/scans/images`,
    component: Images
  }
]);


class Scans extends Component {
  render() {
    const { match: { params } } = this.props;
    return (
      <ModuleViewWithMenu
        menuItems={menuItems(params.patientId)}
      />
    );
  }
}

export default Scans;
