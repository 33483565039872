import React, { Component } from 'react';
import {Dropdown } from 'react-bootstrap';
import onClickOutside from 'react-onclickoutside';

class DropdownWrapper extends Component {

  handleClickOutside = () => {
    const {onClickOutside} = this.props;
    onClickOutside();
  };

  render() {
    const {show} = this.props;

    if (!show) {
      return null;
    }

    return (
      <Dropdown as={'ul'} className="calendar-toolbar-custom-button-select-dropdown">
        {this.props.children}
      </Dropdown>
    );

  }

}

export default onClickOutside(DropdownWrapper);
