import TeethUpdateManager from '../helpers/Teeth/TeethUpdateManager';

const initState = {
  visit: {
    teeth: {},
    description: '',
  },
  visitWasUpdated: false,
  isLoading: true,
  success: false,
  visitTypes: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'VISIT_CLEAR_VISIT': {
      const newState = { ...state };
      newState.visit = {
        teeth: {},
        description: '',
      };
      newState.visitWasUpdated = false;
      return newState;
    }
    case 'VISIT_GET_VISIT_FOR_SCHEDULE_ITEM_ID_START': {
      const newState = { ...state };
      newState.isLoading = true;
      newState.visit = {
        teeth: {},
        description: '',
      };
      newState.visitWasUpdated = false;
      return newState;
    }
    case 'VISIT_GET_VISIT_FOR_SCHEDULE_ITEM_ID_FAILED': {
      const newState = { ...state };
      newState.isLoading = false;
      return newState;
    }
    case 'VISIT_GET_VISIT_FOR_SCHEDULE_ITEM_ID_SUCCEED': {
      const newState = { ...state };
      newState.isLoading = false;
      newState.success = true;
      newState.visit = action.data;
      newState.visitWasUpdated = false;
      return newState;
    }
    case 'VISIT_CLOSE_VISIT': {
      const newState = { ...state };
      newState.visit.status = 'closed';
      newState.visitWasUpdated = false;
      return newState;
    }
    case 'VISIT_ADD_RECOGNITION_TOOTH_PART': {
      const newState = { ...state };
      const { teeth } = newState.visit;
      const manger = new TeethUpdateManager(teeth);

      newState.visit.teeth = manger.updateToothRecognition(
        action.data.toothNumber,
        action.data.toothPartNumber,
        action.data.recognition
      );
      newState.visitWasUpdated = true;
      return newState;
    }
    case 'VISIT_ADD_PROCEDURE_TOOTH_PART': {
      const newState = { ...state };
      const { teeth } = newState.visit;
      const manger = new TeethUpdateManager(teeth);

      newState.visit.teeth = manger.updateToothProcedure(
        action.data.toothNumber,
        action.data.toothPartNumber,
        action.data.procedure
      );
      newState.visitWasUpdated = true;
      return newState;
    }
    case 'VISIT_REMOVE_RECOGNITION_TOOTH_PART': {
      const newState = { ...state };

      const { teeth } = newState.visit;
      const manger = new TeethUpdateManager(teeth);

      newState.visit.teeth = manger.deleteRecognitionFromToothPart(
        action.data.toothNumber,
        action.data.toothPartNumber,
        action.data.recognition,
      );
      newState.visitWasUpdated = true;
      return newState;
    }

    case 'VISIT_REMOVE_PROCEDURE_TOOTH_PART': {
      const newState = { ...state };
      const { teeth } = newState.visit;
      const manger = new TeethUpdateManager(teeth);

      newState.visit.teeth = manger.deleteProcedureFromToothPart(
        action.data.toothNumber,
        action.data.toothPartNumber,
        action.data.procedure,
      );
      newState.visitWasUpdated = true;
      return newState;
    }

    case 'VISIT_UPDATE_TOOTH_DESCRIPTION': {
      const newState = { ...state };
      const { teeth } = newState.visit;

      if (!teeth[action.data.toothNumber]) {
        teeth[action.data.toothNumber] = {
          parts: {},
          updated: true,
          tootRecognition: {
            description: action.data.description,
          },
          toothNumber: action.data.toothNumber,
        };
      } else {
        const tooth = { ...teeth[action.data.toothNumber]};
        if (!tooth.tootRecognition) {
          tooth.tootRecognition = {};
        }

        tooth.tootRecognition.description = action.data.description;
        tooth.updated = true;
        teeth[action.data.toothNumber] = tooth;
      }

      newState.visit.teeth = { ...teeth };
      newState.visitWasUpdated = true;
      return newState;
    }

    case 'VISIT_GET_VISIT_TYPES_SUCCEED': {
      const newState = { ...state };
      newState.visitTypes = action.data;
      return newState;
    }
    case 'VISIT_UPDATE_NOTES': {
      const newState = { ...state };
      newState.visit.description = action.data;
      newState.visitWasUpdated = true;
      return newState;
    }
    case 'VISIT_LOAD_VISIT_FROM_VISIT_DATA': {
      const newState = { ...state };
      newState.visit = action.data;
      newState.visitWasUpdated = false;
      return newState;
    }
    default:
      return state;
  }
};
