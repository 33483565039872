import React, { Component } from 'react';
import Loader from '../../../../components/Loader';
import UserAction from '../../../../actions/UserAction';
import NotificationSettingItem from '../../../../components/User/Notifications/NotificationSettingItem';

class Notification extends Component {

  constructor(props) {
    super(props);

    this.state = {
      settings: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    UserAction.promiseGetNotificationsSettings().then((response) => {
      this.setState({
        settings: response.data,
      });
    })
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  }

  onChangeHandler = (notificationSettingItem) => {
    UserAction.promiseUpdateNotificationsSettings(
      notificationSettingItem.notificationType,
      notificationSettingItem.enabled,
    ).then((response) => {
      if (response.status !== 202) {
        this.getSettings();
      }
    }).catch(() => {
      this.getSettings();
    });
  };

  render() {

    const {isLoading, settings} = this.state;

    if (isLoading) {
      return <Loader />
    }

    return (
      <div>
        <h1>
          Powiadom mnie gdy:
        </h1>
        <div>
          {settings.map((notificationSettingItem) => <NotificationSettingItem
            key={notificationSettingItem.notificationType}
            notificationSettingItem={notificationSettingItem}
            onChange={this.onChangeHandler}
          />)}
        </div>
      </div>
    );
  }
}

export default Notification;
