import React, { Component } from 'react';
import Menu from '../../components/Menu';
import { Button } from 'react-bootstrap';
import './style.css';
import ImgContent from './imgs/dentmate-landing.png';
import { NavLink } from 'react-router-dom';
import Footer from '../Footer';


class LandingPage extends Component {
    render() {
        return (
            <div>
                < Menu />
                <div className="style_Header container-fluid">
                    <div className="style_content">
                        <h1>Pracuj WYGODNIEJ.SZYBCIEJ.NOWOCZEŚNIEJ</h1>
                        <p>Zorganizuj swoją pracę i zadbaj o swoich pacjentów</p>
                    </div>
                    <div className="trial-btn">
                        <NavLink to="/register">
                            <Button >
                                Wypróbuj teraz - pierwszy miesiąc za darmo
                            </Button>
                        </NavLink>
                    </div>
                </div>
                <div className="offer-3">
                    <div className="style-offer">
                        <img src={ImgContent} alt="" />
                    </div>
                </div>
                < Footer />
            </div>

        );
    }
}

export default LandingPage;