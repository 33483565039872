import BaseAction from './BaseAction';

class ClinicAction extends BaseAction {
  promiseSearchClinics = (name) => {
    const url = '/web/clinics/search-by-name';
    const params = {
      clinic_form: {
        name,
      },
    };

    return this.promiseDoRequest(BaseAction.METHOD_POST, url, params);
  };

  promiseGetUserClinics = (userId) => {
    const url = `/web/clinics/user-clinics/${userId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  };

  getUserClinics = (userId) => {
    const url = `/web/clinics/user-clinics/${userId}`;
    this.request(BaseAction.METHOD_GET, url);
  };

  changeUserCurrentClinic = (clinic) => {
    this.makeDispatch('ACTION_USER_CHANGE_CURRENT_CLINIC', clinic);
  };

  changeCurrentClinic = (clinic) => {
    this.makeDispatch('ACTION_USER_CLINICS_SET_CURRENT_CLINIC', clinic);
  };

  promiseGetUserOwnClinics = (userId) => {
    const url = `/web/clinics/user-own-clinics/${userId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  };

  promiseGetUserClinic = (clinicId) => {
    const url = `/api/clinic/${clinicId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  };

  promiseAddClinic = (clinicFormData) => {
    const url = '/api/clinic/';
    const formData = {
      advanced_clinic_form: {
        name: clinicFormData.fields.name.value,
        city: clinicFormData.fields.city.value,
        postcode: clinicFormData.fields.postCode.value,
        street: clinicFormData.fields.street.value,
        streetNumber: clinicFormData.fields.streetNumber.value,
        latitude: clinicFormData.fields.latitude.value,
        longitude: clinicFormData.fields.longitude.value,
        openFrom: clinicFormData.fields.openFrom.value,
        openTo: clinicFormData.fields.openTo.value,
      },
    };

    return this.promiseDoRequest(BaseAction.METHOD_POST, url, formData);
  };

  promiseUpdateClinic = (clinicId, clinicFormData) => {
    const url = `/api/clinic/${clinicId}`;
    const formData = {
      advanced_clinic_form: {
        name: clinicFormData.fields.name.value,
        city: clinicFormData.fields.city.value,
        postcode: clinicFormData.fields.postCode.value,
        street: clinicFormData.fields.street.value,
        streetNumber: clinicFormData.fields.streetNumber.value,
        latitude: clinicFormData.fields.latitude.value || 0,
        longitude: clinicFormData.fields.longitude.value || 0,
        openFrom: clinicFormData.fields.openFrom.value,
        openTo: clinicFormData.fields.openTo.value,
      },
    };

    return this.promiseDoRequest(BaseAction.METHOD_PUT, url, formData);
  };

  promiseGetUsersOfClinic = (clinicId) => {
    const url = `/api/clinic/list-users-of-clinic/${clinicId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }
}

export default new ClinicAction();
