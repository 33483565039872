import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Modal,
  Button,
} from 'react-bootstrap';
import UserEnum from '../../helpers/Enum/UserEnum';
import FormsAction from '../../actions/FormsAction';
import Loader from '../Loader';
import formSelector from '../../helpers/selectors/formSelector';
import Input from '../Inputs/Input';
import SaveButton from '../SaveButton';
import TableView from '../TableView';
import ConfirmDialog from '../ConfirmDialog';
import ConfirmDialogAction from '../../actions/ConfirmDialogAction';
import InvitationsView from './InvitationsView';

class UsersView extends Component {
  tableColumns = [
    {
      text: 'Imię i Naziwsko',
      property: 'name',
      onText: item => `${item.user.name} ${item.user.lastName}`,
    },
    {
      text: 'Adres e-mail',
      property: 'user.email',
      onText: item => item.user.email,
    },
  ];

  buttons = [
    {
      id: 'delete-button',
      button: item => (this.renderButton(item)),
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      formIsOk: false,
      sending: false,
    };

    FormsAction.registerForm('InviteUser').then(() => {
      this.setState({ formIsOk: true });
    });
  }

  renderButton = (item) => {
    if (item.type === 'owner') {
      return (
        <Button
          key="delete-button"
          variant="link"
        >
          <i className="fas fa-user-shield" />
        </Button>
      );
    }
    return (
      <Button
        variant="danger"
        onClick={() => ConfirmDialogAction.openDialog('delete-user-form-clinic', item.user.id)}
      >
        Usuń
      </Button>
    );
  }

  onAddHandler = () => {
    this.setState({
      modalIsOpen: true,
    });
  }

  handleHideModal = () => {
    this.setState({ modalIsOpen: false });
    FormsAction.resetForm('InviteUser');
  }


  updateValue = (name, value) => {
    FormsAction.updateValue('InviteUser', name, value);
  };

  sendInvitation = () => {
    const { inviteUserForm, clinic, type, onSendInvitation } = this.props;
    FormsAction.checkFormValidation('InviteUser');
    this.setState({
      sending: true,
    });

    if (inviteUserForm.isValid) {
      onSendInvitation(
        inviteUserForm.fields.email.value,
        clinic.id,
        type,
      ).then((response) => {
        if (response && response.status === 201) {
          FormsAction.resetForm('InviteUser');
          this.setState({
            modalIsOpen: false,
            sending: false,
          });
        }
      });
    } else {
      this.setState({
        sending: false,
      });
    }
  }

  getTitle = () => {
    const { type, clinic } = this.props;

    switch (type) {
      case UserEnum.TYPE_DENTIST:
        return <span>Lekarze pracujący w {clinic.name}:</span>;
      default:
        return <span>Użytkownicy pracujący w {clinic.name}:</span>;
    }
  }

  getModalTitle = () => {
    const { type } = this.props;
    switch (type) {
      case UserEnum.TYPE_DENTIST:
        return <span>Zaproś lekarza do kliniki:</span>;
      default:
        return <span>Zaproś pracownika do kliniki:</span>;
    }
  }

  render() {
    const { modalIsOpen, formIsOk, sending } = this.state;
    const {
      maxAccounts,
      inviteUserForm,
      users,
      onDelete,
      invitations,
      onResendInvitation,
      onCancelInvitation,
    } = this.props;

    if (!formIsOk) {
      return <Loader />;
    }

    return (
      <Row>
        <Col sm={12}>
          <h5>
            Ilość kont: 0/{maxAccounts}
          </h5>
          <h3>
            {this.getTitle()}
            <Button
              className="back-button"
              style={{ backgroundColor: '#4098ff', borderColor: '#4098ff', color: '#fff' }}
              onClick={this.onAddHandler}
            >
              Dodaj/Zaproś
            </Button>
          </h3>

          <Row>
            <Col sm={12} md={6}>
              <InvitationsView
                invitations={invitations}
                onResendInvitation={onResendInvitation}
                onCancel={onCancelInvitation}
              />
              <TableView
                paginator={false}
                columns={this.tableColumns}
                items={users}
                buttons={this.buttons}
              />
            </Col>
          </Row>
        </Col>
        <Modal
          show={modalIsOpen}
          onHide={this.handleHideModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.getModalTitle()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Input
              inputElement={inviteUserForm.fields.email}
              onChange={value => this.updateValue('email', value)}
            />
            <SaveButton
              variant="info"
              disabled={!inviteUserForm.isValid}
              onSave={this.sendInvitation}
              isLoading={sending}
              text="Wyślij zaproszenie"
            />
          </Modal.Body>
        </Modal>
        <ConfirmDialog
          name="delete-user-form-clinic"
          cancelTextButton="Anuluj"
          confirmTextButton="Usuń"
          onConfirm={onDelete}
        >
          Na pewno chcesz usunąć tego pracownika z tej kliniki?
        </ConfirmDialog>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  inviteUserForm: formSelector(state.forms, 'InviteUser'),
});

export default connect(mapStateToProps)(UsersView);
