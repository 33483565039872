import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';


class PatientCardMenu extends Component {

  currentVisit = () => {
    const { patient } = this.props;

    let currentVisit = patient.currentVisit;
    if (!patient.currentVisit && patient.scheduleIdFromLatestOpenVisit > 0 && patient.scheduleIdFromLatestOpenVisit === patient.scheduleIdFromLatestVisit) {
      currentVisit = patient.scheduleIdFromLatestOpenVisit;
    }

    if (!currentVisit) {
      return (
        <Nav.Link
          disabled
        >
          Aktualna wizyta
        </Nav.Link>
      );
    }

    const kropka = currentVisit === patient.scheduleIdFromLatestOpenVisit
      ? <span className="blob" />
      : ''

    return (
      <NavLink
        to={`/panel/patients/patient/${patient.id}/card/visit/new/${currentVisit}`}
        disabled={currentVisit === 0}
        className="nav-link"
      >
        <div className="patient-active-visit">
          {kropka} Aktualna wizyta
        </div>
      </NavLink>
    )
  };

  render() {
    const { patient } = this.props;
    return (
      <div className="patient-navbar-wrapper">
        <Navbar className="patient-navbar left">
          <NavLink
            to={`/panel/patients/patient/${patient.id}/card/personal-data`}
            className="nav-link"
          >
            Dane osobowe
          </NavLink>
          <NavLink
            to={`/panel/patients/patient/${patient.id}/card/history`}
            className="nav-link"
          >
            Historia wizyt
          </NavLink>
          <NavLink
            to={`/panel/patients/patient/${patient.id}/card/scans/patient-cards`}
            className="nav-link"
          >
            Skany
          </NavLink>
        </Navbar>
        <Navbar className="patient-navbar right">
          {this.currentVisit()}
        </Navbar>
      </div>
    );
  }
}

export default PatientCardMenu;
