import React, { Component } from 'react';
import DateTime from '../../helpers/DateTime';
import EventsTableList from '../Schedule/EventsTableList';
import { Button, Modal } from 'react-bootstrap';

class VisitCards extends Component {
  constructor(props) {
    super(props);



    this.state = {
      previousVisit: null,
      nexVisit: null,
      futureVisits: null,
      showModal: false,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.visits
      && nextProps.visits
      && this.props.visits.length !== nextProps.visits.length
    ) {
      this.updateState(nextProps.visits);
    }
  }

  componentDidMount() {
    this.updateState(this.props.visits);
  }

  updateState = (visits) => {
    let previousVisit = null;
    let nexVisit = null
    const futureVisits = [];

    const currentDate = new Date();

    visits.forEach((visit) => {
      const visitDateTill = new Date(visit.dateTill);
      const visitDateFrom = new Date(visit.dateFrom);

      if (currentDate > visitDateTill && (!previousVisit || visitDateTill > new Date(previousVisit.dateTill))) {
        previousVisit = visit;
      }

      if (currentDate < visitDateFrom && (!nexVisit || nexVisit < new Date(nexVisit.dateTill))) {
        nexVisit = visit;
      }

      if (currentDate < visitDateFrom) {
        futureVisits.push(visit);
      } else {
      }
    });

    this.setState( {
      previousVisit: previousVisit,
      nexVisit: nexVisit,
      futureVisits: futureVisits,
      showModal: false,
    });
  }

  openModel = () => {
    this.setState({showModal: true});
  }

  closeModel = () => {
    this.setState({showModal: false});
  }


  render() {
    const {
      previousVisit,
      nexVisit,
      futureVisits,
      showModal,
    } = this.state;
    return (
      <div className="visits-cards">
        <div className="visits-card">
          Poprzednia wizyta:<br />
          {previousVisit ? DateTime.dateTimeStringToDateReverseString(previousVisit.dateTill) : 'Brak' }
        </div>
        <div className="visits-card">
          Następna wizyta:<br />
          {nexVisit ? DateTime.dateTimeStringToDateReverseString(nexVisit.dateTill)  : 'Brak' }
        </div>
        <div className="visits-card">
          <Button onClick={this.openModel}>Zaplanowane wizyty</Button>
        </div>
        <Modal
          size="lg"
          show={showModal}
          onHide={this.closeModel}
        >
          <Modal.Header closeButton>
            <Modal.Title>Zaplanowane wizyty</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EventsTableList visits={futureVisits} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default VisitCards;
