const initState = {
  items: [],
  count: 0,
};

// item: {
//   id:
//   type: success || error
//   title: string
//   message: string
// }

export default (state = initState, action) => {
  switch (action.type) {
    case 'NOTIFICATIONS_PUSH': {
      const newState = { ...state };
      newState.items.push(action.data);
      newState.count = newState.items.length;
      return newState;
    }
    case 'DROP_NOTIFICATION':
      return {
        ...state,
        items: action.notifications,
        count: action.notifications.length,
      };
    default:
      return state;
  }
};
