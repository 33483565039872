import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './style.css';

class SwitchButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: !!this.props.default || false,
      style: { width: 'auto', marginLeft: '5px' },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.default !== !!this.props.default) {
      this.changeDefault(!!nextProps.default);
    }
  }

  changeDefault = (value) => {
    this.setState({
      value,
    });
  }

  displayText = () => (this.state.value === true ? 'Tak' : 'Nie');
  displayStyle = () => (this.state.value === true ? 'success' : 'danger');
  toggle = () => {
    this.setState({
      value: !this.state.value,
    });
    this.props.onChange(!this.state.value);
  }
  render() {
    return (
      <div className="form-group shwich-button">
        <label htmlFor="login-form-login" >{this.props.name}:</label>
        <Button
          className="form-control"
          variant={this.displayStyle()}
          onClick={this.toggle}
          style={this.state.style}
          disabled={this.props.disabled}
        >
          {this.displayText()}
        </Button>
      </div>);
  }
}

export default SwitchButton;
