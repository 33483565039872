import React, { Component } from 'react';
import PatientAction from '../../../../actions/PatientAction';
import { Col, Row } from 'react-bootstrap';
import DropUploader from '../../../../components/FileUploader/DropUploader';
import AttachmentsList from '../../../../components/Patient/AttachmentsList';

class Documents extends Component {
  ATTACHMENT_TYPE = 'patient_documents_scan';
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      attachments: [],
      loadingDocuments: false,
    }
  }

  componentDidMount() {
    this.getDocuments();
  }


  onUploadCompleteHandler = (idList) => {
    this.setState({
      processing: true,
    });
    const { match: { params } } = this.props;
    PatientAction.promiseAddAttachments(params.patientId, this.ATTACHMENT_TYPE, idList)
      .then((response) => {
        this.getDocuments();
        this.setState({
          processing: false,
        });
      });
  }

  getDocuments = () => {
    this.setState({
      loadingDocuments: true,
    });

    const { match: { params } } = this.props;
    PatientAction.promiseGetAttachments(params.patientId, this.ATTACHMENT_TYPE).then((response) => {
      this.setState({
        loadingDocuments: false,
      });
      if (response && response.status === 200) {
        this.setState({
          attachments: response.data,
        });
      }
    }).catch(() => {
      this.setState({
        loadingDocuments: false,
      });
    });
  }

  render() {
    const { processing, attachments } = this.state;
    const { match: { params } } = this.props;
    return (
      <div>
        <Row>
          <Col xs={12}>
            <DropUploader
              onUploadComplete={this.onUploadCompleteHandler}
              handleProcessing={processing}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <AttachmentsList
              attachments={attachments}
              onDeleteAttachment={() => {this.getDocuments()}}
              patientId={params.patientId}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Documents;
