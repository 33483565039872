import React, { Component } from 'react';


class JewDownPart extends Component {
  render() {
    const { width, height } = this.props;
    const style = {
      width,
      height,
    };

    return (
      <div
        className="jew-down-container"
        style={style}
      />
    );
  }
}

export default JewDownPart;
