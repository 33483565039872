import React, { Component } from 'react';
import ListItem from './ListItem';


class ListItems extends Component {
  render() {
    return (<div className="searchDropdown">
      <ul className="options" >
        {this.props.items.map(item => (<ListItem
          item={item}
          key={item.id}
          value={this.props.value}
          name={this.props.name}
          small={this.props.small}
          onClick={this.props.onSelect}
        />))}
      </ul>
    </div>);
  }
}

export default ListItems;
