const initState = {
  isLoading: true,
  actionSelect: '',
  actions: [],
  items: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'GET_ITEMS_SUCCEED': {
      const newState = { ...state };
      newState[action.actionSelect] = action.data;
      newState.isLoading = false;
      newState.actionSelect = action.actionSelect;
      return newState;
    }
    case 'GET_ITEMS_FAILED': {
      return {
        ...state,
        isLoading: false,
        items: [],
      };
    }
    case 'GET_ITEMS_START': {
      const newState = { ...state };
      if (newState.actions.indexOf(action.action) < 0) {
        newState.actions.push(action.action);
      }
      return newState;
    }
    default:
      return state;
  }
};
