export default (collection, openFrom, openTo) => {
  return collection.collection.map((workingDayItem) => {
    const convertDayNumber = workingDayItem.dayNumber === 7
      ? 0
      : workingDayItem.dayNumber;
    return {
      daysOfWeek: [ convertDayNumber ],
      startTime: workingDayItem.workFrom,
      endTime: workingDayItem.workTill,
    }
  });
}
