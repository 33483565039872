import BaseAction from './BaseAction';

class LoginAction extends BaseAction {
  classPrefix = 'Login';

  post = (login, password) => {
    const url = '/login';
    const inputs = {
      username: login,
      password,
    };
    this.request('post', url, inputs);
  };

  logout = () => {
    this.makeDispatch('USER_LOGOUT', []);
    this.makeDispatch('LOGIN_LOGOUT', []);
  }
}

export default new LoginAction();
