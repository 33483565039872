import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const LinkButton = (props) => {
  const {
    onClick,
    bsStyle,
    className,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props;

  const bsStyleClassName = bsStyle ? `btn-${bsStyle}` : '';

  return (
    <Link
      className={`btn ${bsStyleClassName} ${className}`}
      {...rest}
    />
  );
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LinkButton;
