import React, { Component } from 'react';
import { connect } from 'react-redux';
import Menu from '../../components/Menu';
import RegisterForm from '../RegisterForm';
import Notifications from '../Notifications';

class Register extends Component {
  render() {
    const { register } = this.props;

    if (register.success) {
      return (
        <div>
          <Menu />
          <div className="page-container">
            <h1 className="text-center">
              Zarejestruj konto
            </h1>
            <p>
              Dziękujemy za założenie konta w serwisie dentmate.pl
              aby aktywować swoje konto sprawdź swoją pocztę i kliknij w link aktywujący.
            </p>
            <Notifications />
          </div>
        </div>
      );
    }

    return (
      <div>
        <Menu />
        <div className="page-container">
          <h1 className="text-center">
            Zarejestruj konto
          </h1>
          <RegisterForm />
          <Notifications />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: { ...state.user },
  register: state.register,
});


export default connect(mapStateToProps)(Register);
