import React from 'react';
import ToothClass from './ToothClass';

class DoubleBRoot extends ToothClass {
  render() {
    return (
      <g
        id="roots"
        transform="rotate(-180 20, 46)"
      >
        <path
          d="m 35.694943,37.119625 c -0.639969,0.274272 -1.279938,0.45712 -2.011331,0.45712 L 25.18117,37.0282 c -1.737058,-0.09142 -3.382692,-0.182848 -5.119751,-0.09142 v 5.211175 h 0.274273 c 3.931237,0 6.765384,24.958783 4.571205,31.449895 -2.285602,6.948232 0.914241,12.799375 2.285603,11.33659 11.702287,-12.159407 3.382692,-36.752494 7.131081,-44.523543 0.639969,-1.188514 1.005665,-2.194179 1.371362,-3.291268 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(1, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(1),
            strokeWidth: this.getStrokeWith(1),
          }}
        />
        <path
          d="m 15.85591,37.0282 -8.9595629,0.548545 c -1.0056653,0 -1.8284823,-0.274272 -2.6512993,-0.731393 0.3656965,1.188514 0.822817,2.285603 1.4627858,3.474117 3.8398128,7.771049 -4.6626297,32.364136 7.0396564,44.523543 1.279938,1.462785 4.66263,-4.388358 2.377027,-11.33659 -2.194178,-6.399688 0.274273,-31.449895 4.571206,-31.449895 h 0.274272 v -5.211175 c -1.371361,0 -2.742723,0.09142 -4.114085,0.182848 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(2, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(2),
            strokeWidth: this.getStrokeWith(2),
          }}
        />
      </g>
    );
  }
}

export default DoubleBRoot;
