const initState = {
  isLoading: false,
  success: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'REGISTER_POST_ACTION_SUCCEED': {
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    }
    case 'REGISTER_POST_ACTION_FAILED': {
      console.log(action);
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }
    case 'REGISTER_POST_ACTION_START': {
      return {
        ...state,
        isLoading: true,
        success: false,
      };
    }
    default:
      return state;
  }
};
