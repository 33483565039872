import React, { Component } from 'react';
import Menu from '../../components/Menu';
import Notifications from '../Notifications';
import UserAction from '../../actions/UserAction';
import { Alert } from 'react-bootstrap';
import Loader from '../../components/Loader';

class ActivateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accepted: false,
    };

    UserAction.promiseActiveAccount(this.props.match.params.token)
      .then((response) => {
        if (response && response.status === 202) {
          this.setState({
            accepted: true,
          });
        }
      });
  }

  showLoader = () => {
    const { accepted } = this.state;
    if (!accepted) {
      return <Loader />;
    }
    return (
      <Alert variant="success">
        Konto aktywowane
      </Alert>
    );
  }

  render() {
    return (
      <div>
        <Menu />
        <div className="page-container">
          <h1 className="text-center">
            Aktywacja konta
          </h1>
          {this.showLoader()}
          <Notifications />
        </div>
      </div>
    );
  }
}

export default ActivateAccount;
