import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { NavLink, Route, Switch } from 'react-router-dom';
import './ModuleViewWithMenu.css'

class ModuleViewWithMenu extends Component {
  render() {
    const { t, menuItems } = this.props;
    return (
      <div className="layout-module-view-with-menu">
        <div className="menu-side-bar">
          <Nav className="flex-column">
            {menuItems.map((menuItem) => (
              <NavLink
                key={menuItem.label}
                to={menuItem.to}
                className="nav-link"
              >
                {t(menuItem.label)}
              </NavLink>
            ))}
          </Nav>
        </div>
        <div className="content-side container">
          <Switch>
            {menuItems.map((menuItem) => (<Route key={menuItem.label} exac path={menuItem.route} component={menuItem.component} />))}
          </Switch>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default withTranslation()(ModuleViewWithMenu);
