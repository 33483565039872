import thunk from 'redux-thunk';
// import persistState from 'redux-localstorage';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';

const middleware = applyMiddleware(thunk);

class StoreManger {
  static store;

  static getStore = () => {
    if (!StoreManger.store) {
      const enhancer = compose(
        middleware,
        // persistState(),
      );

      StoreManger.store = createStore(reducers, enhancer);

      console.log('utworzono nowy store');
    }

    return StoreManger.store;
  }
}

export default StoreManger;