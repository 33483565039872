import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import LogoNavbar from '../../components/Menu/LogoNavbar';
import Notifications from '../Notifications';
import ScheduleAction from '../../actions/ScheduleAction';
import { Alert, Button, Jumbotron, Card, Row, Col, Container } from 'react-bootstrap';
import Loader from '../../components/Loader';
import ConfirmDialog from '../../components/ConfirmDialog';
import SaveButton from '../../components/SaveButton';
import ConfirmDialogAction from '../../actions/ConfirmDialogAction';
import DateTime from '../../helpers/DateTime';

import './style.css';

class ApproveScheduleEvent extends Component {
  history;
  constructor(props) {
    super(props);

    this.state = {
      scheduleEvent: {},
      scheduleEventIsApproved: false,
      scheduleEventIsCancelled: false,
      isLoading: true,
      accepted: false,
      tokenFail: false,
      approvalLoading: false,
      tokenNotFound: false,
    };

    this.history = createBrowserHistory();

    ScheduleAction.promiseGetScheduleEventByToken(this.props.match.params.token)
      .then((response) => {
        if (response && response.status === 200) {
          if (response.data.id) {
            this.setState({
              scheduleEvent: response.data,
              isLoading: false,
            });
          } else {
            this.setState({
              tokenNotFound: true,
              isLoading: false,
            });
          }
        }
      });
  }
  cancelEvent = (e) => {
    e.stopPropagation();
    ConfirmDialogAction.openDialog('cancel-approval-visit');
  }

  cancelConfirmHandler = () => {
    ConfirmDialogAction.closeDialog();
    const { scheduleEvent } = this.state;
    ScheduleAction.promiseCancelScheduleEventByToken(scheduleEvent.id)
      .then((response) => {
        if (response && response.status === 202) {
          this.setState({
            scheduleEventIsCancelled: true,
          });
          this.history.push('/approve-visit/cancelled');
        }
      });
  }

  approveConfirmHandler = () => {
    this.setState({
      approvalLoading: true,
    });
    const { scheduleEvent } = this.state;
    ScheduleAction.promiseApproveScheduleEventByToken(scheduleEvent.id)
      .then((response) => {
        if (response && response.status === 202) {
          this.setState({
            approvalLoading: false,
            scheduleEventIsApproved: true,
          });
          this.history.push('/approve-visit/approved');
        }
      });
  }

  showLoader = () => {
    const { scheduleEvent, isLoading, tokenNotFound } = this.state;

    if (!isLoading && tokenNotFound) {
      return (
        <Alert variant="danger">
          Link aktywacyjny wygasł
        </Alert>
      );
    }


    if (isLoading && !(scheduleEvent && scheduleEvent.id)) {
      return <Loader />;
    }

    const timeFrom = DateTime.dateTimeStringToTimeString(scheduleEvent.dateFrom, false);
    const dateFrom = DateTime.dateTimeStringToDateReverseString(scheduleEvent.dateFrom);
    const dayName = DateTime.getDayName(scheduleEvent.dateFrom);

    return (
      <div>
        <Col sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 2 }}>
          <strong>Data:</strong> <br />
          {dayName}, {dateFrom} <br /> <br />
          <strong>Godzina:</strong> <br />
          {timeFrom} <br /><br />
          <strong>Gabinet:</strong> <br />
          {scheduleEvent.clinic.name}<br /><br />
          <strong>Lekarz:</strong> <br />
          {scheduleEvent.doctor.name} {scheduleEvent.doctor.lastName}<br />
        </Col>
        {this.showButtons()}
      </div>
    );
  }

  showButtons = () => {
    const { approvalLoading, scheduleEventIsApproved, scheduleEventIsCancelled } = this.state;

    if (scheduleEventIsApproved || scheduleEventIsCancelled) {
      return;
    }

    return (
      <Row className="justify-content-md-center">
        <Col>
          <div className="button-wrapper">
            <SaveButton
              bsStyle="success"
              bsSize="large"
              isLoading={approvalLoading}
              text={"Potwierdź"}
              onSave={this.approveConfirmHandler}
            />
            <Button
              bsStyle="danger"
              onClick={this.cancelEvent}
            >
              Odwołaj
            </Button>
          </div>
        </Col>
        <ConfirmDialog
          name="cancel-approval-visit"
          title="Potwierdzenie anulowania wizyty"
          cancelTextButton="Nie"
          confirmTextButton="Tak"
          onConfirm={this.cancelConfirmHandler}
        >
          Czy na pewno chcesz odwołać tę wizytę?
        </ConfirmDialog>
      </Row>
    )
  };

  showNotifications = () => {
    const { scheduleEventIsApproved, scheduleEventIsCancelled } = this.state;
    if (scheduleEventIsApproved) {
      return (
        <Alert variant="success">
          Wizyta została <strong>potwierdzona</strong>
        </Alert>
      );
    }

    if (scheduleEventIsCancelled) {
      return (
        <Alert variant="success">
          Wizyta została <strong>anulowana</strong>
        </Alert>
      );
    }

    return <h5>Czy chcesz potwierdzić wizytę?</h5>;
  }

  render() {
    return (
      <div>
        <LogoNavbar />
        <Container>
          <Jumbotron className="confirm-visit">
            {this.showNotifications()}
            {this.showLoader()}
          </Jumbotron>
          <Notifications />
        </Container>
      </div>
    );
  }
}

export default ApproveScheduleEvent;
