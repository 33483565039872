import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './style.css';


class InputMessages extends Component {
  showErrors = messages => (messages.map((message) => {
    const { t } = this.props;
    if (message[1] === false) {
      return false;
    }
    return (<li key={Math.random()} >{t(message[1])}</li>);
  }));

  prepareMessages = () => {
    const { messages } = this.props;

    if (!messages) {
      return false;
    }

    const messagesList = Object.entries(messages).filter((message) => {
      if (message[1]) {
        return message[1];
      }
      return false;
    });
    return messagesList;
  }

  render() {
    const messages = this.prepareMessages();
    if (!(messages && messages.length)) {
      return false;
    }
    return (
      <ul className="help-block-after-input">
        {this.showErrors(messages)}
      </ul>
    );
  }
}

export default withTranslation()(InputMessages);
