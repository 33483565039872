

export default class UserModel {
    id;

    email;

    name;

    lastName;

    phone;

    type;
}
