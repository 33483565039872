import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';


class UserCardMenu extends Component {

  render() {
    return (
      <div className="patient-navbar-wrapper">
        <Navbar className="patient-navbar left">
          {/*<NavLink
            to={`/panel/profile/me/plan`}
            className="nav-link"
            disable={true}
          >
            Plan
          </NavLink>*/}
          <NavLink
            to={`/panel/profile/me/settings`}
            className="nav-link"
          >
            Ustawienia
          </NavLink>
          <NavLink
            to={`/panel/profile/me/reports`}
            className="nav-link"
          >
            Raporty
          </NavLink>
        </Navbar>
      </div>
    );
  }
}

export default UserCardMenu;
