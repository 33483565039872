import CheckValidation from '../helpers/CheckValidation';

const initState = {
  forms: {},
};


export default (state = initState, action) => {
  switch (action.type) {
    case 'FORMS_REGISTER_FORM': {
      const newState = { ...state };
      const newFormName = action.data.formName;
      newState.forms[newFormName] = {};
      newState.forms[newFormName].fields = action.data.formFields;
      newState.forms[newFormName].isValid = false;
      return newState;
    }
    case 'FORMS_ADD_VALIDATION_MESSAGES_TO_FILED': {
      const newState = { ...state };
      const { formName, filedName, messages } = action.data;
      const newMessages = [...newState.forms[formName].fields[filedName].validationMessages, ...messages];
      newState.forms[formName].fields[filedName].validationMessages = newMessages;
      return newState;
    }
    case 'FORMS_RESET_FORM_VALUES' : {
      const newState = { ...state };
      const { formName } = action.data;
      const newForm =  { ...newState.forms[formName] }
      newForm.isValid =  false;
      Object.keys(newForm.fields).forEach((key) => {
        newForm.fields[key].value = '';
        newForm.fields[key].validationMessages = [];
      });

      newState.forms[formName] = newForm;
      return newState;
    }
    case 'FORMS_UPDATE_INPUT': {
      const newState = { ...state };
      const { formName, inputName, propName } = action.data;
      const oldInput = { ...newState.forms[formName].fields[inputName] };

      oldInput[propName] = action.data.value;

      const checkValidation = new CheckValidation();
      newState.forms[formName].fields[inputName] = oldInput;

      if (oldInput.reValid) {
        newState.forms[formName].fields = checkValidation.reValidForm(newState.forms[formName]);
      } else {
        newState.forms[formName].fields = checkValidation.checkValidation(
          newState.forms[formName].fields,
          inputName,
          oldInput.value,
        );
      }

      newState.forms[formName].isValid = checkValidation.formIsValid(newState.forms[formName]);
      return newState;
    }
    case 'FORMS_UPDATE_ATTRIBUTE': {
      const newState = { ...state };
      const {
        formName,
        inputName,
        attribute,
        value,
      } = action.data;

      const oldInput = { ...newState.forms[formName].fields[inputName] };

      if (!oldInput.attributes) {
        oldInput.attributes = {};
      }

      const attributes = { ...oldInput.attributes };
      attributes[attribute] = value;

      oldInput.attributes = attributes;

      newState.forms[formName].fields[inputName] = oldInput;

      return newState;
    }
    case 'FORMS_CHECK_VALIDATION': {
      const newState = { ...state };
      const { formName } = action.data;

      const checkValidation = new CheckValidation();

      newState.forms[formName].fields = checkValidation.reValidForm(newState.forms[formName]);
      newState.forms[formName].isValid = checkValidation.formIsValid(newState.forms[formName]);

      return newState;
    }
    default:
      return state;
  }
};
