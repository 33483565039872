import BaseAction from './BaseAction';


class CalendarAction extends BaseAction {
  classPrefix = 'CALENDAR';

  openEventPopup = () => {
    const actionName = `${this.classPrefix}_OPEN_EVENT_POPUP`;
    this.makeDispatch(actionName, []);
  };

  closeEventPopup = () => {
    const actionName = `${this.classPrefix}_CLOSE_EVENT_POPUP`;
    this.makeDispatch(actionName, []);
  };

  changeSettingsProperty = (settingName, newValue) => {
    const actionName = `${this.classPrefix}_CHANGE_SETTINGS_PROPERTY`;
    this.makeDispatch(actionName, {
      settingName,
      newValue
    });
  };

  getCalendarSettingsAndUpdateState = (userId, clinicId) => {
    this.promiseGetCalendarSettings(userId, clinicId)
      .then((response) => {
        if(response && response.status === 200) {
          Object.keys(response.data).forEach((key) => {
            this.changeSettingsProperty(key, response.data[key]);
          });
        }
      });
  }

  promiseGetCalendarSettings = (userId, clinicId) => {
    const url = `/api/calendar/settings/${userId}/${clinicId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseGetNearestFreeTermin = (userId, clinicId) => {
    const url = `/api/calendar/get-nearest-free-termin/${userId}/${clinicId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseSaveCalendarSettings = (userId, clinicId, settings) => {
    const url = `/api/calendar/settings/${userId}/${clinicId}`;

    const formSettings = {
      clinicId: {
        id: clinicId,
      },
      ...settings,
    }

    return this.promiseDoRequest(BaseAction.METHOD_POST, url, formSettings);
  }
}

export default new CalendarAction();
