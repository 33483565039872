import React, { Component } from 'react';
import BootstrapPagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

class Pagination extends Component {

  pagesSizes = [5, 10, 15, 20, 30, 50, 100];

  onPageChangeHandler = (page) => {
    this.props.onPageChange(page);
  }

  onPageSizeChangeHandler = (pageSize) => {
    this.props.onPageSizeChange(pageSize);
  }

  render() {
    const {currentPage, pageSize, totalRows} = this.props;
    const totalPages = Math.ceil(totalRows / pageSize);

    const items = [];
    for (let number = 0; number < totalPages; number++) {
      items.push(
        <BootstrapPagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => this.onPageChangeHandler(number)}
        >
          {number+1}
        </BootstrapPagination.Item>,
      );
    }

    return (
      <div className="pagination">
        <BootstrapPagination>
          {items}
        </BootstrapPagination>
        <DropdownButton title="Liczba wyników na stronie"
          onChange={this.onPageSizeChangeHandler}
        >
          {this.pagesSizes.map((value) => (
            <Dropdown.Item
              key={value}
              onClick={() => this.onPageSizeChangeHandler(value)}
              active={value === pageSize}
            >
              {value}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    );
  }
}

export default Pagination;
