import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import './style.css';

class NotificationItem extends Component {
  prepareMessage = (message) => {
    console.log('ni', message, typeof message);
    console.log('bzung', this.props.item);
    const { t } = this.props;
    switch (typeof message) {
      case 'string':
        return t(message);
      case 'object':
        return this.objMmessages(message);
      default:
        return '';
    }
  }

  objMmessages = message => (
    <div key={Date.now()} >
      { Object.keys(message).map(item => this.prepareMessage(message[item])) }
    </div>
  );


  render() {
    return (<div className="notifications-item">
      <Alert variant={this.props.item.type} >
        <strong>{this.props.item.title}</strong> {this.prepareMessage(this.props.item.message)}
      </Alert>
    </div>);
  }
}

export default withTranslation()(NotificationItem);
