import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Checkbox from '../Inputs/Checkbox';

class TableHeader extends Component {
  onSelectAllHandler = (isChecked) => {
    this.props.onSelect(isChecked);
  }
  onChangeOrderHandler = (item, dir) => {
    item.orderCallback(item.property, dir);
  }
  selectAllCell = () => {
    if (this.props.selectAll) {
      return (<th style={this.checkboxStyle} >
        <Checkbox onChange={this.onSelectAllHandler} />
      </th>);
    }
    return null;
  }
  emptyCellIfButtons = () => {
    if (this.props.hasButtons) {
      return (<th style={this.checkboxStyle} />);
    }
    return null;
  }

  showCell = item => (<th style={item.style} key={item.property ? item.property : Math.random()}>
    {item.text}
    {item.orderCallback && item.property ? this.showOrderButtons(item) : null}
  </th>);

  showOrderButtons = item => (<span>
    <Button variant="link" onClick={() => { this.onChangeOrderHandler(item, 'ASC'); }} >
      <span className="glyphicon glyphicon-chevron-up" />
    </Button>
    <Button variant="link" onClick={() => { this.onChangeOrderHandler(item, 'DESC'); }}>
      <span className="glyphicon glyphicon-chevron-down" />
    </Button>
  </span>);

  checkboxStyle = {
    width: '20px',
    verticalAlign: 'middle',
  };


  render() {
    return (<tr className="black-muted-bg">
      {this.selectAllCell()}
      {this.props.header.map(item => this.showCell(item))}
      {this.emptyCellIfButtons()}
    </tr>);
  }
}
export default TableHeader;
