import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './style.css';


class DButton extends Component {
  render() {
    return (
      <Button
        {...this.props}
      >
        {this.props.children}
      </Button>
    );
  }


}

export default DButton;
