import React, { Component } from 'react';
import { ButtonToolbar, ButtonGroup, Button } from 'react-bootstrap';
import SmartSearchInput from '../SmartSearchInput';
import './teethRecognition.css';
import Loader from '../Loader';
import ElementOverallNotInteractive from '../Overall/ElementOverallNotInteractive';
import IconButton from '../Button/IconButton';
import config from '../../config';
import DButton from '../Button/DButton';


class TeethRecognition extends Component {
  static ACTION_RECOGNITION = 'recognitionList';
  static ACTION_PROCEDURE = 'proceduresList';

  constructor(props) {
    super(props);

    this.state = {
      searchWord: '',
      actionSelected: TeethRecognition.ACTION_RECOGNITION,
    };
  }

  onSearchHandler = (value) => {
    this.setState({
      searchWord: value,
    });
  };

  onChangeActionHandler = (actionName) => {
    this.setState({
      actionSelected: actionName,
    });
  }

  showActionList = () => {
    const { searchWord, actionSelected } = this.state;
    const actionList = [ ...this.props[actionSelected]];

    if (!(actionList && actionList.length)) {
      return <Loader />;
    }

    const newList = actionList.filter(item => item.name.match(new RegExp(searchWord,'gi')));

    return (
      <ul className="recognition-box-list">
        {newList.map(item => this.recognitionListItem(item))}
      </ul>
    );
  };

  recognitionOnClickHandler = (item) => {
    const { onSelectRecognition, onSelectProcedure } = this.props;
    const { actionSelected } = this.state;
    if (actionSelected === TeethRecognition.ACTION_RECOGNITION) {
      onSelectRecognition(item);
    } else {
      onSelectProcedure(item);
    }
  };

  itemIsSelected = (item, action) => {
    const { selectedTeeth } = this.props;

    return selectedTeeth.some((tooth) => {
      const toothParts = Object.values(tooth.parts);

      return toothParts.some((toothPart) => {
        if (action === TeethRecognition.ACTION_PROCEDURE) {
          if (toothPart.procedure && toothPart.procedure.procedureId === item.id) {
            return true;
          }
        } else {
          if (toothPart.recognition && toothPart.recognition.recognitionId === item.id) {
            return true;
          }
        }
      });
    });
  }

  onRemoveHandler = (item, actionSelected) => {
    const { onRemoveRecognition, onRemoveProcedure } = this.props;
    if (actionSelected === TeethRecognition.ACTION_PROCEDURE) {
      onRemoveProcedure(item);
    } else {
      onRemoveRecognition(item);
    }
  }

  showRemoveElement = (show, item, actionSelected) => {
    if (show) {
      return (
        <div className="remove-element">
          <IconButton
            onClick={() => this.onRemoveHandler(item, actionSelected)}
            size="small"
          >
            <i className="fas fa-minus-square" />
          </IconButton>
        </div>
      );
    }
    return null;
  }

  /**
   * @var item - recognition|procedure db item
   */
  recognitionListItem = (item) => {
    const { actionSelected } = this.state;
    const itemIsSelected = this.itemIsSelected(item, actionSelected);


    const style = {
     backgroundColor: item.color,
    }

    if (actionSelected === TeethRecognition.ACTION_PROCEDURE) {
      style.backgroundImage = `url(${config.api}/${config.procedureApiImageDirectoryPath}/${item.id}.png)`;
    }

    return (
      <li
        key={`${actionSelected}-${item.id}`}
        onClick={() => this.recognitionOnClickHandler(item)}
        className={itemIsSelected ? 'selected' : ''}
      >
        <div className="recognition-list-item-div">
          <div className="background-element">
            <div style={style} />
          </div>
          <div className="name-element">
            {item.name}
          </div>
          {this.showRemoveElement(itemIsSelected, item, actionSelected)}
        </div>
      </li>
    );
  }

  render() {
    const { toothIsSelected } = this.props;
    const { searchWord, actionSelected } = this.state;
    return (
      <div className="teeth-recognition-main">
        <ButtonToolbar>
          <ButtonGroup>
            <DButton
              variant={actionSelected === TeethRecognition.ACTION_RECOGNITION ? 'success' : 'primary'}
              onClick={() => this.onChangeActionHandler(TeethRecognition.ACTION_RECOGNITION)}
            >
              Rozpoznania
            </DButton>
            <DButton
              variant={actionSelected === TeethRecognition.ACTION_PROCEDURE  ? 'success' : 'primary'}
              onClick={() => this.onChangeActionHandler(TeethRecognition.ACTION_PROCEDURE)}
            >
              Procedury
            </DButton>
          </ButtonGroup>
        </ButtonToolbar>
        <div className="search-box">
          <div className="search-box-input">
            <SmartSearchInput
              onSearch={this.onSearchHandler}
              defaultValue={searchWord}
            />
          </div>
        </div>
        <div className="recognition-box">
          {toothIsSelected || <ElementOverallNotInteractive buttonText="Wybierz ząb z diagramu" />}
          {this.showActionList()}
        </div>
      </div>
    );
  }
}


export default TeethRecognition;
