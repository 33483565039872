import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';


class PatientCardMenu extends Component {
  render() {
    const { clinic } = this.props;
    return (
      <div className="patient-navbar-wrapper">
        <Navbar className="patient-navbar left">
          <NavLink
            to={`/panel/clinics/${clinic.id}/detail`}
            className="nav-link"
          >
            Dane Gabinetu
          </NavLink>
          <NavLink
            to={`/panel/clinics/${clinic.id}/users`}
            className="nav-link"
          >
            Użytkownicy
          </NavLink>
        </Navbar>
      </div>
    );
  }
}

export default PatientCardMenu;
