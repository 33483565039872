import React, { Component } from 'react';


class TimelineItem extends Component {

  onClickHandler = (object) => {
    const {onClick} = this.props;

    if (onClick && onClick instanceof Function) {
      onClick(object);
    }
  }

  render() {
    const { year, label, className} = this.props;
    return (
      <div
        className={`timeline-step ${className}`}
      >
        <div
          className="timeline-content"
        >
          <div
            className="inner-circle"
            onClick={() => this.onClickHandler({year, label})}
            role="button"
          />
          <p onClick={() => this.onClickHandler({year, label})} role="button" className="h6 mt-3 mb-1">{year}</p>
          {label ? <p onClick={() => this.onClickHandler({year, label})} role="button" className="h6 text-muted mb-0 mb-lg-0">{label}</p> : '' }
        </div>
      </div>
    );
  }
}

export default TimelineItem;
