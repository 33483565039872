import axios from 'axios';
import store from './store';


const pushNotification = (title, message, type) => {
  const item = {
    id: Date.now(),
    type,
    title,
    message,
  };
  store.getStore().dispatch({ type: 'NOTIFICATIONS_PUSH', data: item });
};

const pushNotificationValidation = (title, message, type) => {
  const item = {
    id: Date.now(),
    type,
    title,
    message: message.errors,
  };
  store.getStore().dispatch({ type: 'NOTIFICATIONS_PUSH', data: item });
};

const errors = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 405:
        pushNotification('Niedozwolona operacja', '', 'warning');
        return err.response;
      case 417:

        const messages = {
          errors: [],
        };
        if (err.response.data.length) {
          err.response.data.forEach((errorItem) => {
            errorItem.forEach((errorMessage) => {
              messages.errors.push(errorMessage.message);
            });
          });
        }

        console.log('bs', err.response.data);
        console.log('meessages', messages);
        pushNotificationValidation('Błąd Walidacji', messages, 'warning');
        return err.response;
      case 406:
      case 409:
      case 422:
        pushNotificationValidation('Błąd Walidacji', err.response.data, 'warning');
        return err.response;
      case 500:
        pushNotification('Błąd serwera', 'Jeśli problem się powtarza zgłoś to', 'danger');
        return err.response;
      default:
      //
    }
  } else {
    console.log(err);
    return err.response;
  }
  // Promise.reject(err);
};

const successes = (res) => {
  switch (res.status) {
    case 201:
    case 202:
      pushNotification('Zapisano zmiany', '', 'success');
      break;
    default:
      //
  }

  return res;
};

const successRequest = (requestConfig) => {
  switch (requestConfig.method) {
    case 'delete':
      // return false;
    default:
      return requestConfig;
  }
};

const errorRequest = (error) => {
  return error;
};

axios.interceptors.request.use(successRequest, errorRequest);
axios.interceptors.response.use(successes, errors);
