import { NavLink } from 'react-router-dom';
import React, { Component } from 'react';


class DisabledLink extends Component {
  render() {
    const { disable, children, className } = this.props;
    if (disable) {
      return (
        <span
          {...this.props}
          className={`disabled ${className}`}
        >
          {children}
        </span>
      );
    }

    return (
      <NavLink
        {...this.props}
      >
        {children}
      </NavLink>
    );
  }
}

export default DisabledLink;
