import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Register from './containers/Register';
import RegisterOnlyUser from './containers/Register/RegisterOnlyUser';
import AcceptInvite from './containers/Register/AcceptInvite';
import ActivateAccount from './containers/Register/ActivateAccount';
import ApproveScheduleEvent from './containers/Schedule/ApproveScheduleEvent';
import Login from './containers/Login';
import ForgotPassword from './containers/Login/ForgotPassword';
import ResetPassword from './containers/Login/ResetPassword';
import MainPage from './containers/MainPage';
import JwtStorage from './helpers/JwtStorage';
import store from './store';
import RefreshTokenAction from './actions/RefreshTokenAction';
import LandingPage from './components/LandingPage';


if (JwtStorage.getJwt() !== false) {
  store.getStore().dispatch({
    type: 'LOGIN_LOGIN',
  });
}

const Routes = () => (
  <Provider store={store.getStore()}>
    <BrowserRouter>
      <Switch>
        <Route exac path="/login" component={Login} />
        <Route exac path="/forgot-password/token/:token" component={ResetPassword} />
        <Route exac path="/forgot-password" component={ForgotPassword} />
        <Route exac path="/active-account/token/:token" component={ActivateAccount} />
        <Route exac path="/accept-invite/token/:token" component={AcceptInvite} />
        <Route exac path="/approve-visit/token/:token" component={ApproveScheduleEvent} />
        <Route exac path="/register/token/:token" component={RegisterOnlyUser} />
        <Route exac path="/register" component={Register} />
        <Route exac path="/panel" component={MainPage} />
        <Route exac path="/" component={LandingPage} />
      </Switch>
    </BrowserRouter>
  </Provider>
);

const interval = setInterval(() => {
  if (JwtStorage.getJwt() === false) {
    store.getStore().dispatch({
      type: 'LOGIN_LOGOUT',
    });
    clearInterval(interval);
  }
}, 3600 * 1000);

setInterval(() => {
  RefreshTokenAction.refresh();
}, 1500 * 1000);

export default Routes;
