import React, { Component } from 'react';
import { Navbar, Nav, Button, Form } from 'react-bootstrap';
import Logo from '../Logo';
import './style.css';


class Menu extends Component {
  render() {
    return (
      <Navbar className='justify-content-between' expand="sm" fixed="top" style={{ background: "var(--dark-blue)" }}>
        <Navbar.Brand href="/">
          < Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <Nav.Link href="#home">Oferta</Nav.Link>
            <Nav.Link href="#link">Cennik</Nav.Link>
            <Nav.Link></Nav.Link>
          </Nav>
          <Form inline className="mr-md-2">
            <Button className="login-btn" variant="outline-light" href="/login" size="sm">Zaloguj się</Button>
            <Button variant="success" href="/register" size="sm">Zarejestruj się</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar >
    );
  }
}

export default Menu;
