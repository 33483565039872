import React, { Component } from 'react';
import pl from 'react-phone-number-input/locale/pl.json';
import PhoneInput from 'react-phone-number-input/mobile';
import 'react-phone-number-input/style.css';
import { Form } from 'react-bootstrap';
import customList from '../../helpers/PhoneNumber/CustomLabels/customCountryList.json';
import InputMessages from '../../containers/FormWizard/InputMessages';

class PhoneNumber extends Component {
  constructor(props) {
    super(props);

    const { inputElement } = this.props;

    this.state = {
      inputValue: inputElement.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { inputElement } = this.props;

    if (nextProps.inputElement.value !== inputElement.value) {
      this.setState({ inputValue: nextProps.inputElement.value });
    }
  }

  onChangeHandler = (value) => {
    this.props.onChange(value);

    this.setState({ inputValue: value });
  };

  render() {
    const { inputElement, disabled, maxlength, className } = this.props;
    const { validationMessages, attributes, label } = inputElement;

    const { inputValue } = this.state;

    const buttonIsDisabled = disabled || (attributes && attributes.disabled);

    return (
      <Form.Group disabled={buttonIsDisabled}>
        {label && <Form.Label>{label}:</Form.Label>}
        <PhoneInput
          labels={pl}
          countries={customList}
          inputComponent={Form.Control}
          defaultCountry="PL"
          placeholder={inputElement.placeholder || ''}
          value={inputValue}
          onChange={this.onChangeHandler}
          disabled={buttonIsDisabled}
          international
          countryCallingCodeEditable={false}
        />
        <InputMessages messages={validationMessages} />
      </Form.Group>
    );
  }
}

export default PhoneNumber;
