const initState = {
  events: [],
  isLoading: false,
  success: false,
  isAdding: false,
  shouldBeReload: false,
  dragAndDropAction: {
    eventId: 0,
    direction: '',
  }
};

const convertDates = (data) => {
  const newData = [];

  data.forEach((item) => {
    const newItem = convertDate(item);
    newData.push(newItem);
  });

  return newData;
};


const convertDate = (item) => {
  const newItem = { ...item };
  newItem.start = new Date(item.dateFrom);
  newItem.end = new Date(item.dateTill);
  newItem.title = `${item.name} ${item.lastName} - ${item.description || ''}`;
  return newItem;
}

export default (state = initState, action) => {
  switch (action.type) {
    case 'SCHEDULE_GET_LIST_BY_USER_START':
    case 'SCHEDULE_GET_LIST_BY_CLINIC_START':
    case 'SCHEDULE_GET_LIST_START': {
      const newState = { ...state };
      newState.isLoading = true;
      return newState;
    }
    case 'SCHEDULE_GET_LIST_BY_USER_FAILED':
    case 'SCHEDULE_GET_LIST_BY_CLINIC_FAILED':
    case 'SCHEDULE_GET_LIST_FAILED': {
      const newState = { ...state };
      newState.isLoading = false;
      newState.shouldBeReload = false;
      return newState;
    }
    case 'SCHEDULE_GET_LIST_BY_USER_SUCCEED':
    case 'SCHEDULE_GET_LIST_BY_CLINIC_SUCCEED':
    case 'SCHEDULE_GET_LIST_SUCCEED':
    case 'SCHEDULE_REFRESH_LAST_GET_LIST_SUCCEED':
    case 'SCHEDULE_REFRESH_LAST_GET_LIST_FOR_NEW_USER_SUCCEED': {
      const newState = { ...state };
      newState.isLoading = false;
      newState.shouldBeReload = false;
      newState.events = convertDates(action.data);
      return newState;
    }
    case 'SCHEDULE_POST_ACTION_START': {
      const newState = { ...state };
      newState.isAdding = true;
      return newState;
    }
    case 'SCHEDULE_POST_ACTION_SUCCEED': {
      const newState = { ...state };
      newState.isAdding = false;
      newState.shouldBeReload = true;
      return newState;
    }
    case 'SCHEDULE_POST_ACTION_FAILED': {
      const newState = { ...state };
      newState.isAdding = false;
      return newState;
    }
    case 'SCHEDULE_SET_DND': {
      const newState = { ...state };
      newState.dragAndDropAction = {
        eventId: action.data.eventId,
        event: action.data.event,
        direction: action.data.direction,
      };
      return newState;
    }
    case 'SCHEDULE_UPDATE_EVENT': {
      const newState = { ...state };
      const newEvent = { ...action.data.event };
      newState.events = newState.events.map((event) => {
        if (event.id === newEvent.id) {
          return convertDate(newEvent);
        }
        return event;
      });
      return newState;
    }
    default:
      return state;
  }
};
