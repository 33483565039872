import Event from '../../Model/Calendar/Event';
import DateTime from '../DateTime';

export default (currentClinic, events) => {
  const clusterEventsObject = {};

  events.forEach((event) => {
    if (event.idClinic === currentClinic.id && event.status === 'active') {
      const dateString = DateTime.dateTimeStringToDateString(event.dateFrom);
      clusterEventsObject[dateString] = 1 + (clusterEventsObject[dateString] || 0);
    }
  });
  const clusterEventsArray = [];

  Object.keys(clusterEventsObject).forEach((dateString) => {
    const eventModel = new Event(
      DateTime.setTimeOnDateFromTimeString(new Date(dateString), '00:00:00'),
      DateTime.setTimeOnDateFromTimeString(new Date(dateString), '23:59:59'),
      `month-view-cluster-${dateString}`,
      currentClinic.id,
      '',
      '',
      '',
      'month-view-event',
      clusterEventsObject[dateString],
      'month-view-cluster',
      false,

    );

    clusterEventsArray.push(eventModel.getModel());
  });

  return clusterEventsArray;
};
