import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, Col } from 'react-bootstrap';
import Logo from '../Logo';
import MenuUser from '../../containers/MenuUser';
import MenuAuthorization from '../../helpers/Menu/MenuAuthorization';
import { FaCalendarAlt, FaUsers, FaClinicMedical } from 'react-icons/fa';
import './style.css';
import Notification from '../../containers/Notification';


class PanelMenu extends Component {
  showClinicsTab = () => {
    const { user } = this.props;
    if (MenuAuthorization.canShowClinicsTab(user)) {
      return (
        <NavLink to="/panel/clinics" className="nav-link">
          < FaClinicMedical />
          Gabinety
        </NavLink>
      );
    }
    return null;
  }

  render() {
    return (
      <Navbar expand="sm" style={{ background: "var(--dark-blue)" }}>
        <Col xs={4} md={3}>
          <Navbar.Brand href="/panel/calendar">
            < Logo />
          </Navbar.Brand>
        </Col>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />


        <Col xs={3} md={6}>
          <div className="center-nav">
            <Navbar.Collapse>
              <Nav>
                <NavLink to="/panel/calendar" className="nav-link">
                  <FaCalendarAlt />
                  Kalendarz
                </NavLink>
                <NavLink to="/panel/patients" className="nav-link">
                    <FaUsers />
                    Pacjenci
                </NavLink>

                {this.showClinicsTab()}
              </Nav>
            </Navbar.Collapse >
          </div>
        </Col>
        <Col xs={3} md={3}>
          <div className="user-nav">
            <Notification />
            <MenuUser />
          </div>
        </Col>
      </Navbar >
    );
  }
}

export default PanelMenu;
