import BaseAction from './BaseAction';


class UserWorkingDayAction extends BaseAction {
  classPrefix = 'UserWorkingDay';

  openModal = () => {
    this.makeDispatch('USER_WORKING_DAY_OPEN_MODAL');
  };

  closeModal = () => {
    this.makeDispatch('USER_WORKING_DAY_CLOSE_MODAL');
  };


}

export default new UserWorkingDayAction();
