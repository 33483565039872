import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import UsersView from '../../components/Clinic/UsersView';
import UserEnum from '../../helpers/Enum/UserEnum';
import ClinicAction from '../../actions/ClinicAction';
import UserAction from '../../actions/UserAction';
import ConfirmDialogAction from '../../actions/ConfirmDialogAction';

class ClinicUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: [],
      workers: [],
      doctorsInvitations: [],
      workersInvitations: [],
    };

    this.fetchUsers();
    this.fetchInvitations();
  }

  onDeleteAction = (userId) => {
    const { userClinics } = this.props;
    UserAction.promiseDeleteUserFormClinic(userId, userClinics.currentClinic.id)
      .then((response) => {
        if (response && response.status === 204) {
          this.fetchUsers();
        }
        ConfirmDialogAction.closeDialog();
      });
  };

  onSendInvitation = (email, clinic, type) => {
    const promise = UserAction.promiseInviteUser(
      email,
      clinic,
      type,
    );
    promise.then(() => {
      this.fetchInvitations();
    });

    return promise;
  }

  onResetInvitation = (invitation) => {
    UserAction.promiseInviteUser(
      invitation.email,
      invitation.clinic.id,
      invitation.userType,
    ).then((response) => {
      if (response && response.status === 201) {
        this.fetchInvitations();
      }
    });
  }

  onCancelInvitation = (invitationId) => {
    UserAction.promiseCancelInvitation(invitationId)
      .then(() => {
        ConfirmDialogAction.closeDialog();
        this.fetchInvitations();
      });
  }

  fetchUsers = () => {
    const { userClinics } = this.props;
    ClinicAction.promiseGetUsersOfClinic(userClinics.currentClinic.id)
      .then((response) => {
        if (response && response.status === 200) {
          const workers = [];
          const doctors = [];
          response.data.forEach((item) => {
            if (item.userFunction === UserEnum.TYPE_DENTIST) {
              doctors.push(item);
            } else {
              workers.push(item);
            }
          });
          this.setState({
            workers,
            doctors,
          });
        }
      });
  }

  fetchInvitations = () => {
    const { userClinics } = this.props;
    UserAction.promiseGetInvitationsForClinic(userClinics.currentClinic.id)
      .then((data) => {
        const workersInvitations = [];
        const doctorsInvitations = [];

        if (data && data.data) {
          data.data.forEach((item) => {
            if (item.userType === UserEnum.TYPE_DENTIST) {
              doctorsInvitations.push(item);
            } else {
              workersInvitations.push(item);
            }
          });
        }
        this.setState({
          workersInvitations,
          doctorsInvitations,
        });
      });
  }


  render() {
    const {
      doctors,
      workers,
      doctorsInvitations,
      workersInvitations,
    } = this.state;
    const { userClinics } = this.props;
    return (
      <Row>
        <Col sm={12}>
          <UsersView
            type={UserEnum.TYPE_DENTIST}
            maxAccounts={5}
            clinic={userClinics.currentClinic}
            users={doctors}
            invitations={doctorsInvitations}
            onDelete={this.onDeleteAction}
            onSendInvitation={this.onSendInvitation}
            onResendInvitation={this.onResetInvitation}
            onCancelInvitation={this.onCancelInvitation}
          />
          <UsersView
            type={UserEnum.TYPE_OFFICE_WORKER}
            maxAccounts={6}
            clinic={userClinics.currentClinic}
            users={workers}
            invitations={workersInvitations}
            onDelete={this.onDeleteAction}
            onSendInvitation={this.onSendInvitation}
            onResendInvitation={this.onResetInvitation}
            onCancelInvitation={this.onCancelInvitation}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  userClinics: state.userClinics,
});

export default connect(mapStateToProps)(ClinicUsers);
