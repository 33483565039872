

class TeethUpdateManager {

  constructor(teeth) {
    this.teeth = teeth;
  }

  updateToothRecognition = (toothNumber, toothPartNumber, recognition) => {
    const tooth = this._getTooth(toothNumber);
    const toothPart = this._getPartNumber(tooth, toothPartNumber);
    tooth.updated = true;
    toothPart.recognition = recognition;
    toothPart.recognition.recognitionId = recognition.id;
    toothPart.partNumber = toothPartNumber;
    toothPart.updated = true;

    return { ...this.teeth };
  }

  updateToothProcedure = (toothNumber, toothPartNumber, procedure) => {
    const tooth = this._getTooth(toothNumber);
    const toothPart = this._getPartNumber(tooth, toothPartNumber);
    tooth.updated = true;
    toothPart.procedure = procedure;
    toothPart.procedure.procedureId = procedure.id;
    toothPart.updated = true;

    return { ...this.teeth };
  }

  deleteRecognitionFromToothPart = (toothNumber, toothPartNumber, recognition) => {
    const tooth = this._getTooth(toothNumber);
    const toothPart = this._getPartNumber(tooth, toothPartNumber);

    if (toothPart.recognition && toothPart.recognition.recognitionId === recognition.id) {
      toothPart.recognition = {};
      toothPart.updated = true;
      tooth.updated = true;
    }

    return { ...this.teeth };
  }

  deleteProcedureFromToothPart = (toothNumber, toothPartNumber, procedure) => {
    const tooth = this._getTooth(toothNumber);
    const toothPart = this._getPartNumber(tooth, toothPartNumber);

    if (toothPart.procedure && toothPart.procedure.procedureId === procedure.id) {
      toothPart.procedure = {};
      toothPart.updated = true;
      tooth.updated = true;
    }

    return { ...this.teeth };
  }


  _getTooth = (tootNumber) => {
    if (!this.teeth[tootNumber]) {
      this.teeth[tootNumber] = {
        parts: {},
        updated: true,
        tootRecognition: {
          description: '',
        },
        toothNumber: tootNumber,
      };
    } else if (this.teeth[tootNumber].parts === undefined) {
      this.teeth[tootNumber].parts = {}
    }

    return this.teeth[tootNumber];
  }

  _getPartNumber = (tooth, toothPartNumber) => {
    if (tooth.parts === undefined) {
      tooth.parts = {}
    }

    if (!tooth.parts[toothPartNumber]) {
      tooth.parts[toothPartNumber] = {
        recognition: {},
        procedure: {},
        partNumber: toothPartNumber,
      };
    }

    return tooth.parts[toothPartNumber];
  }
}

export default TeethUpdateManager;
