import React, { Component } from 'react';
import ModuleViewWithMenu from '../../../layout/ModuleViewWithMenu';
import Notification from './Notification/Notification';

class SettingMainPage extends Component {

  tabs = [
    {
      label: 'notifications',
      to: `/panel/profile/me/settings/notifications`,
      route: `/panel/profile/me/settings/notifications`,
      component: Notification,
    }/*,
    {
      label: 'change_password',
      to: `/panel/profile/me/settings/change-password`,
      route: `/panel/profile/me/settings/change-password`,
      component: Documents
    },*/
  ];

  render() {
    const { match: { params } } = this.props;
    return (
      <ModuleViewWithMenu
        menuItems={this.tabs}
      />
    );
  }
}

export default SettingMainPage;
