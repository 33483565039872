import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import LoginAction from '../../actions/LoginAction';
import { FaUserAlt } from 'react-icons/fa';
import './style.css';
import { NavLink } from 'react-router-dom';


class MenuUser extends Component {
  logOut = () => {
    LoginAction.logout();
  };


  initials =(user) => {
    if (!user) {
      return '';
    }

    return `${user.name.charAt(0)
      .toUpperCase()}${user.lastName.charAt(0)
      .toUpperCase()}`
  }

  render() {
    const {user} = this.props;
    return (
      <div className="menu-user">
        <div className="profile-image">
          <span className="initials">
            {this.initials(user)}
          </span>
          <DropdownButton
            title={
              < FaUserAlt />
            }
            menuAlign="right"
            key={1}
            id="dropdown-user-menu"
          >
            {/*<li
              role="presentation"
            >
              <NavLink to="/panel/profile/me" activeClassName="active">
                Mój profil
              </NavLink>
            </li>*/}
            <Dropdown.Item
              as={NavLink}
              to="/panel/profile/me/reports/sms-report"
            >
              Raport SMS
            </Dropdown.Item>
            <Dropdown.Item
              as={NavLink}
              to="/panel/profile/me/settings/notifications"
            >
              Ustawienia
            </Dropdown.Item>
            <Dropdown.Item
              className="logout-btn"
              onClick={() => this.logOut()}
            >
              Wyloguj się
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  user: {...{ ...state.user }.user},
});

export default connect(mapStateToProps)(MenuUser);


