import React, { Component } from 'react';
import './style.css';

class Loader extends Component {
  render() {
    return (
      <div className="loader">
        Loading...
      </div>
    );
  }
}

export default Loader;
