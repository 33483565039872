import React, { Component } from 'react';
import UserAction from '../../../../actions/UserAction';
import HorizontalTimeline from '../../../../containers/Timeline/HorizontalTimeline';
import Loader from '../../../../components/Loader';
import ClinicReportView  from './ClinicReportView';

class SmsReport extends Component {

  constructor(props) {
    super(props);

    this.state = {
      reportData: [],
      reportIsLoading: true,
    }
  }

  componentDidMount() {
    this.loadReport();
  }

  loadReport = () => {
    UserAction.promiseGetSmsReport().then((response) => {
      if (response.status === 200) {
        this.setState({
          reportData: response.data,
          reportIsLoading: false,
        })
      }
    });
  }

  render() {
    const {reportIsLoading, reportData} = this.state;

    if (reportIsLoading) {
      return <Loader />;
    }

    return (
      <div>
        {reportData.clinic.map((clinicItemView) => {
          return <ClinicReportView key={clinicItemView.id} clinicItem={clinicItemView} />
        })}
      </div>
    );
  }
}

export default SmsReport;
