import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import SmartSearchInput from '../../components/SmartSearchInput';
import ListItems from './ListItems';
import StringHelper from './StringHelper';
import './style.css';

class OnlineActiveSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: '',
      show: { display: 'none' },
    };
  }

  onSearchHandler = (searchWord) => {
    this.setState({
      searchWord,
      show: { display: 'block' },
    });
    this.props.serachCallback(searchWord);
  };

  onSelectHandler = (item) => {
    const { onSelect, name } = this.props;
    this.setState({
      show: { display: 'none' },
      searchWord: StringHelper.findValues(item, name),
    });
    onSelect(item);
  };

  onFocusHandler = () => {
    this.setState({
      show: { display: 'block' },
    });
  };


  handleClickOutside = () => {
    this.setState({
      show: { display: 'none' },
    });
  };

  showListItems = () => {
    const {
      items,
      isLoading,
      value,
      name,
      small,
    } = this.props;

    if (isLoading) {
      return <div><i className="fa fa-spinner fa-spin" aria-hidden="true" /></div>;
    }

    if (items && items.length) {
      return (
        <ListItems
          items={items}
          value={value}
          name={name}
          small={small}
          onSelect={this.onSelectHandler}
        />
      );
    }
    return '';
  };

  render() {
    const { defaultValue } = this.props;
    const { searchWord } = this.state;
    const { show } = this.state;
    return (
      <div>
        <SmartSearchInput
          onSearch={this.onSearchHandler}
          defaultValue={defaultValue || searchWord}
        />
        <div style={show} className="searchDropdownPos">
          {this.showListItems()}
        </div>
      </div>
    );
  }
}

export default onClickOutside(OnlineActiveSelect);
