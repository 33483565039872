export default class {
  checkValidation = (context, inputName, value, isRecursion) => {
    const newContext = { ...context };
    const newInput = { ...context[inputName] };
    const { validators } = newInput;

    newInput.validationMessages = [];
    newInput.value = value;
    newContext[inputName] = newInput;

    if (value === '' && newInput.require === false) {
      return newContext;
    }

    if (validators) {
      validators.forEach((validator) => {
        if (value !== undefined) {
          if (validator.isValid(newInput, newContext, inputName, isRecursion) === false) {
            newInput.validationMessages = [...validator.errors, ...newInput.validationMessages];
          }
        } else if (value === undefined && newInput.require === true) {
          newInput.validationMessages = [...validator.errors, 'Błędna wartość'];
        }
      });
    }

    return newContext;
  };

  reValidForm = (form) => {
    let newContext = { ...form.fields };

    const entries = Object.entries(newContext);

    entries.forEach((inputItem) => {
      newContext = this.checkValidation(
        newContext,
        inputItem[0],
        inputItem[1].value,
      );
    });

    return newContext;
  };

  formIsValid = (form) => {
    const entries = Object.entries(form.fields);
    let isValid = true;
    entries.forEach((inputItem) => {
      const { validators, require, value } = inputItem[1];
      const validatorsIsDefined = validators !== undefined;
      if ((require === true || !require && value) && validatorsIsDefined && validators.length) {
        if (value !== undefined) {
          validators.forEach((validator) => {
            if (validator.isValid(inputItem[1], form.fields, inputItem[0], false) === false) {
              isValid = false;
            }
          });
        } else if (value === undefined) {
          isValid = false;
        }
      }
    });

    return isValid;
  }
}
