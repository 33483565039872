import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import TableView from '../TableView';
import ConfirmDialogAction from '../../actions/ConfirmDialogAction';
import ConfirmDialog from '../ConfirmDialog';


class InvitationsView extends Component {
  tableColumns = [
    {
      text: 'email',
      property: 'email',
    },
    {
      text: 'Status',
      property: 'id',
      onText: item => (
        <span>
          Zaproszenie wysłane&nbsp;&nbsp;-&nbsp;
          <Button
            key="resend-button"
            variant="link"
            onClick={() => this.onResendInvitation(item)}
            bsSize="small"
          >
            Wyślij ponownie
          </Button>
        </span>
      ),
    },
  ];

  buttons = [
    {
      button: item => (
        <Button
          key="delete-button"
          variant="danger"
          bsSize="small"
          onClick={() => ConfirmDialogAction.openDialog('cancel-invitation', item.id)}
        >
          Anuluj
        </Button>
      ),
    },
  ];

  onResendInvitation(item) {
    const { onResendInvitation } = this.props;
    return onResendInvitation && onResendInvitation(item);
  }


  render() {
    const { invitations, onCancel } = this.props;

    if (invitations && !invitations.length) {
      return null;
    }

    return (
      <div>
        <TableView
          paginator={false}
          columns={this.tableColumns}
          items={invitations}
          buttons={this.buttons}
        />
        <ConfirmDialog
          name="cancel-invitation"
          cancelTextButton="Anuluj"
          confirmTextButton="Usuń zaproszenie"
          onConfirm={onCancel}
        >
          Na pewno chcesz anulować zaproszenie?
        </ConfirmDialog>
      </div>
    );
  }
}

export default InvitationsView;
