import BaseAction from './BaseAction';

class RecognitionAction extends BaseAction {
  classPrefix = 'Recognition';

  promiseGetRecognitions = () => {
    const url = '/api/recognition/';
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  };
}

export default new RecognitionAction();
