import UserEnum from '../Enum/UserEnum';

class MenuAuthorization {
  /**
   * @param {UserModel} user
   */
  canShowClinicsTab = user => user.type !== UserEnum.TYPE_OFFICE_WORKER;
}

export default new MenuAuthorization();
