const initState = {
  showModal: false,
};


export default (state = initState, action) => {
  switch (action.type) {
    case 'USER_WORKING_DAY_OPEN_MODAL': {
      const newState = { ...state };
      newState.showModal = true;
      return newState;
    }
    case 'USER_WORKING_DAY_CLOSE_MODAL': {
      const newState = { ...state };
      newState.showModal = false;
      return newState;
    }
    default:
      return state;
  }
};
