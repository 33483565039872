import React, { Component } from 'react';
import Menu from '../../components/Menu';
import Notifications from '../Notifications';
import UserAction from '../../actions/UserAction';
import { Alert } from 'react-bootstrap';
import Loader from '../../components/Loader';

class AcceptInvite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invitation: false,
      accepted: false,
      tokenFail: false,
    };

    UserAction.promiseGetInvitation(this.props.match.params.token)
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            invitation: true,
          });
          UserAction.promiseAcceptInvitation(response.data.token)
            .then((response2) => {
              if (response2 && response2.status === 200) {
                this.setState({
                  accepted: true,
                });
              }
            });
        } else if (response && response.status === 204) {
          this.setState({
            invitation: true,
            tokenFail: true,
          });
        }
      });
  }

  showLoader = () => {
    const { invitation, accepted, tokenFail } = this.state;
    if (!invitation || !(accepted || tokenFail)) {
      return <Loader />;
    }

    if (tokenFail) {
      return (
        <Alert variant="warning">
          Zaproszenie nieaktywne
        </Alert>
      );
    }

    return (
      <Alert variant="success">
        Zaproszenie zaakceptowane
      </Alert>
    );
  }

  render() {
    return (
      <div>
        <Menu />
        <div className="page-container">
          <h1 className="text-center">
            Akceptacja zaproszenia
          </h1>
          {this.showLoader()}
          <Notifications />
        </div>
      </div>
    );
  }
}

export default AcceptInvite;
