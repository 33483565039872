import jwtParser from './jwtParser';

class JwtStorage {
  static JWT_TOKEN = 'jwtToken';

  static JWT_REFRESH_TOKEN = 'jwtRefreshToken';

  static getJwt() {
    let isJWT = localStorage.getItem(JwtStorage.JWT_TOKEN) || false;
    if (isJWT) {
      isJWT = jwtParser(isJWT);
      const timestamp = JwtStorage.getCurrentTimestamp();
      if (timestamp > isJWT.exp) {
        localStorage.removeItem(JwtStorage.JWT_TOKEN);
        return false;
      }
      return isJWT;
    }

    return false;
  }

  static getRefreshToken() {
    return localStorage.getItem(JwtStorage.JWT_REFRESH_TOKEN) || false;
  }

  static getRawJwt() {
    const rawJwt = localStorage.getItem(JwtStorage.JWT_TOKEN) || false;
    if (rawJwt) {
      const isJWT = jwtParser(rawJwt);
      const timestamp = JwtStorage.getCurrentTimestamp();
      if (timestamp > isJWT.exp) {
        localStorage.removeItem(JwtStorage.JWT_TOKEN);
        return false;
      }
      return rawJwt;
    }

    return false;
  }

  static setJwtToken(token) {
    localStorage.setItem(JwtStorage.JWT_TOKEN, token);
  }

  static setJwtRefreshToken(token) {
    localStorage.setItem(JwtStorage.JWT_REFRESH_TOKEN, token);
  }

  static getCurrentTimestamp() {
    return new Date().getTime() / 1000;
  }

  static removeJwt() {
    localStorage.removeItem(JwtStorage.JWT_TOKEN);
    localStorage.removeItem(JwtStorage.JWT_REFRESH_TOKEN);
  }
}

export default JwtStorage;
