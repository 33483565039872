import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';

import 'react-credit-cards/es/styles-compiled.css';

import Input from '../../components/Inputs/Input';
import Checkbox from '../../components/Inputs/Checkbox';
import Loader from '../../components/Loader';
import SaveButton from '../../components/SaveButton';
import FormsAction from '../../actions/FormsAction';
import RegisterAction from '../../actions/RegisterAction';
import formSelector from '../../helpers/selectors/formSelector';
import UserPart from './UserPart';

import './style.css';
import BaseAction from '../../actions/BaseAction';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsOk: false,
      sendingData: false,
    };

    FormsAction.registerForm('RegisterForm').then(() => {
      this.setState({ formIsOk: true });
    });
  }

  onSubmitHandler = () => {
    const { registerForm } = this.props;
    FormsAction.checkFormValidation('RegisterForm');

    if (registerForm.isValid) {
      this.setState({sendingData: true});
      RegisterAction.promiseRegisterAction(registerForm)
        .then((response) => {
          if (response && response.status === 201) {
            this.setState({ sendingData: false });
              RegisterAction.makeDispatch('REGISTER_POST_ACTION_SUCCEED');
          } else {
            if (response.data) {
              response.data.forEach((item) => {
                switch (item.property_path) {
                  case 'employeeData.email':
                    FormsAction.updateAddValidationMessage('RegisterForm', 'email', [item.message]);
                    break;
                  default:
                    //
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({sendingData: false});
        });
    }
  };

  updateValue = (name, value) => {
    FormsAction.updateValue('RegisterForm', name, value);
  };

  showCabinetInput = registerForm => (
    <Input
      inputElement={registerForm.fields.cabinetName}
      onChange={value => this.updateValue('cabinetName', value)}
    />
  );

  render() {
    const { formIsOk, sendingData } = this.state;
    if (!formIsOk) {
      return <Loader />;
    }

    const { registerForm, register } = this.props;
    return (
      <div className="register-form">
        <Row>
          <Col md={6} sm={12}>
            <div className="borderSeparatorLeft">
              <UserPart
                registerForm={registerForm}
                onUpdateValue={this.updateValue}
              />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="borderSeparatorRight">
              <h4>
                Dane placówki
              </h4>
              <Row>
                <Col md={12} sm={12}>
                  {this.showCabinetInput(registerForm)}
                </Col>
                <Col md={6} sm={12} />
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Form.Label>Miasto*</Form.Label>
                  <Input
                    inputElement={registerForm.fields.city}
                    onChange={value => this.updateValue('city', value)}
                    disabled={registerForm.fields.addAccountToExistingCabinet.value}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>Kod pocztowy*</Form.Label>
                  <Input
                    inputElement={registerForm.fields.postalCode}
                    onChange={value => this.updateValue('postalCode', value)}
                    disabled={registerForm.fields.addAccountToExistingCabinet.value}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Form.Label>Ulica*</Form.Label>
                  <Input
                    inputElement={registerForm.fields.street}
                    onChange={value => this.updateValue('street', value)}
                    disabled={registerForm.fields.addAccountToExistingCabinet.value}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>Numer budynku*</Form.Label>
                  <Input
                    inputElement={registerForm.fields.streetNumber}
                    onChange={value => this.updateValue('streetNumber', value)}
                    disabled={registerForm.fields.addAccountToExistingCabinet.value}
                  />
                </Col>
              </Row>
              <div className="margin-div" />
              <Checkbox
                inputElement={registerForm.fields.acceptRegulations}
                onChange={value => this.updateValue('acceptRegulations', value)}
              />
              <Checkbox
                inputElement={registerForm.fields.agree}
                onChange={value => this.updateValue('agree', value)}
              />
            </div>
          </Col>
        </Row>
        <div className="margin-div" />
        <Row bsClass="row">
          <Col md={6} sm={12} mdOffset={3}>
            <SaveButton
              variant="primary"
              bsSize="large"
              block
              disabled={!registerForm.isValid}
              text="Zarejestruj konto"
              onSave={this.onSubmitHandler}
              isLoading={sendingData}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  registerForm: formSelector(state.forms, 'RegisterForm'),
  register: state.register,
});

export default connect(mapStateToProps)(RegisterForm);
