import React, {Component} from 'react';
import PageOverlay from '../PageOverlay/PageOverlay';
import PdfViewer from './PdfViewer';
import ImageViewer from './ImageViewer';
import IconButton from '../Button/IconButton';




class FileViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayIsOpen: false,
    };
  }

  onClickHandler = (e) => {
    this.setState({
      overlayIsOpen: true,
    });
  }

  closeOverlay = () => {
    this.setState({
      overlayIsOpen: false,
    });
  }

  showOverlay = () => {
    const { overlayIsOpen } = this.state;
    if (!overlayIsOpen) {
      return null;
    }

    const { attachment } = this.props;

    return (
      <PageOverlay
        onClose={this.closeOverlay}
      >
        {this.getViewer(attachment)}
      </PageOverlay>
    );
  }

  getViewer = (attachment) => {
    const extension = attachment.fileName.split('.').pop();
    switch (extension) {
      case 'pdf':
        return (
          <PdfViewer attachment={attachment} />)
      case 'jpg':
      case 'jpeg':
      case 'png':
        return <ImageViewer attachment={attachment} />
      default:
        return <i className="far fa-file" />
    }
  }

  render() {
    const { children, tooltip } = this.props;
    return (
      <IconButton
        className="file-viewer"
        onClick={this.onClickHandler}
        variant="link"
        tooltip={tooltip}
      >
        {children}
        {this.showOverlay()}
      </IconButton>
    );
  }
}

export default FileViewer;
