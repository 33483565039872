import React, { Component } from 'react';
import { Alert, Button, ButtonGroup, ButtonToolbar, Col, Row } from 'react-bootstrap';
import VisitAction from '../../../actions/VisitAction';
import Loader from '../../../components/Loader';
import TableView from '../../../components/TableView';
import JewRender from '../../../components/Jaw/JewRender';
import DateTime from '../../../helpers/DateTime';
import "./style.css"
import ProcedureAction from '../../../actions/ProcedureAction';
import ToothProperties from '../../../components/Teeth/ToothProperties';

class History extends Component {
  tableColumns = [
    {
      text: 'Klinika',
      property: 'clinic',
      onText: item => <span>{item.clinic.name}</span>,
    },
    {
      text: 'Lekarz',
      property: 'lastName',
      onText: item => (
        <span>
          {item.doctor.name}
          &nbsp;
          {item.doctor.lastName}
        </span>
      ),
    },
    {
      text: 'Wizyta',
      property: 'visitDate',
      onText: item => DateTime.phpDateTimeStringToDateTimeString(item.visitDate),
    },
    {
      text: 'Zakończenie',
      property: 'closeDate',
      onText: item => item.closeDate ? DateTime.phpDateTimeStringToDateTimeString(item.closeDate) : '-',
    },
    {
      text: 'Status',
      property: 'status',
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      historyIsEmpty: false,
      historyList: [],
      proceduresList: [],
      selectedVisit: {},
      teethMode: JewRender.JEW_RENDER_MODE_PERMANENT,
    };
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    VisitAction.promiseGetPatientHistoryList(params.patientId).then((data) => {
      if (data && data.status === 200) {
        if (data.data.length) {
          this.setState({
            historyList: data.data,
            isLoading: false,
            historyIsEmpty: false,
            selectedVisit: data.data[0],
          });
        } else {
          this.setState({
            historyList: [],
            isLoading: false,
            historyIsEmpty: true,
            selectedVisit: {},
          });
        }
      }
    });

    ProcedureAction.promiseGetProcedures().then((data) => {
      this.setState({
        proceduresList: data.data,
      });
    });
  }

  showData = () => {
    const { isLoading, historyList } = this.state;
    if (isLoading) {
      return <Loader />;
    }
    return (
      <div>
        <TableView
          columns={this.tableColumns}
          items={historyList}
          onRowClick={item => this.setState({ selectedVisit: item })}
        />
      </div>
    );
  }

  onChangeJawMode = (newMode) => {
    this.setState({
      teethMode: newMode,
    });
  }

  render() {
    const {
      selectedVisit,
      proceduresList,
      teethMode,
      historyIsEmpty,
    } = this.state;

    if (historyIsEmpty) {
      return (
        <Alert variant="primary">
          Brak historii wizyt.
        </Alert>
      );
    }

    if (!selectedVisit.id) {
      return <Loader />;
    }

    return (
      <Row className="visit-history-details">
        <Col md={5}>
          {this.showData()}
        </Col>
        <Col md={7}>
          <h3>
            Notatka
          </h3>
          <div className="visit-notes">
            {selectedVisit && selectedVisit.description}
          </div>
          <h3>
            Diagram
          </h3>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                variant={teethMode === JewRender.JEW_RENDER_MODE_PERMANENT ? 'success' : null}
                onClick={() => this.onChangeJawMode(JewRender.JEW_RENDER_MODE_PERMANENT)}
              >
                Zęby stałe
              </Button>
              <Button
                variant={teethMode === JewRender.JEW_RENDER_MODE_MILKY ? 'success' : null}
                onClick={() => this.onChangeJawMode(JewRender.JEW_RENDER_MODE_MILKY)}
              >
                Zęby mleczne
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
          <JewRender
            proceduresList={proceduresList}
            graphModeTeeth={teethMode}
            teeth={selectedVisit && selectedVisit.id ? selectedVisit.teeth : {}}
          />
          <ToothProperties
            editable={false}
            tooth={{}}
            teeth={selectedVisit.teeth}
            textIfEmpty={"Brak zmian na diagramie"}
          />
        </Col>
      </Row>
    );
  }
}

export default History;
