import axios from 'axios';
import BaseAction from './BaseAction';
import JwtStorage from '../helpers/JwtStorage';
import config from '../config';


class RefreshTokenAction extends BaseAction {
  static refresh() {
    const refreshToken = JwtStorage.getRefreshToken();

    const params = {
      refresh_token: refreshToken,
    };

    axios({
      method: BaseAction.METHOD_POST,
      url: `${config.api}/api/token/refresh`,
      data: params,
    }).then((data) => {
      if (data) {
        JwtStorage.setJwtToken(data.data.token);
        JwtStorage.setJwtRefreshToken(data.data.refresh_token);
      }
    });
  }
}

export default RefreshTokenAction;
