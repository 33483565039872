const initState = {
  eventPopupIsOpen: false,
  settings: {
    timeStep: 15,
    clinicWorkingHoursCollection: {
      collection: [],
    },
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'CALENDAR_OPEN_EVENT_POPUP': {
      const newState = { ...state };
      newState.eventPopupIsOpen = true;
      return newState;
    }
    case 'CALENDAR_CLOSE_EVENT_POPUP': {
      const newState = { ...state };
      newState.eventPopupIsOpen = false;
      return newState;
    }
    case 'CALENDAR_CHANGE_SETTINGS_PROPERTY': {
      const newState = { ...state };
      newState.settings[action.data.settingName] = action.data.newValue;
      return newState;
    }
    default:
      return state;
  }
};
