import React, { Component } from 'react';
import FileAction from '../../actions/FileAction';
import { Page } from 'react-pdf';
import Loader from '../Loader';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import IconButton from '../Button/IconButton';
import './pdf-viewer.css';

class PdfViewer extends Component {

  isLoaded = false;

  constructor(props) {
    super(props);

    this.state = {
      pdfIsReady: false,
      attachment: null,
      attachmentRaw: null,
      pages: null,
      page: 1,
      scale: 2
    };
  }

  componentDidMount() {
    this.downloadDocument();
  }

  downloadDocument() {
    const { attachment } = this.props;
    FileAction.download(attachment.id)
      .then((response) => {
        if (response && response.status === 200) {
          this.setState({
            attachmentIsReady: true,
            attachment: this.strToBuffer(atob(response.data)),
          });
        }
      });
  }

  strToBuffer (string) {
    let arrayBuffer = new ArrayBuffer(string.length * 1);
    let newUint = new Uint8Array(arrayBuffer);
    newUint.forEach((_, i) => {
      newUint[i] = string.charCodeAt(i);
    });
    return newUint;
  }

  onDocumentLoadSuccess = ({ numPages }) => {
      if (this.isLoaded) {
        return;
      }

      this.isLoaded = true;

      this.setState({
        pages: numPages,
      })
  }

  onChangePage = (nextPage) => {
    const { pages } = this.state;
    if (nextPage <= 0 || nextPage > pages) {
      return;
    }

    this.setState({
      page: nextPage,
    })
  }

  onChangeScale = (nextScale) => {
    if (nextScale <= 0 || nextScale > 100) {
      return;
    }

    this.setState({
      scale: nextScale,
    })
  }

  renderPages = () => {
    return <Page pageNumber={1} />
  }

  render() {
    const { attachmentIsReady, attachment, pages, page, scale } = this.state;
    if (!attachmentIsReady) {
      return <Loader />;
    }

    return (
      <div className="pdf-viewer-content">
        <Document
          file={{
            data: attachment
          }}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={page} scale={scale} />
        </Document>
        <div className="pages-toolbar">
          <div className="tool previous-page">
            <IconButton
              onClick={() => this.onChangePage(page-1)}
            >
              <i className="fas fa-chevron-left" />
            </IconButton>
          </div>
          <div className="all-pages">{page}/{pages}</div>

          <div className="tool next-page">
            <IconButton
              onClick={() => this.onChangePage(page+1)}
            >
              <i className="fas fa-chevron-right" />
            </IconButton>
          </div>
          <div className="tool">
            <IconButton
              onClick={() => this.onChangeScale(scale-0.5)}
            >
              <i className="fas fa-search-minus" />
            </IconButton>
          </div>
          <div className="tool">
            <IconButton
              onClick={() => this.onChangeScale(scale+0.5)}
            >
              <i className="fas fa-search-plus" />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

export default PdfViewer;