const initState = {
  patient: {},
  isLoading: true,
  success: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case 'PATIENT_GET_PATIENT_SUCCEED': {
      return {
        ...state,
        patient: { ...action.data },
        isLoading: false,
        success: true,
      };
    }
    case 'PATIENT_GET_PATIENT_FAILED': {
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    }
    case 'PATIENT_GET_PATIENT_START': {
      return {
        ...state,
        isLoading: true,
        success: false,
      };
    }
    case 'VISIT_CLOSE_VISIT':
    case 'PATIENT_VISIT_WAS_CLOSED': {
      const { patient } = state;
      patient.scheduleIdFromLatestOpenVisit = 0;
      patient.currentVisit = 0;
      return {
        ...state,
        patient
      };
    }
    case 'PATIENT_VISIT_WAS_OPEN': {
      const { patient } = state;
      patient.scheduleIdFromLatestOpenVisit = action.data;
      patient.scheduleIdFromLatestVisit = action.data;
      patient.currentVisit = action.data;
      return {
        ...state,
        patient
      };
    }
    default:
      return state;
  }
};
