import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Badge, DropdownButton, Dropdown } from 'react-bootstrap';
import SaveButton from '../../components/SaveButton';
import WorkingDayElement from './UserWorkingDay/WorkingDayElement';
import UserWorkingDayAction from '../../actions/UserWorkingDayAction';
import DateTime from '../../helpers/DateTime';
import './css/style.css';
import CalendarAction from '../../actions/CalendarAction';


class UserWorkingDay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workingDays: {},
      isLoading: false,
      settingsIsLoading: true,
      formIsChange: false,
      settings: {
        timeStep: 15,
        clinicWorkingHoursCollection: {
          collection: []
        },
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const newId = nextProps.userClinics.selectedClinic.id;
    const newShowModal = nextProps.userWorkingDay.showModal;
    const showModal = this.props.userWorkingDay.showModal;
    const { user } = this.props;

    if (newShowModal !== showModal) {
      if (newShowModal) {
        this.setState({
          settingsIsLoading: true,
        });

        CalendarAction.promiseGetCalendarSettings(user.user.id, newId)
          .then((response) => {
            if (response && response.data) {
              this.setState({
                settings: response.data,
                settingsIsLoading: false,
              });
            }
          });
      } else {
        this.setState({
          workingDays: {},
          formIsChange: false,
        });
      }
    }
  }

  onCloseModalHandler = () => {
    UserWorkingDayAction.closeModal();
  };

  onChangeIsWorkingDay = (dayNumber, isWorkingDay) => {
    const { workingDays } = this.state;
    if (!workingDays[dayNumber]) {
      workingDays[dayNumber] = {};
    }
    workingDays[dayNumber].isWorkingDay = isWorkingDay;
    this.setState({
      workingDays,
      formIsChange: true,
    });
  };

  onChangeFrom = (dayNumber, stringFromTime) => {
    const { workingDays } = this.state;
    if (!workingDays[dayNumber]) {
      workingDays[dayNumber] = {};
    }
    workingDays[dayNumber].workFrom = stringFromTime;
    this.setState({
      workingDays,
      formIsChange: true,
    });
  };

  onChangeTill = (dayNumber, stringTime) => {
    const { workingDays } = this.state;
    if (!workingDays[dayNumber]) {
      workingDays[dayNumber] = {};
    }
    workingDays[dayNumber].workTill = stringTime;
    this.setState({
      workingDays,
      formIsChange: true,
    });
  };

  onSaveHandler = () => {
    const { workingDays, settings } = this.state;
    const { userClinics: { selectedClinic }, user } = this.props;
    this.setState({ isLoading: true });

    const flatDays = [];
    // fill data from default vales
    // settings.clinicWorkingHoursCollection.collection
    // fill data from form
    Object.keys(workingDays).forEach((key) => {
      let object = settings.clinicWorkingHoursCollection.collection.filter((item) =>
        item.dayNumber === +key
      ).shift();

      if (!object) {
        object = {};
      }

      object = { ...object, ...workingDays[key] };
      if (!object.isWorkingDay) {
        object.isWorkingDay = false;
      }
      object.dayNumber = +key;
      flatDays.push(object);
    });

    const settingsForm = {
      workingDays: flatDays,
      timeStep: settings.timeStep,
    }

    CalendarAction.promiseSaveCalendarSettings(user.user.id, selectedClinic.id, settingsForm)
      .then((response) => {
        this.setState({ isLoading: false, formIsChange: false });
        if (user.selectedUser && user.selectedUser.id === user.user.id) {
          CalendarAction.promiseGetCalendarSettings(user.user.id, selectedClinic.id)
            .then((response) => {
              if (response && response.status === 200) {
                Object.keys(response.data)
                  .forEach((key) => {
                    CalendarAction.changeSettingsProperty(key, response.data[key]);
                  });
              }
            });
        }
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  getWorkingHoursForDay = (dayNumber, collection) => {
    const results = collection.filter((item => item.dayNumber === dayNumber));
    return results.length > 0 ? results[0] : null;
  };

  onChangeTimeStep = (e) => {
    const { settings } = this.state;
    const newSettings = { ...settings };
    newSettings.timeStep = +e;
    this.setState({
      settings: newSettings,
      formIsChange: true,
    })
  }

  render() {
    const { userWorkingDay: { showModal }, userClinics: { selectedClinic } } = this.props;
    const { isLoading, formIsChange, settings, settingsIsLoading } = this.state;
    const { collection } = settings.clinicWorkingHoursCollection || { collection: [] };

    if (settingsIsLoading) {
      return null;
    }

    return (
      <div>
        <Modal
          show={showModal}
          onHide={this.onCloseModalHandler}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Dni pracy w
              {' '}
              {selectedClinic.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Godziny pracy gabinetu:
              {' '}
              <Badge>
                {DateTime.showTimeFormTimeString(selectedClinic.openFrom)}
              </Badge>
              {' - '}
              <Badge>
                {DateTime.showTimeFormTimeString(selectedClinic.openTo)}
              </Badge>
            </p>
            <table className="user-working-day-table">
              <thead>
                <tr>
                  <th />
                  <th>Dostępność</th>
                  <th className="user-working-day-time-picker">Od</th>
                  <th className="user-working-day-time-picker">Do</th>
                </tr>
              </thead>
              <tbody>
                <WorkingDayElement
                  dayNameNumber={1}
                  defaultValues={this.getWorkingHoursForDay(1, collection)}
                  onChangeIsWorkingDay={isWorkingDay => this.onChangeIsWorkingDay(1, isWorkingDay)}
                  onChangeFrom={stringTimeFrom => this.onChangeFrom(1, stringTimeFrom)}
                  onChangeTill={stringFrom => this.onChangeTill(1, stringFrom)}
                  minDate={selectedClinic.openFrom}
                  maxDate={selectedClinic.openTo}
                />
                <WorkingDayElement
                  dayNameNumber={2}
                  defaultValues={this.getWorkingHoursForDay(2, collection)}
                  onChangeIsWorkingDay={isWorkingDay => this.onChangeIsWorkingDay(2, isWorkingDay)}
                  onChangeFrom={stringTimeFrom => this.onChangeFrom(2, stringTimeFrom)}
                  onChangeTill={stringFrom => this.onChangeTill(2, stringFrom)}
                  minDate={selectedClinic.openFrom}
                  maxDate={selectedClinic.openTo}
                />
                <WorkingDayElement
                  dayNameNumber={3}
                  defaultValues={this.getWorkingHoursForDay(3, collection)}
                  onChangeIsWorkingDay={isWorkingDay => this.onChangeIsWorkingDay(3, isWorkingDay)}
                  onChangeFrom={stringTimeFrom => this.onChangeFrom(3, stringTimeFrom)}
                  onChangeTill={stringFrom => this.onChangeTill(3, stringFrom)}
                  minDate={selectedClinic.openFrom}
                  maxDate={selectedClinic.openTo}
                />
                <WorkingDayElement
                  dayNameNumber={4}
                  defaultValues={this.getWorkingHoursForDay(4, collection)}
                  onChangeIsWorkingDay={isWorkingDay => this.onChangeIsWorkingDay(4, isWorkingDay)}
                  onChangeFrom={stringTimeFrom => this.onChangeFrom(4, stringTimeFrom)}
                  onChangeTill={stringFrom => this.onChangeTill(4, stringFrom)}
                  minDate={selectedClinic.openFrom}
                  maxDate={selectedClinic.openTo}
                />
                <WorkingDayElement
                  dayNameNumber={5}
                  defaultValues={this.getWorkingHoursForDay(5, collection)}
                  onChangeIsWorkingDay={isWorkingDay => this.onChangeIsWorkingDay(5, isWorkingDay)}
                  onChangeFrom={stringTimeFrom => this.onChangeFrom(5, stringTimeFrom)}
                  onChangeTill={stringFrom => this.onChangeTill(5, stringFrom)}
                  minDate={selectedClinic.openFrom}
                  maxDate={selectedClinic.openTo}
                />
                <WorkingDayElement
                  dayNameNumber={6}
                  defaultValues={this.getWorkingHoursForDay(6, collection)}
                  onChangeIsWorkingDay={isWorkingDay => this.onChangeIsWorkingDay(6, isWorkingDay)}
                  onChangeFrom={stringTimeFrom => this.onChangeFrom(6, stringTimeFrom)}
                  onChangeTill={stringFrom => this.onChangeTill(6, stringFrom)}
                  minDate={selectedClinic.openFrom}
                  maxDate={selectedClinic.openTo}
                />
                <WorkingDayElement
                  dayNameNumber={7}
                  defaultValues={this.getWorkingHoursForDay(7, collection)}
                  onChangeIsWorkingDay={isWorkingDay => this.onChangeIsWorkingDay(7, isWorkingDay)}
                  onChangeFrom={stringTimeFrom => this.onChangeFrom(7, stringTimeFrom)}
                  onChangeTill={stringFrom => this.onChangeTill(7, stringFrom)}
                  minDate={selectedClinic.openFrom}
                  maxDate={selectedClinic.openTo}
                />
                <tr>
                  <td colSpan={4} style={{ height: '15px' }} />
                </tr>
                <tr>
                  <td colSpan={2} >
                    Częstotliwość wizyt:
                  </td>
                  <td className="user-working-day-time-picker" colSpan={2}>
                    <DropdownButton
                      title={settings.timeStep}
                      onSelect={this.onChangeTimeStep}
                    >
                      <Dropdown.Item eventKey={5} active={settings.timeStep === 5}>5 minut</Dropdown.Item >
                      <Dropdown.Item eventKey={10} active={settings.timeStep === 10} >10 minut</Dropdown.Item >
                      <Dropdown.Item eventKey={15} active={settings.timeStep === 15} >15 minut</Dropdown.Item >
                      <Dropdown.Item eventKey={20} active={settings.timeStep === 20} >20 minut</Dropdown.Item >
                      <Dropdown.Item eventKey={30} active={settings.timeStep === 30} >30 minut</Dropdown.Item >
                      <Dropdown.Item eventKey={45} active={settings.timeStep === 45} >45 minut</Dropdown.Item >
                      <Dropdown.Item eventKey={60} active={settings.timeStep === 60} >1 godzina</Dropdown.Item >
                    </DropdownButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <SaveButton
              onSave={this.onSaveHandler}
              variant="success"
              text="Zapisz"
              isLoading={isLoading}
              disabled={!formIsChange}
            />
            <Button
              variant="danger"
              onClick={this.onCloseModalHandler}
            >
              Anuluj
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userClinics: state.userClinics,
  user: state.user,
  userWorkingDay: state.userWorkingDay,
});

export default connect(mapStateToProps)(UserWorkingDay);
