import React, { Component } from 'react';
import ModuleViewWithMenu from '../../../layout/ModuleViewWithMenu';
import SmsReport from './SmsReport/SmsReport';


class ReportMainPage extends Component {

  tabs = [
    {
      label: 'report_sms',
      to: `/panel/profile/me/reports/sms-report`,
      route: `/panel/profile/me/reports/sms-report`,
      component: SmsReport,
    }/*,
    {
      label: 'change_password',
      to: `/panel/profile/me/settings/change-password`,
      route: `/panel/profile/me/settings/change-password`,
      component: Documents
    },*/
  ];

  render() {
    const { match: { params } } = this.props;
    return (
      <ModuleViewWithMenu
        menuItems={this.tabs}
      />
    );
  }
}

export default ReportMainPage;