import React, { Component } from 'react';
import Switch from 'react-switch';
import TimePicker from 'rc-time-picker';
import DateFormat from '../../../helpers/Enum/DateFormat';
import DateTime from '../../../helpers/DateTime';

class WorkingDayElement extends Component {
  static dayNameDictionary = {
    1: 'Poniedziałek',
    2: 'Wtorek',
    3: 'Środa',
    4: 'Czwartek',
    5: 'Piątek',
    6: 'Sobota',
    7: 'Niedziela',
  };

  constructor(props) {
    super(props);
    const { defaultValues, minDate, maxDate } = this.props;
    this.state = {
      checked: defaultValues ? defaultValues.isWorkingDay : false,
      timeFrom: defaultValues ? defaultValues.workFrom : minDate,
      timeTill: defaultValues ? defaultValues.workTill : maxDate,
      disableTimePicker: defaultValues ? !defaultValues.isWorkingDay : true,
    };
  }

  onSwitchHandler = (checked) => {
    let timeFromToSet;
    let timeTillToSet;
    this.props.onChangeIsWorkingDay(checked);
    if (checked) {
      const { timeFrom, timeTill } = this.state;
      timeFromToSet = timeFrom;
      timeTillToSet = timeTill;
      this.props.onChangeFrom(timeFrom);
      this.props.onChangeTill(timeTill);
    } else {
      const { minDate, maxDate } = this.props;
      timeFromToSet = minDate;
      timeTillToSet = maxDate;
      this.props.onChangeFrom(minDate);
      this.props.onChangeTill(maxDate);
    }

    this.setState({
      checked,
      timeFrom: timeFromToSet,
      timeTill: timeTillToSet,
      disableTimePicker: !checked,
    });
  };

  onOpenToChange = (date, momentValue) => {
    if (!momentValue) {
      return;
    }

    momentValue.set({ second: 0, millisecond: 0 });
    const newState = { ...this.state };

    let selectedTime = this.getTimeBetweenOpenHoursOfClinic(momentValue);

    if (date === 'timeFrom') {
      const currentMaxDate = DateTime.stringToMoment(newState.timeTill);
      if (selectedTime > currentMaxDate) {
        selectedTime = currentMaxDate;
      }
      this.props.onChangeFrom(selectedTime.format(DateFormat.TIME_FORMAT));
    } else {
      const currentMinDate = DateTime.stringToMoment(newState.timeFrom);
      if (currentMinDate > selectedTime) {
        selectedTime = currentMinDate;
      }
      this.props.onChangeTill(selectedTime.format(DateFormat.TIME_FORMAT));
    }

    newState[date] = selectedTime.format(DateFormat.TIME_FORMAT);
    this.setState(newState);
  };

  getTimeBetweenOpenHoursOfClinic = (momentValue) => {
    const { minDate, maxDate } = this.props;
    const momentMinValue = DateTime.stringToMoment(minDate);
    const momentMaxValue = DateTime.stringToMoment(maxDate);

    let selectedDate = momentValue;
    if (selectedDate < momentMinValue) {
      selectedDate = momentMinValue;
    }

    if (selectedDate > momentMaxValue) {
      selectedDate = momentMaxValue;
    }

    return selectedDate;
  };

  render() {
    const { dayNameNumber } = this.props;
    const { checked, timeFrom, timeTill, disableTimePicker } = this.state;
    return (
      <tr>
        <td>
          {WorkingDayElement.dayNameDictionary[dayNameNumber]}
        </td>
        <td>
          <Switch
            onChange={this.onSwitchHandler}
            checked={checked}
          />
        </td>
        <td className="user-working-day-time-picker">
          <TimePicker
            value={DateTime.stringToMoment(timeFrom)}
            showSecond={false}
            className="form-control"
            onChange={momentValue => this.onOpenToChange('timeFrom', momentValue)}
            disabled={disableTimePicker}
            minuteStep={15}
          />
        </td>
        <td className="user-working-day-time-picker">
          <TimePicker
            value={DateTime.stringToMoment(timeTill)}
            showSecond={false}
            className="form-control"
            onChange={momentValue => this.onOpenToChange('timeTill', momentValue)}
            disabled={disableTimePicker}
            minuteStep={15}
          />
        </td>
      </tr>
    );
  }
}

export default WorkingDayElement;
