import BaseAction from './BaseAction';

class ProcedureAction extends BaseAction {
  classPrefix = 'Procedure';

  promiseGetProcedures = () => {
    const url = '/api/procedure/';
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  };
}

export default new ProcedureAction();
