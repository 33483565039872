import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import ClinicsList from './ClinicsList';
import AddClinic from './AddClinic';
import ClinicDetail from './ClinicDetail';

class Clinics extends Component {
  render() {
    return (
      <Row className="root-row">
        <Col sm={12}>
          <Switch>
            <Route exac path="/panel/clinics/add" component={AddClinic} />
            <Route path="/panel/clinics/:clinicId/" component={ClinicDetail} />
            <Route exac path="/panel/clinics" component={ClinicsList} />
          </Switch>
        </Col>
      </Row>
    );
  }
}

export default Clinics;
