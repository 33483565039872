import React, { Component } from 'react';
import StringHelper from './StringHelper';

class ListItem extends Component {
  textInput = false;

  onClickHandler = () => {
    this.props.onClick(this.props.item);
    // this.textInput.style.display = 'none';
  };

  prepareName = () => {
    const { item, name } = this.props;
    return StringHelper.findValues(item, name);
  };

  prepareSmallText = () => {
    const { small, item } = this.props;
    if (small) {
      return (<small>{StringHelper.findValues(item, small)}</small>);
    }
    return null;
  };

  render() {
    return (
      <li
        onClick={this.onClickHandler}
        ref={(input) => { this.textInput = input; }}
      >
        {this.prepareName()}
        &nbsp;
        {this.prepareSmallText()}
      </li>
    );
  }
}

export default ListItem;
