import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Jumbotron, Row } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import Menu from './Menu';
import EditClinic from './EditClinic';
import ClinicAction from '../../actions/ClinicAction';
import Loader from '../../components/Loader';
import TitleHeader from '../../components/Header/TitleHeader';
import ClinicUsers from './ClinicUsers';
import ClinicJambotron from '../../components/Clinic/ClinicJambotron';


class ClinicDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinicIsLoading: true,
      failLoadClinic: false,
    };
    const { match } = this.props;

    ClinicAction.promiseGetUserClinic(match.params.clinicId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            clinicIsLoading: false,
          });
          ClinicAction.changeCurrentClinic(response.data);
        } else {
          this.setState({
            failLoadClinic: true,
          });
        }
      })
      .catch(() => {
        this.setState({
          failLoadClinic: true,
        });
      });
  }

  render() {
    const { clinicIsLoading, failLoadClinic } = this.state;
    const { userClinics: { currentClinic } } = this.props;

    if (failLoadClinic) {
      return (
        <Alert variant="danger">
          Brak uprawnień do przeglądania tej witryny.
        </Alert>
      );
    }

    if (clinicIsLoading || !currentClinic.id) {
      return <Loader />;
    }

    return (
      <div className="work-space">
        <ClinicJambotron clinic={currentClinic}/>
        <Row>
          <Col sm={12}>
            <Jumbotron className="form-container patient-jumbo">
              <Switch>
                <Route exac path="/panel/clinics/:clinicId/detail" component={EditClinic} />
                <Route exac path="/panel/clinics/:clinicId/users" component={ClinicUsers} />
              </Switch>
            </Jumbotron>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userClinics: state.userClinics,
});

export default connect(mapStateToProps)(ClinicDetail);
