import React, { Component } from 'react';
import { ProgressBar, ButtonGroup } from 'react-bootstrap';
import SaveIcon from '../SaveButton/SaveIcon';
import FileAction from '../../actions/FileAction';
import IconButton from '../Button/IconButton';
import './style.css';

class DropUploader extends Component {
  constructor(props) {
    super(props);
    this.isInProgress = false;
    this.state = {
      files: [],
      progress: 0,
      processing: false,
      bsStyle: null,
    }
  }

  dropHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.isInProgress) {
      return;
    }

    let files = [...event.dataTransfer.files];
    files = files.filter((file) => this.fileIsAcceptable(file.name));

    this.setState({
      files,
      progress: 0,
      processing: false,
      bsStyle: null,
    });
  }
  onDragOverHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

  }

  progressCallback = (progressEvent) => {
    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
    const percent = Math.round((progressEvent.loaded * 100) / totalLength);
    let processing = false;
    if (percent >= 100) {
      processing = true;
    }
    this.setState({
      progress: percent,
      processing,
    });
  }

  saveHandler = () => {
    const { files } = this.state;
    this.isInProgress = true;
    FileAction.upload(files, this.progressCallback).then((response) => {
      if (response && response.status === 201) {
        this.onUploadComplete(response.data);
      } else {
        this.isInProgress = false;
        this.setState({
          bsStyle: 'danger',
          processing: false,
        });
      }
    }).catch(() => {
      this.isInProgress = false;
      this.setState({
        bsStyle: 'danger',
        processing: false,
      });
    });
  }

  onUploadComplete = (data) => {
    const { onUploadComplete } = this.props;
    this.isInProgress = false;

    if (onUploadComplete) {
      onUploadComplete(data);
    }

    this.setState({
      files: [],
      bsStyle: null,
      processing: false,
      progress: 0,
    });
  }

  onSelectFileHandler = (e) => {
    if (this.isInProgress) {
      return;
    }

    const fileListDict = e.target.files;
    const files = [];
    Object.keys(fileListDict).forEach((key) => {
      if (this.fileIsAcceptable(fileListDict[key].name)) {
        files.push(fileListDict[key]);
      }
    });

    this.setState({
      files,
      progress: 0,
      processing: false,
      bsStyle: null,
    })
  }

  fileIsAcceptable = (fileName) => {
    const extension = fileName.split('.').pop();
    const acceptableExtensions = ['pdf', 'png', 'jpg', 'jpeg'];
    return acceptableExtensions.includes(extension.toLowerCase());
  }

  removeFile = (fileName) => {
    const { files } = this.state;

    this.setState({
      files: files.filter((item) => item.name !== fileName),
    })
  }

  render() {
    const { handleProcessing } = this.props;
    const { files, progress, processing, bsStyle } = this.state;
    const plusIcon = <i className="fas fa-plus fa-lg" />;
    const uploadIcon = <i className="fas fa-cloud-upload-alt" />;

    const percentProgress = handleProcessing ? 100 : progress;

    return (
      <div className="file-uploader drop-uploader"
        onDrop={this.dropHandler}
        onDragOver={this.onDragOverHandler}
      >
        <ProgressBar active={handleProcessing || processing} now={percentProgress} variant={bsStyle} />
        <ul className="files-list">
          {files.map(file => <li key={file.name}>{file.name} <IconButton onClick={() => this.removeFile(file.name)}><i className="far fa-times-circle" /></IconButton></li>)}
        </ul>
        <div className="place-holder">
          <div>
            Przeciągnij i upuść pliki tutaj<br />
            lub<br /> Wybierz pliki, które chcesz wgrać.
          </div>
        </div>
        <div className="upload-button-wrapper">
          <ButtonGroup>
            <IconButton
              text={plusIcon}
              replace
              tooltip="Wybierz pliki"
              variant="primary"
            >
              {plusIcon}
              <input
                type="file"
                multiple
                onChange={this.onSelectFileHandler}
                accept="image/png, image/jpeg, application/pdf"
              />
            </IconButton>
            <SaveIcon
              variant="success"
              onSave={this.saveHandler}
              isLoading={processing}
              disabled={files.length === 0}
              tooltip="Zapisz"
            >
              <i className="fas fa-cloud-upload-alt" />
            </SaveIcon>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default DropUploader;
