import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Alert } from 'react-bootstrap';
import Input from '../../components/Inputs/Input';
import SaveButton from '../../components/SaveButton';
import UserAction from '../../actions/UserAction';
import FormsAction from '../../actions/FormsAction';

import './style.css';
import Loader from '../../components/Loader';
import formSelector from '../../helpers/selectors/formSelector';



class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: '', // danger, success
      alertTitle: '',
      isLoading: false,
      formIsOk: false,
    };

    FormsAction.registerForm('ResetPassword').then(() => {
      this.setState({ formIsOk: true });
    });
  }

  showAlert = () => {
    const { showAlert, alertTitle } = this.state;
    if (!showAlert) {
      return null;
    }

    return (
      <Alert variant={showAlert}>
        <h4>{alertTitle}</h4>
      </Alert>
    );
  }

  updateValue = (name, value) => {
    FormsAction.updateValue('ResetPassword', name, value);
  };

  onSave = (e) => {
    e.preventDefault();
    const { resetPasswordForm, match } = this.props;
    FormsAction.checkFormValidation('ResetPassword');

    if (!resetPasswordForm.isValid) {
      return;
    }
    this.setState({
      isLoading: true,
    });

    UserAction.promiseChangeUserPasswordToken(
      match.params.token,
      resetPasswordForm.fields.password.value,
    ).then((data) => {
      if (!data) {
        this.setState({
          showAlert: 'danger',
          alertTitle: 'Token wygasł',
          isLoading: false,
        });
        return;
      }
      this.setState({
        showAlert: 'success',
        alertTitle: 'Hasło zostało zmienione, możesz sie zalogować',
        isLoading: false,
      });
    })
      .catch(() => {
        this.setState({
          showAlert: 'danger',
          alertTitle: 'Token wygasł',
          isLoading: false,
        });
      });
  };

  render() {
    const {
      formIsOk,
      isLoading,
    } = this.state;

    if (!formIsOk) {
      return <Loader />;
    }

    const { resetPasswordForm } = this.props;

    return (
      <div className="login-page">
        <div className="content">
          <div className="content-one" />
          <div className="content-two">
            <h1>Resetuj hasło</h1>
            <form
              onSubmit={e => this.onSave(e)}
            >
              <div className="login-form">
                <Input
                  inputElement={resetPasswordForm.fields.password}
                  onChange={value => this.updateValue('password', value)}
                />
                <Input
                  inputElement={resetPasswordForm.fields.password2}
                  onChange={value => this.updateValue('password2', value)}
                />
                <SaveButton
                  isLoading={isLoading}
                  onSave={() => {}}
                  text="Zmień hasło"
                />
                <div className="forgot-password">
                  <NavLink to="/login" activeClassName="active">
                    Zaloguj
                  </NavLink>
                </div>
              </div>
            </form>
            {this.showAlert()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  login: { ...state.login },
  resetPasswordForm: formSelector(state.forms, 'ResetPassword'),
});

export default connect(mapStateToProps)(ResetPassword);
