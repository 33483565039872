import React, { Component } from 'react';
import { connect } from 'react-redux';
import ClinicAction from '../../actions/ClinicAction';
import ClinicForm from './ClinicForm';

class AddClinic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
    };
  }

  addClinic = (addNewClinicForm) => {
    ClinicAction.promiseAddClinic(addNewClinicForm)
      .then((data) => {
        this.setState({
          isSaving: false,
        });
        const url = `/panel/clinics/edit/${data.data[0]}`;
        this.props.history.push(url);
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  render() {
    const { isSaving } = this.state;
    return (
      <ClinicForm
        isSaving={isSaving}
        onSubmitHandler={this.addClinic}
      />
    );
  }
}

const mapStateToProps = state => ({
  register: state.register,
});

export default connect(mapStateToProps)(AddClinic);
