

const initState = {
  userAllClinics: [],
  selectedClinic: {},
  userAllClinicsLoading: false,
  currentClinic: {},
};


export default (state = initState, action) => {
  switch (action.type) {
    case 'GET_USER_CLINICS_START': {
      const newState = { ...state };
      newState.userAllClinicsLoading = true;
      return newState;
    }
    case 'GET_USER_CLINICS_SUCCEED': {
      const newState = { ...state };
      newState.userAllClinics = action.data;
      newState.userAllClinicsLoading = false;
      if (!newState.selectedClinic.id) {
        newState.selectedClinic = { ...newState.userAllClinics[0] };
      } else {
        const results = newState.userAllClinics.filter(item => item.id === newState.selectedClinic.id);
        if (results.length) {
          newState.selectedClinic = { ...results[0] };
        }
      }

      return newState;
    }
    case 'ACTION_USER_CHANGE_CURRENT_CLINIC': {
      const newState = { ...state };
      newState.selectedClinic = { ...action.data };
      return newState;
    }
    case 'ACTION_USER_CLINICS_SET_CURRENT_CLINIC': {
      const newState = { ...state };
      newState.currentClinic = { ...action.data };
      return newState;
    }
    default:
      return state;
  }
};
