import React, { Component } from 'react';
import Crown from './svg/Crown';
import CrownB from './svg/CrownB';
import CrownC from './svg/CrownC';
import TripleRoot from './svg/TripleRoot';
import SingleARoot from './svg/SingleARoot';
import SingleBRoot from './svg/SingleBRoot';
import DoubleARoot from './svg/DoubleARoot';
import DoubleBRoot from './svg/DoubleBRoot';
import config from '../../../config';

class TeethModel extends Component {
  toothPartClickHandler = (toothPartNumber, multiSelect) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(toothPartNumber, multiSelect);
    }
  }

  renderRoots = () => {
    const {
      mode,
      side,
      teeth,
      labelName,
      selectedTooth,
    } = this.props;

    const tooth = teeth && teeth[+labelName] ? teeth[+labelName] : null;

    switch (mode) {
      case 'TripleRoot':
        return <TripleRoot onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
      case 'SingleARoot':
        return <SingleARoot side={side} onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
      case 'SingleBRoot':
        return <SingleBRoot side={side} onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
      case 'DoubleARoot':
        return <DoubleARoot onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
      case 'DoubleBRoot':
        return <DoubleBRoot onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
      default:
        return null;
    }
  };

  renderCrown = () => {
    const {
      mode,
      teeth,
      labelName,
      selectedTooth,
    } = this.props;
    const tooth = teeth && teeth[+labelName] ? teeth[+labelName] : null;
    switch (mode) {
      case 'TripleRoot':
        return <CrownB onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth}/>;
      case 'SingleARoot':
        return <Crown onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
      case 'SingleBRoot':
        return <CrownC onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
      case 'DoubleARoot':
        return <Crown onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
      case 'DoubleBRoot':
        return <CrownB onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
      default:
        return <Crown onClick={this.toothPartClickHandler} tooth={tooth} labelName={labelName} selectedTooth={selectedTooth} />;
    }
  };


  crateSvgPatterns = () => {
    const { teeth, proceduresList } = this.props;

    if (!proceduresList || !proceduresList.length) {
      return null;
    }

    return proceduresList.map((item) => {
        const imagUrl = `${config.api}/${config.procedureApiImageDirectoryPath}/${item.id}.png`;
        return (
          <pattern
            key={item.id}
            id={`slaczek${item.id}`}
            patternUnits="userSpaceOnUse"
            width="70"
            height="70"
          >
            <image href={imagUrl} x="0" y="0" width="70" height="70"/>
          </pattern>
        );
    });
  }


  render() {
    const { height, width } = this.props;

    return (
      <div className="teeth-svg-model">
        <svg
          height={height}
          width={width - 10}
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 40 100"
        >
          <defs>
            {this.crateSvgPatterns()}
          </defs>
          {this.renderRoots()}
          {this.renderCrown()}
        </svg>
      </div>
    );
  }
}

export default TeethModel;
