import React from 'react';
import ToothClass from './ToothClass';

class CrownB extends ToothClass {
  render() {
    return (
      <g id="crown">
        <path
          d="m 36.104637,88.640452 c 2.193108,-0.83547 3.759614,-2.81971 3.759614,-5.221686 L 39.133214,71.72219 c 0,-0.83547 0,-1.67094 0,-2.401976 l 0.731037,-12.218745 c 0,-2.401976 -1.67094,-4.386217 -3.864048,-5.221686 -3.759614,5.430553 -5.743855,11.696576 -5.743855,18.380334 0,6.683759 1.984241,12.845348 5.848289,18.380335 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(4, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(4),
            strokeWidth: this.getStrokeWith(4),
          }}
        />
        <path
          d="M 4.1479183,51.775349 C 1.9548102,52.506385 0.28387064,54.490626 0.28387064,56.997035 L 1.0149067,67.335973 c 0.2088674,1.879807 0.2088674,3.864048 0,5.743855 L 0.28387064,83.314332 c 0,2.401976 1.67093956,4.386217 3.86404766,5.221686 3.7596139,-5.430553 5.9527217,-11.696576 5.9527217,-18.380334 0,-6.683758 -2.1931078,-12.740914 -5.9527217,-18.380335 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(5, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(5),
            strokeWidth: this.getStrokeWith(5),
          }}
        />
        <path
          d="M 5.8188578,88.84932 19.08194,88.118284 c 0.522169,0 1.148771,0 1.67094,0 l 13.680817,0.731036 c 0.522169,0 1.148771,-0.208868 1.67094,-0.208868 -3.864048,-5.639421 -5.952722,-11.696576 -5.952722,-18.484768 -0.417735,5.117252 -4.699518,9.085733 -9.921204,9.085733 -5.221686,0 -9.503468,-4.072915 -9.921203,-9.190167 0,0 -0.3133014,0 -0.3133014,0 0,6.683758 -2.1931081,12.740914 -5.952722,18.380335 0.6266023,0.313301 1.1487709,0.417735 1.7753732,0.417735 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(6, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(6),
            strokeWidth: this.getStrokeWith(6),
          }}
        />
        <path
          d="m 9.9962066,70.573419 h 0.2088674 c 0,-0.208868 0,-0.522169 0,-0.731036 0,-5.534988 4.49065,-10.025638 10.025637,-10.025638 5.534987,0 9.921204,4.386217 10.025637,9.921204 0.104434,-6.474891 2.193108,-12.323179 5.952722,-17.753733 -0.522168,-0.208867 -1.044337,-0.313301 -1.670939,-0.313301 l -12.532047,0.83547 c -1.566505,0.208867 -3.028577,0.208867 -4.386216,0 L 5.8188578,51.670915 c -0.5221686,0 -1.1487709,0.208868 -1.6709395,0.313301 3.6551802,5.534988 5.8482883,11.905445 5.8482883,18.589203 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(7, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(7),
            strokeWidth: this.getStrokeWith(7),
          }}
        />
        <circle
          cx="20.126278"
          cy="69.737953"
          r="10.130071"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(8, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(8),
            strokeWidth: this.getStrokeWith(8),
          }}
        />
      </g>
    );
  }
}

export default CrownB;
