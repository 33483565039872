import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TeethUpPart from './Teeth/TeethUpPart';
import TeethDownPart from './Teeth/TeethDownPart';


class JewTeethPart extends Component {
  static TEETH_PART_HEIGHT_RATIO = 0.30;

  static TEETH_TOP_RATIO = 1.88;

  static LEFT_FREE_SPACE = 0;

  render() {
    const {
      width,
      height,
      top,
      graphModeTeeth,
      onToothSelect,
      selectedTeeth,
      teeth,
      clickable,
      proceduresList,
    } = this.props;

    const style = {
      width,
      height,
      top,
    };

    const teethPartHeight = height * JewTeethPart.TEETH_PART_HEIGHT_RATIO;
    const teethPartHTop = (height / 2) - (teethPartHeight * JewTeethPart.TEETH_TOP_RATIO);

    return (
      <div
        className="jew-teeth-container"
        style={style}
      >
        <TeethUpPart
          proceduresList={proceduresList}
          height={teethPartHeight}
          width={width}
          teethPartHTop={teethPartHTop}
          graphModeTeeth={graphModeTeeth}
          onToothSelect={onToothSelect}
          selectedTeeth={selectedTeeth}
          teeth={teeth}
          clickable={clickable}
        />
        <TeethDownPart
          height={teethPartHeight}
          width={width}
          teethPartHBottom={teethPartHTop}
          graphModeTeeth={graphModeTeeth}
          onToothSelect={onToothSelect}
          selectedTeeth={selectedTeeth}
          teeth={teeth}
          clickable={clickable}
        />
      </div>
    );
  }
}

JewTeethPart.propTypes = {
  proceduresList: PropTypes.array,
  graphModeTeeth: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  top: PropTypes.number,
};

JewTeethPart.defaultProps = {
  graphModeTeeth: 'milky',
  width: 0,
  height: 0,
  top: 0,
  proceduresList: [],
};

export default JewTeethPart;
