import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import './style.css';

class IconButton extends Component {
  onClickHandler = (event) => {
    const { onClick } = this.props;
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  }

  tooltip = (tooltip) => (
    <Tooltip id={Math.random()} className="in">
      {tooltip}
    </Tooltip>
  );

  returnButton = () => {
    const { className, variant } = this.props;
    return (
      <Button
        {...this.props}
        onClick={this.onClickHandler}
        variant={variant || 'link'}
        className={`icon-button ${className || ''}`}
      >
        <span className="icon-wrapper">
          {this.props.children}
        </span>
      </Button>
    )
  }

  render() {
    const {
      tooltip,
    } = this.props;

    if (tooltip) {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={this.tooltip(tooltip)}
        >
          {this.returnButton()}
        </OverlayTrigger>
      );
    }

    return this.returnButton();
  }
}

export default IconButton;
