import React from 'react';
import { Component } from 'react';


class ToothClass extends Component {
  onClickHandler = (partNumber, multiSelect) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(partNumber, multiSelect);
    }
  };

  getRecognitionColor = (toothPartNumber) => {
    const { tooth } = this.props;

    if (!tooth) {
      return '#fff';
    }

    const colorProcedure = this.getColorFromProcedure(tooth, toothPartNumber);

    if (colorProcedure) {
      return colorProcedure;
    }

    const colorRecognition = this.getColorFromRecognition(tooth, toothPartNumber);

    if (colorRecognition) {
      return colorRecognition;
    }

    return '#fff';
  };

  getColorFromRecognition(tooth, toothPartNumber) {
    if (tooth.parts && tooth.parts[toothPartNumber] && tooth.parts[toothPartNumber].recognition) {
      return tooth.parts[toothPartNumber].recognition.color;
    }

    return false;
  }

  getColorFromProcedure(tooth, toothPartNumber) {
    if (tooth.parts
      && tooth.parts[toothPartNumber]
      && tooth.parts[toothPartNumber].procedure
      && tooth.parts[toothPartNumber].procedure.procedureId > 0
    ) {
      return `url(#slaczek${tooth.parts[toothPartNumber].procedure.procedureId})`;
    }

    return false;
  }

  getStrokeWith = (toothPartNumber) => {
    const { labelName, selectedTooth } = this.props;

    if (!selectedTooth || selectedTooth.toothNumber !== +labelName) {
      return 0.25;
    }

    if (selectedTooth.parts && selectedTooth.parts[+toothPartNumber]) {
      return 1.25;
    }

    return 0.25;
  }
}

export default ToothClass;
