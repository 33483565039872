import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JewTeethPart from '../JewTeethPart';
import TeethTemplateDown from './TeethTemplateDown';


class TeethDownPart extends Component {
  render() {
    const {
      width,
      height,
      teethPartHBottom,
      graphModeTeeth,
      onToothSelect,
      selectedTeeth,
      teeth,
      clickable,
    } = this.props;



    const heightOfTeethTemplate = height - teethPartHBottom;
    const widthOfTeethTemplate = width - JewTeethPart.LEFT_FREE_SPACE;

    const style = {
      width,
      height,
    };

    const style2 = {
      bottom: heightOfTeethTemplate * 0.29,
      height: heightOfTeethTemplate,
      width: widthOfTeethTemplate,
      left: JewTeethPart.LEFT_FREE_SPACE,
    };

    return (
      <div
        className="jew-teeth-down-container"
        style={style}
      >
        <div
          className="jew-teeth-down-container-teethes"
          style={style2}
        >
          <TeethTemplateDown
            height={heightOfTeethTemplate}
            width={widthOfTeethTemplate}
            graphModeTeeth={graphModeTeeth}
            onToothSelect={onToothSelect}
            selectedTeeth={selectedTeeth}
            teeth={teeth}
            clickable={clickable}
          />
        </div>
      </div>
    );
  }
}

TeethDownPart.propTypes = {
  graphModeTeeth: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  teethPartHBottom: PropTypes.number,
};

TeethDownPart.defaultProps = {
  graphModeTeeth: 'milky',
  width: 0,
  height: 0,
  teethPartHBottom: 0,
};


export default TeethDownPart;
