import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Col, Row, Jumbotron } from 'react-bootstrap';

import PersonalData from './Card/PersonalData';
import TeethDiagram from './Card/TeethDiagram';
import Scans from './Card/Scans';
import Forms from './Card/Forms';
import History from './Card/History';
import Visit from './Visit';


class PatientCard extends Component {
  render() {
    const { patient } = this.props;

    return (
      <div>
        <Jumbotron className="form-container patient-jumbo">
          <Row>
            <Col xs={12}>
              <Switch>
                <Route exac path="/panel/patients/patient/:patientId/card/personal-data" component={PersonalData} />
                <Route exac path="/panel/patients/patient/:patientId/card/diagram" component={TeethDiagram} />
                <Route path="/panel/patients/patient/:patientId/card/scans" component={Scans} />
                <Route exac path="/panel/patients/patient/:patientId/card/forms" component={Forms} />
                <Route exac path="/panel/patients/patient/:patientId/card/history" component={History} />
                <Route exac path="/panel/patients/patient/:patientId/card/visit/new/:scheduleId" component={Visit} />
                <Route exac path="/panel/patients/patient/:patientId/card/visit" component={Visit} />
                <Route exac path="/panel/patients/patient/:patientId/card" component={PersonalData} />
              </Switch>
            </Col>
          </Row>
        </Jumbotron>
      </div >
    );
  }
}

export default PatientCard;
