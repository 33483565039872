import React, { Component } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';


class SaveButton extends Component {
  text = () => {
    const { text } = this.props;
    if (text) {
      return text;
    }
    return 'Zapisz';
  };

  tooltip = (tooltip) => (
    <Tooltip id={Math.random()} className="in">
      {tooltip}
    </Tooltip>
  );


  showText = () => {
    const { text, replace, isLoading } = this.props;
    const showText = text || 'Zapisz';
    if (isLoading) {
      return replace ? this.showLoader() : <span>{showText} {this.showLoader()}</span>;
    }

    return showText;
  };

  showLoader = () => <i className="fa fa-spinner fa-spin" aria-hidden="true" />;

  renderButton = () => {
    const {
      size,
      onSave,
      type,
      block,
      disabled,
      isLoading,
      variant,
    } = this.props;
    const style = variant || 'success';
    return (
      <Button
        disabled={disabled || isLoading}
        variant={style}
        size={size}
        type={type || 'submit'}
        block={block || false}
        onClick={() => onSave()}
      >
        {this.showText()}
      </Button>
    );
  }

  render() {
    const {
      tooltip,
      disabled,
      isLoading
    } = this.props;

    if (tooltip && (!disabled && !isLoading)) {
      return (
        <OverlayTrigger
          defaultShow={false}
          placement="bottom"
          overlay={this.tooltip(tooltip)}
          delay={0}
          flip={false}
          onHide={() => { }}
          onToggle={() => { }}
          popperConfig={{}}
          target={null}
          trigger={null}
        >
          {this.renderButton()}
        </OverlayTrigger>
      );
    }

    return this.renderButton();
  }
}

export default SaveButton;
