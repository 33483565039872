import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import Row from './Row';

class TableItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isAllSelected,
    };
  }

  onSelectAllHandler = (isChecked) => {
    this.setState({
      isChecked,
    });
  };

  additionalClumns = () => {
    let add = 0;
    add += (this.props.selectAll ? 1 : 0);
    add += (this.props.buttons && this.props.buttons.length ? 1 : 0);
    return add;
  };

  showItems = () => {
    if (this.props.items.length === 0) {
      return (<tr>
        <td colSpan={this.props.columns.length + this.additionalClumns()}>
          <Alert variant="info">
            Nie ma nic do pokazania
          </Alert>
        </td>
      </tr>);
    }
    return this.props.items.map(rowItem => (<Row
      key={rowItem.id}
      isChecked={this.props.isAllSelected}
      selectAll={this.props.selectAll}
      onSelect={this.props.onSelect}
      columns={this.props.columns}
      rowItem={rowItem}
      buttons={this.props.buttons}
      onRowStyle={this.props.onRowStyle}
      onRowClick={this.props.onRowClick}
    />));
  };

  render() {
    return (
      <tbody>
        {this.showItems()}
      </tbody>
    );
  }
}
export default TableItems;
