import React from 'react';
import PropTypes from 'prop-types';
import TeethWrapper from './TeethWrapper';
import TeethModel from './TeethModel';
import TeethTemplateClass from './TeethTemplateClass';

class TeethTemplateDown extends TeethTemplateClass {
  render() {
    const {
      width,
      height,
      graphModeTeeth,
      selectedTeeth,
      teeth,
    } = this.props;
    const teethWrapperWidth = width / 16;
    return (
      <div className="teeth-template teeth-template-down">
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={8}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="DoubleBRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={7}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="DoubleBRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={6}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="DoubleBRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={5}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            side="left"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={4}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            side="left"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={3}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            side="left"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={2}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleBRoot"
            side="left"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={1}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleBRoot"
            side="left"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={1}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleBRoot"
            side="right"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={2}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleBRoot"
            side="right"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={3}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            side="right"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={4}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={5}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            side="right"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={6}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="DoubleBRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={7}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="DoubleBRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          part="down"
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={8}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            width={teethWrapperWidth}
            height={height}
            mode="DoubleBRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
      </div>
    );
  }
}

TeethTemplateDown.propTypes = {
  graphModeTeeth: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

TeethTemplateDown.defaultProps = {
  graphModeTeeth: 'milky',
  width: 0,
  height: 0,
};


export default TeethTemplateDown;
