import MD5 from "crypto-js/md5";

class StringFunction {

  static md5 (string) {
    return MD5(string).toString();
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

}

export default StringFunction;
