import React, { Component } from 'react';
import connect from 'react-redux/lib/connect/connect';
import RegisterForm from '../RegisterForm';
import PatientAction from '../../../actions/PatientAction';


class PersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsLoading: false,
    };
  }

  onSubmitHandler = (registerForm) => {
    const { patientState } = this.props;
    this.setState({
      formIsLoading: true,
    })
    if (registerForm.isValid) {
      this.setState({ formIsLoading: true });
      PatientAction.promiseUpdatePatient(patientState.patient.id, registerForm).then((data) => {
        this.setState({ formIsLoading: false });
        if (data !== undefined) {
          PatientAction.getPatient(patientState.patient.id);

        }
      }).finally(() => {
        this.setState({
          formIsLoading: false,
        });
      });
    }
  };

  render() {
    const { patientState } = this.props;
    const { formIsLoading } = this.state;
    return (
      <div>
        <RegisterForm
          patient={patientState.patient}
          formIsLoading={formIsLoading}
          onSubmit={this.onSubmitHandler}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  patientState: { ...state.patient },
});

export default connect(mapStateToProps)(PersonalData);
