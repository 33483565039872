import React from 'react';
import PropTypes from 'prop-types';
import TeethWrapper from './TeethWrapper';
import TeethModel from './TeethModel';
import TeethTemplateClass from './TeethTemplateClass';

class TeethTemplateUp extends TeethTemplateClass {
  render() {
    const {
      width,
      height,
      graphModeTeeth,
      selectedTeeth,
      teeth,
      proceduresList,
    } = this.props;
    const teethWrapperWidth = width / 16;

    return (
      <div className="teeth-template teeth-template-up">
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={8}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="TripleRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={7}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="TripleRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={6}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="TripleRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={5}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            side="left"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={4}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="DoubleARoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={3}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            side="left"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="left"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={2}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="SingleBRoot"
            side="left"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="left"
          viewMode={graphModeTeeth}
          teethNumber={1}
          selectedTeeth={selectedTeeth}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="SingleBRoot"
            side="left"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="right"
          viewMode={graphModeTeeth}
          teethNumber={1}
          selectedTeeth={selectedTeeth}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="SingleBRoot"
            side="right"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={2}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="SingleBRoot"
            side="right"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={3}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            side="right"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={4}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="DoubleARoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          proceduresList={proceduresList}
          width={teethWrapperWidth}
          height={height}
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={5}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="SingleARoot"
            side="right"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={6}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="TripleRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={7}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="TripleRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
        <TeethWrapper
          width={teethWrapperWidth}
          height={height}
          direction="right"
          viewMode={graphModeTeeth}
          selectedTeeth={selectedTeeth}
          teethNumber={8}
          onClick={this.toothClickHandler}
          onLabelClick={this.toothLabelClickHandler}
        >
          <TeethModel
            proceduresList={proceduresList}
            width={teethWrapperWidth}
            height={height}
            mode="TripleRoot"
            onClick={this.toothPartClickHandler}
            teeth={teeth}
          />
        </TeethWrapper>
      </div>
    );
  }
}

TeethTemplateUp.propTypes = {
  graphModeTeeth: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  proceduresList: PropTypes.array,
};

TeethTemplateUp.defaultProps = {
  graphModeTeeth: 'milky',
  width: 0,
  height: 0,
  proceduresList: [],
};


export default TeethTemplateUp;
