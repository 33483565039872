class AbstractModel {
  getModel() {
    const model = {};
    const propsNames = Object.getOwnPropertyNames(this);

    propsNames.forEach((propName) => {
      const niceName = propName.replace('_', '');
      model[niceName] = this[propName];
    });
    return model;
  }
}

export default AbstractModel;
