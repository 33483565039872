import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { Alert, Jumbotron, Row, Col, Container } from 'react-bootstrap';
import Input from '../../components/Inputs/Input';
import SaveButton from '../../components/SaveButton';
import UserAction from '../../actions/UserAction';
import LogoNavbar from '../../components/Menu/LogoNavbar';
import './style.css';


class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      showAlert: '', // danger, success
      alertTitle: '',
      isLoading: false,
    };
  }

  showAlert = () => {
    const { showAlert, alertTitle } = this.state;
    if (!showAlert) {
      return null;
    }

    return (
      <Alert variant={showAlert}>
        <h4>{alertTitle}</h4>
      </Alert>
    );
  }

  onElementChange = (value) => {
    this.setState({ email: value });
  };

  onSave = (e) => {
    e.preventDefault();
    const { email } = this.state;
    this.setState({
      isLoading: true,
    });

    UserAction.promiseSendResetPasswordToken(email).then((data) => {
      if (!data) {
        this.setState({
          showAlert: 'danger',
          alertTitle: 'Adres email jest nieprawidlowy',
          isLoading: false,
        });
        return;
      }
      this.setState({
        showAlert: 'success',
        alertTitle: 'Link do resetu hasła został wysłany na email',
        isLoading: false,
        email: '',
      });
    })
      .catch(() => {
        this.setState({
          showAlert: 'danger',
          alertTitle: 'Adres email jest nieprawidlowy',
          isLoading: false,
        });
      });
    return false;
  };

  render() {
    const { email, isLoading } = this.state;

    return (
      <Container>
        <LogoNavbar />

        <Jumbotron className="forgot-password">
          <h1>Resetowanie hasła</h1>
          <Col sm={{ span: 12, offset: 0 }} md={{ span: 8, offset: 2 }}>
            <p>Wpisz swój adres e-mail, który był przez Ciebie użyty do rejestracji.
              Wyślemy do Ciebie wiadomość e-mail z łączem umożliwiającym zresetowanie hasła.</p>
          </Col>
          <form
            onSubmit={e => this.onSave(e)}
          >
            <Row>

              <Col sm={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }}>
                <Input
                  inputElement={{
                    label: 'Adres e-mail',
                    value: email,
                  }}
                  onChange={value => this.onElementChange(value)}
                />
              </Col>
            </Row>
            <Row>

              <Col sm={{ span: 6, offset: 6 }} md={{ span: 6, offset: 4 }}>
                <SaveButton
                  isLoading={isLoading}
                  onSave={() => { }}
                  text="Wyślij"
                />
              </Col>
            </Row>
          </form>
          {this.showAlert()}
        </Jumbotron>

      </Container >
    );
  }
}

const mapStateToProps = state => ({
  login: { ...state.login },
});

export default connect(mapStateToProps)(ForgotPassword);
