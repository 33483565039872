import React from 'react';
import ToothClass from './ToothClass';

class SingleARoot extends ToothClass {
  render() {
    const { side } = this.props;
    let transform = null;
    if (side === 'left') {
      transform = 'scale(-1, 1) translate(-40, 0)';
    }
    return (
      <g id="roots">
        <path
          d="m 24.631631,53.0931 c -2.998759,0.181743 -5.906645,0.181743 -8.814533,0 L 7.4569242,52.547871 C 9.4560962,48.004297 12.727468,41.37068 12.818339,30.82959 13.181825,15.017954 19.724571,4.1133778 19.997186,3.4772778 c 3.271373,-7.45146 4.725316,-0.999586 5.270545,4.543573 2.544401,28.7153842 2.998758,31.5324002 3.907473,34.8037722 1.090457,3.362245 2.362658,7.36059 3.362244,9.723248 L 24.631631,53.0931 Z"
          className="svg-teeth-default"
          transform={transform}
          onClick={(event) => this.onClickHandler(1, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(1),
            strokeWidth: this.getStrokeWith(1),
          }}
        />
      </g>
    );
  }
}

export default SingleARoot;
