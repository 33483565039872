import React, { Component } from 'react';
import SaveButton from '../SaveButton';
import './element-overall.css';

class ElementOverall extends Component {
  onClickCallback = () => {
    const { onClick } = this.props;

    if (typeof onClick === 'function') {
      onClick();
    }
  }

  render() {
    const { buttonText, isLoading, zIndex } = this.props;
    return (
      <div
        className="element-overall"
        style={{ zIndex }}
      >
        <SaveButton
          variant="primary"
          bsSize="large"
          onSave={this.onClickCallback}
          text={buttonText}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default ElementOverall;
