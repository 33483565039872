import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import Jew from './Jew';

import './style.css';


class JewRender extends Component {
  static JEW_RENDER_MODE_MILKY = 'milky';

  static JEW_RENDER_MODE_PERMANENT = 'permanent';

  jewMainContainer = null;

  constructor(props) {
    super(props);
    this.jewMainContainer = React.createRef();
    this.state = {
      jewMainContainer: {
        width: 0,
        height: 0,
      },
    };
  }

  onContainerLoad = (node) => {
    if (node) {
      this.jewMainContainer = node;
      const resizeObserver = new ResizeObserver((entries) => {

        entries.forEach((entry) => {
          this.setState({
            jewMainContainer: {
              width: entry.contentRect.width,
              height: entry.contentRect.width / 2.2,
            }
          });
        });
      });
      resizeObserver.observe(node);
    }
  }

  onToothSelectHandler = (toothNumber, toothNumberPartNumber, multiSelect) => {
    const { onToothSelect } = this.props;
    if (onToothSelect) {
      onToothSelect(toothNumber, toothNumberPartNumber, multiSelect);
    }
  }

  mainRender() {
    const { jewMainContainer } = this.state;
    if (jewMainContainer.width === 0 && jewMainContainer.height === 0) {
      return <Loader />;
    }

    const {
      proceduresList,
      graphModeTeeth,
      selectedTeeth,
      teeth,
      clickable,
    } = this.props;

    return (
      <Jew
        jewContainer={jewMainContainer}
        proceduresList={proceduresList}
        graphModeTeeth={graphModeTeeth}
        selectedTeeth={selectedTeeth}
        onToothSelect={this.onToothSelectHandler}
        teeth={teeth}
        clickable={clickable}
      />
    );
  }

  render() {
    return (
      <div
        ref={this.onContainerLoad}
        className="jew-main-container"
      >
        {this.mainRender()}
      </div>
    );
  }
}

JewRender.propTypes = {
  graphModeTeeth: PropTypes.string,
  proceduresList: PropTypes.array,
  selectedTeeth: PropTypes.object,
};

JewRender.defaultProps = {
  graphModeTeeth: JewRender.JEW_RENDER_MODE_MILKY,
  proceduresList: [],
  selectedTeeth: {},
};

export default JewRender;
