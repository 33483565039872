import React from 'react';
import ToothClass from './ToothClass';

class DoubleARoot extends ToothClass {
  render() {
    return (
      <g id="roots">
        <path
          d="m 31.389117,52.554442 -6.557978,0.449177 c -2.87473,0.179671 -5.659624,0.179671 -8.534354,0 L 8.7506192,52.554442 c 1.4373648,-1.886541 3.3239068,-4.5816 3.7730828,-7.546166 1.078024,-6.737648 5.479954,-32.071204 5.210448,-37.5511583 -0.269506,-5.6596244 1.5272,-11.2294135 4.581601,-3.6832476 0.269506,0.7186825 6.108801,9.0733659 5.479953,26.8607569 -0.359341,10.600567 1.886542,17.338215 3.593413,21.919815 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(1, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(1),
            strokeWidth: this.getStrokeWith(1),
          }}
        />
        <path
          d="m 24.831139,53.003619 c -2.87473,0.179671 -5.659624,0.179671 -8.534354,0 L 8.6607839,52.464607 C 9.9184782,47.793171 11.894855,42.133547 10.637161,31.173639 8.6607839,15.362625 10.996502,4.5823879 11.086337,3.8637054 c 2.156048,-7.8156718 3.683248,-1.7068708 4.132424,3.9527536 0.449177,5.569789 7.366496,30.364334 9.253037,36.922312 0.988188,3.323906 3.413742,6.1088 4.940942,7.905507 z"
          className="svg-teeth-default"
          onClick={(event) => this.onClickHandler(2, event.ctrlKey)}
          style={{
            fill: this.getRecognitionColor(2),
            strokeWidth: this.getStrokeWith(2),
          }}
        />
      </g>
    );
  }
}

export default DoubleARoot;
