
class TeethManager {

  createEmptyToothWithNumber(toothNumber) {
    return {
      toothNumber,
      parts: {},
      tootRecognition: {},
      updated: false,
    }
  }
}

export default TeethManager;
