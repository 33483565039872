import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import '../custom-toolbar.css';
import ClinicAction from '../../../actions/ClinicAction';
import DropdownWrapper from './DropdownWrapper';

class CustomButtonSelectClinic extends Component {
  constructor(props) {
    super(props);

    this.state = {
        show: false,
    };
  }

  closeDropdown = () => {
    this.setState({
      show: false,
    });
  };

  handleClick = () => {
    this.setState({
      show: true,
    });
  };

  onSelectClinic = (clinic) => {
    ClinicAction.changeUserCurrentClinic(clinic);
    this.closeDropdown();
  };

  render() {
    const {show} = this.state;
    const { userClinics: { userAllClinics, selectedClinic } } = this.props;

    if (userAllClinics.length === 0) {
      return (
          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
      );
    }


    return (
      <span
        className="calendar-toolbar-custom-button-select"
        onClick={this.handleClick}
      >
        Klinika:
        <span>{selectedClinic.name}</span>
        <i className="fas fa-caret-down" />
        <DropdownWrapper
          onClickOutside={this.closeDropdown}
          show={show}
        >
          {userAllClinics.map(clinicView => (
            <Dropdown.Item
              as={'li'}
              eventKey={clinicView.id}
              key={clinicView.id}
              onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
              onSelect={() => this.onSelectClinic(clinicView)}
            >
              <i className="fas fa-clinic-medical" />
              &nbsp;
              &nbsp;
              {clinicView.name}
            </Dropdown.Item>
          ))}
        </DropdownWrapper>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  userClinics: state.userClinics,
  user: state.user,
});


export default connect(mapStateToProps)(CustomButtonSelectClinic);
