import React, { Component } from 'react';
import IconButton from '../Button/IconButton';


class SaveIcon extends Component {
  showText = () => {
    const { isLoading } = this.props;
    return isLoading ? this.showLoader() : this.props.children;
  };

  showLoader = () => <i className="fa fa-spinner fa-spin" aria-hidden="true" />;

  renderButton = () => {
    const {
      size,
      onSave,
      disabled,
      isLoading,
      variant,
      tooltip
    } = this.props;
    const style = variant || 'primary';
    return (
      <IconButton
        disabled={disabled || isLoading}
        variant={style}
        size={size}
        onClick={() => onSave()}
        tooltip={tooltip}
      >
        {this.showText()}
      </IconButton>
    );
  }

  render() {
    return this.renderButton();
  }
}

export default SaveIcon;
