import React, { Component } from 'react';
import LinkButton from '../Button/LinkButton';
import './style.css';

class TitleHeader extends Component {
  showReturnButton = () => {
    const { returnButton } = this.props;
    if (!returnButton) {
      return null;
    }
    return (
      <LinkButton
        to={returnButton}
        className="back-button"
        style={{ backgroundColor: "#4098ff", borderColor: "#4098ff", color: "#fff" }}
      >
        <i className="fas fa-long-arrow-alt-left" />
      </LinkButton>
    );
  }

  render() {
    return (
      <h1>
        {this.props.children}
        {this.showReturnButton()}
      </h1>
    );
  }
}

export default TitleHeader;
