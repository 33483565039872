import BaseAction from './BaseAction';

class PatientAction extends BaseAction {
  classPrefix = 'Patient';

  getPatient = (patientId) => {
    const url = `/api/patient/${patientId}/full-info`;
    this.request('get', url);
  };

  promiseGetAllMyPatients = (data) => {
    const url = '/api/patient/get-all-patients-for-doctor';
    return this.promiseDoRequest('get', url, data);
  };

  promiseAddAttachments = (patientId, attachmentTypeName, temporaryFilesId) => {
    const url = `/api/patient/${patientId}/add/attachments`;

    const data = {
      temporaryFilesId,
      attachmentTypeName,
    };

    return this.promiseDoRequest(BaseAction.METHOD_POST, url, data);
  }

  promiseUpdatePatient = (patientId, registerFormData) => {
    const url = `/api/patient/${patientId}?form=RegisterPatient`;
    const data = {
      patientId: patientId,
      name: registerFormData.fields.patientName.value,
      lastName: registerFormData.fields.patientLastName.value,
      personId: registerFormData.fields.patientPersonalId.value,
      birthDate: registerFormData.fields.patientBirthDate.value,
      sex: registerFormData.fields.patientSex.value,
      phoneNumber: registerFormData.fields.patientPhone.value,
      email: registerFormData.fields.patientEmail.value,
      optionalPhoneNumber: registerFormData.fields.optionalPhoneNumber.value,
    };

    let guardian = null;

    if (registerFormData.fields.guardianIsNeeded.value) {
      guardian = {
        patientId: +registerFormData.fields.guardianUserId.value,
        name: registerFormData.fields.guardianName.value,
        lastName: registerFormData.fields.guardianLastName.value,
        phoneNumber: registerFormData.fields.guardianPhone.value,
        email: registerFormData.fields.guardianEmail.value,
      };
    }

    data.guardian = guardian;

    return this.promiseDoRequest('put', url, data, true);
  }

  promiseGetAttachments = ($patientId, $attachmentTypeName) => {
    const url = `/api/patient/${$patientId}/attachments/${$attachmentTypeName}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseDeleteAttachment = ($patientId, $attachmentId) => {
    const url = `/api/patient/${$patientId}/attachments/${$attachmentId}`;
    return this.promiseDoRequest(BaseAction.METHOD_DELETE, url);
  }
}

export default new PatientAction();
