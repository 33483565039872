import { combineReducers } from 'redux';
import notifications from './notifications';
import onlineSelect from './onlineSelect';
import forms from './forms';
import register from './register';
import login from './login';
import user from './user';
import patient from './patient';
import schedule from './schedule';
import userClinics from './userClinics';
import userWorkingDay from './userWorkingDay';
import visit from './visit';
import confirmDialog from './confirmDialog';
import calender from './calender';

export default combineReducers({
  notifications,
  calender,
  onlineSelect,
  forms,
  register,
  login,
  user,
  patient,
  schedule,
  userClinics,
  userWorkingDay,
  visit,
  confirmDialog,
});
