import React, { Component } from 'react';
import scriptLoader from 'react-async-script-loader';
import SearchBar from './SearchBar';
import './style.css';

// lng - szerokosc
// lat - dlugosc

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      googleMapDivStyle: { width: '100%' },
    };
  }

  componentWillReceiveProps({ isScriptLoadSucceed }) {
    if (isScriptLoadSucceed && !this.googlemap) {
      const bounding = this.mapDiv.getBoundingClientRect();
      this.setState({
        googleMapDivStyle: {
          width: bounding.width,
          height: bounding.width * (this.props.proportion / 100),
        },
      });
      const defaultMapState = {
        zoom: 8,
        center: { lat: 52.0475632, lng: 19.6643693 },

        disableDefaultUI: true,
      };

      const mapDivWrapper = document.createElement('DIV');
      mapDivWrapper.setAttribute('style', `width: ${bounding.width}px;height:${bounding.width * (this.props.proportion / 100)}px`);
      this.mapDiv.appendChild(mapDivWrapper);
      this.googlemap = new window.google.maps.Map(mapDivWrapper, defaultMapState);
    }

    const { markers } = this.props;

    if (markers && markers.length > 0) {
      const latLngBounds = new window.google.maps.LatLngBounds();
      const newMarkers = [];
      markers.forEach((item) => {
        latLngBounds.extend(item);
        const m = new window.google.maps.Marker({
          position: item,
          map: this.googlemap,
        });
        newMarkers.push(m);
      });

      new window.MarkerClusterer(this.googlemap, newMarkers, { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });

      this.googlemap.setCenter(latLngBounds.getCenter());
      this.googlemap.fitBounds(latLngBounds);
    }
  }

  onSelectHandler = (item) => {
    this.googlemap.setCenter(item.geometry.location);
    if (this.marker) {
      this.marker.setMap(null);
    }
    this.marker = new window.google.maps.Marker({
      map: this.googlemap,
      position: item.geometry.location,
    });
    this.props.onSelect(item);
    const latlngbounds = new window.google.maps.LatLngBounds();
    latlngbounds.extend(item.geometry.location);
    this.googlemap.setCenter(latlngbounds.getCenter());
    this.googlemap.fitBounds(latlngbounds);
  };

  showSearchBar = () => {
    const { searchbar } = this.props;
    if (searchbar && this.state.googleMapDivStyle.height) {
      if (!this.geocoder) {
        this.geocoder = new window.google.maps.Geocoder();
      }

      return <SearchBar geocoder={this.geocoder} onSelect={this.onSelectHandler} />;
    }
    return '';
  };

  render() {
    return (
      <div className="google-map-wrapper">
        <div ref={(mapDiv) => { this.mapDiv = mapDiv; }} style={this.state.googleMapDivStyle} />
        {this.showSearchBar()}
      </div>
    );
  }
}

export default scriptLoader(
  ['https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js'],
  ['https://maps.googleapis.com/maps/api/js?key=AIzaSyAjIhFd0ELJ67D0NuUM1_Yqw7daLlRtqTw'],
)(GoogleMap);
