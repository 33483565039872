import React, { Component } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import InputMessages from '../../containers/FormWizard/InputMessages';

class Checkbox extends Component {
  constructor(props) {
    super(props);

    const { inputElement } = this.props;

    this.state = {
      checked: inputElement.value,
    };
  }

  onChangeHandler = (value) => {
    this.props.onChange(value);
    this.setState({ checked: value });
  };

  render() {
    const { inputElement } = this.props;
    const {
      validationMessages,
      label,
    } = inputElement;
    const { checked } = this.state;
    return (
      <Form.Group>
        <Form.Check
          checked={checked}
          onChange={e => (this.onChangeHandler(e.target.checked))}
          {...inputElement.attributes}
          label={label}
        />
        <InputMessages messages={validationMessages} />
      </Form.Group>
    );
  }
}

export default Checkbox;
