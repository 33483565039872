import moment from 'moment';


class DateTime {
  static FORMAT_DATE = 'YYYY-MM-DD';
  static FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm';

  static stringToMoment(value) {
    if (!value) {
      return null;
    }

    const splitTime = DateTime.splitTime(value);

    const m = moment();
    m.set({
      hour: splitTime.hours,
      minute: splitTime.minutes,
    });

    return m;
  }

  static dateTimeStringToDateString(stringDateTime) {
    return stringDateTime.split(' ')[0];
  }

  static dateTimeStringToDateReverseString(stringDateTime) {
    const dateString = stringDateTime.split(' ')[0];
    const dateArray = dateString.split('-').reverse();
    return dateArray.join('-');
  }

  static getDayName(dateString) {
    return new Date(dateString.replace(/ /g,"T")).toLocaleString('pl-pl', {weekday:'long'});
  }

  static phpDateTimeStringToDateTimeString(stringDateTime) {
    const splitDate = stringDateTime.split('T');
    const date = splitDate[0]
    const splitTime = splitDate[1].split('+');
    return `${date} ${splitTime[0]}`;
  }

  static dateTimeStringToTimeString(stringDateTime, sowSeconds) {
    const timeString = stringDateTime.split(' ')[1];
    if (!sowSeconds) {
      const splittedTime = DateTime.splitTime(timeString);
      return `${splittedTime.hours}:${splittedTime.minutes}`;
    }
    return timeString;
  }

  /**
   * @param stringTime
   */
  static splitTime(stringTime) {
    if (!stringTime) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const array = stringTime.split(':');
    return {
      hours: array[0],
      minutes: array[1],
      seconds: array[2],
    };
  }

  static showTimeFormTimeString(stringTime) {
    const time = DateTime.splitTime(stringTime);
    return `${time.hours}:${time.minutes}`;
  }

  static dateToTimeString(date)
  {
    let h = +date.getHours();
    if (h < 10) {
      h = `0${h}`;
    }

    let m = +date.getMinutes();

    if (m < 10) {
      m = `0${m}`;
    }

    return `${h}:${m}`;
  }

  static dateToDateString(date) {
    if (!date) {
      return undefined;
    }
    return moment(date).format(DateTime.FORMAT_DATE);
  }

  static dateToDateTimeString(date) {
    return moment(date).format(DateTime.FORMAT_DATE_TIME);
  }

  static showTimeFromDateTimeString(stringDateTime) {
    const dateTimeSplit = stringDateTime.split(' ');
    return DateTime.showTimeFormTimeString(dateTimeSplit[1]);
  }

  static setTimeOnDateFromTimeString(dateObject, timeString) {
    const time = DateTime.splitTime(timeString);
    dateObject.setHours(time.hours, time.minutes, time.seconds);
    return dateObject;
  }

  static momentStartOfWeek(momentDate) {
    return moment(momentDate).startOf('week').isoWeekday(1);
  }

  static momentEndOfWeek(momentDate) {
    return moment(momentDate).startOf('week').isoWeekday(1).add(7, 'days');
  }

  static momentStartOfMonth(momentDate) {
    return moment(momentDate).startOf('month').isoWeekday(1);
  }

  static momentEndOfMonth(momentDate) {
    return moment(momentDate).endOf('month').isoWeekday(7);
  }
}

export default DateTime;
