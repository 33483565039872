import BaseAction from './BaseAction';

class UserAction extends BaseAction {
  classPrefix = 'User';

  getSelf = () => {
    const url = '/api/user/self';
    this.request('get', url);
  };

  getUser = (userId) => {
    const url = `/api/user/get-user/${userId}`;
    this.request('get', url);
  };

  changeSelectedUser = (user) => {
    this.makeDispatch('USER_ACTION_CHANGE_SELECTED_USER', user);
  };

  changeSelectedUserWorkingDays = (workingDaysCollection) => {
    this.makeDispatch('USER_ACTION_CHANGE_SELECTED_USER_WORKING_DAYS', workingDaysCollection);
  };

  promiseGetNotifications = () => {
    const url = `/api/user/notifications`;
    return this.promiseDoRequest('get', url);
  };

  promiseMarkNotificationsAsRead = (notificationId) => {
    const url = `/api/user/notifications/mark-as-read/${notificationId}`;
    return this.promiseDoRequest('get', url);
  };

  promiseGetNotificationsSettings = () => {
    const url = `/api/user/notifications/settings`;
    return this.promiseDoRequest('get', url);
  };

  promiseUpdateNotificationsSettings = (notificationType, enabled) => {
    const url = `/api/user/notifications/settings`;

    const data = {
      notificationType,
      enabled,
    }

    return this.promiseDoRequest('patch', url, data);
  };

  promiseGetUser = (userId) => {
    const url = `/api/user/get-user/${userId}`;
    return this.promiseDoRequest('get', url);
  };

  /**
   * @todo move to patient
   */
  promiseSearchPatient = (query, name, lastName, personId, email, type) => {
    const url = '/api/patient/search';

    const data = {
      query,
      name,
      lastName,
      personId,
      email,
      type,
    };

    return this.promiseDoRequest('get', url, data);
  };

  /**
   * @todo move to patient
   */
  promiseRegisterPatient = (scheduleEventId, officeWorkerId, registerFormData, scheduledEventClinicId) => {

    const clinicId = scheduledEventClinicId || registerFormData.fields.rootClinicId.value;

    const url = `/api/patient/register?form=RegisterPatient&scheduleEventId=${scheduleEventId}&officeWorkerId=${officeWorkerId}&clinicId=${clinicId}`;
    const data = {
      patientId: 0,
      name: registerFormData.fields.patientName.value,
      lastName: registerFormData.fields.patientLastName.value,
      personId: registerFormData.fields.patientPersonalId.value,
      birthDate: registerFormData.fields.patientBirthDate.value,
      sex: registerFormData.fields.patientSex.value,
      phoneNumber: registerFormData.fields.patientPhone.value,
      email: registerFormData.fields.patientEmail.value,
      optionalPhoneNumber: registerFormData.fields.optionalPhoneNumber.value,
    };

    let guardian = null;

    if (registerFormData.fields.guardianIsNeeded.value) {
      guardian = {
        patientId: +registerFormData.fields.guardianUserId.value,
        name: registerFormData.fields.guardianName.value,
        lastName: registerFormData.fields.guardianLastName.value,
        phoneNumber: registerFormData.fields.guardianPhone.value,
        email: registerFormData.fields.guardianEmail.value,
      };
    }

    data.guardian = guardian;

    return this.promiseDoRequest('post', url, data, true);
  };

  promiseSendResetPasswordToken = (email) => {
    const url = '/api/user/send-token-password';
    const data = {
      email,
    };
    return this.promiseDoRequest(BaseAction.METHOD_POST, url, data);
  }

  promiseChangeUserPasswordToken = (token, password) => {
    const url = `/api/user/reset-user-password/${token}`;
    const data = {
      password,
    };
    return this.promiseDoRequest(BaseAction.METHOD_POST, url, data);
  }

  promiseInviteUser = (userEmail, clinicId, userType) => {
    const url = '/api/user/send-invitation';
    const data = {
      userEmail,
      clinicId,
      userType,
    };
    return this.promiseDoRequest(BaseAction.METHOD_POST, url, data);
  }

  promiseGetInvitation = (invitationToken) => {
    const url = `/web/invitation/${invitationToken}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseGetPatientDetails = (patientId) => {
    const url = `/api/doctor/patient-details/${patientId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseGetSmsReport = (patientId) => {
    const url = '/api/user/report/sms-report';
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseRegisterUserFromInvitation = (token, registerFormData) => {
    const url = `/api/register/register-form-invitation/${token}`;

    const data = {
      name: registerFormData.fields.name.value,
      lastName: registerFormData.fields.lastName.value,
      phone: registerFormData.fields.phoneNumber.value,
      email: registerFormData.fields.email.value,
      password: registerFormData.fields.password.value,
      type: {
        userType: registerFormData.fields.type.value,
      },
    };

    return this.promiseDoRequest(BaseAction.METHOD_POST, url, data);
  }

  promiseAcceptInvitation = (token) => {
    const url = `/api/register/accept-invitation/${token}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseDeleteUserFormClinic = (userId, clinicId) => {
    const url = `/api/user/remove-user-from-clinic/${userId}/${clinicId}`;
    return this.promiseDoRequest(BaseAction.METHOD_DELETE, url);
  }

  promiseGetInvitationsForClinic = (clinicId) => {
    const url = `/api/invitation/${clinicId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseCancelInvitation = (invitationId) => {
    const url = `/api/invitation/${invitationId}`;
    return this.promiseDoRequest(BaseAction.METHOD_DELETE, url);
  }

  promiseGetUserPlan = (userId) => {
    const url = `/api/user/plan/${userId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseActiveAccount = (token) => {
    const url = `/web/user/active-account/${token}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }
}

export default new UserAction();
