import BaseAction from './BaseAction';

class ScheduleAction extends BaseAction {

  static INSTANCE = null;

  static getInstance() {
    if (!ScheduleAction.INSTANCE) {
      ScheduleAction.INSTANCE = new ScheduleAction();
    }
    return ScheduleAction.INSTANCE;
  }

  classPrefix = 'Schedule';

  lastGetListRequestUrl = '';
  lastFrom = '';
  lastTill = '';



  getList = (from, till) => {
    const url = `/api/schedule/my/${from}/${till}`;
    this.request('get', url);
    this.lastGetListRequestUrl = url;
  };

  getListByClinic = (clinicId, from, till) => {
    const url = `/api/schedule/clinic/${clinicId}/${from}/${till}`;
    this.request('get', url);
    this.lastGetListRequestUrl = url;
  };

  getListByUser = (userId, from, till) => {
    this.lastFrom = from;
    this.lastTill = till;
    const url = `/api/schedule/user/${userId}/${from}/${till}`;
    this.request('get', url);
    this.lastGetListRequestUrl = url;
  };

  refreshLastGetListForNewUser = (userId) => {
    const url = `/api/schedule/user/${userId}/${this.lastFrom}/${this.lastTill}`;
    this.request('get', url);
  }

  refreshLastGetList = () => {
    this.request('get', this.lastGetListRequestUrl);
  }

  checkDates = (doctorId, from, till) => {
    const url = `/api/schedule/check/${doctorId}/${from}/${till}`;
    this.request('get', url);
  };

  postAction = (officeWorkerId, doctorId, clinicId, form) => {
    const url = `/api/schedule/add/${officeWorkerId}/${doctorId}/${clinicId}/${form.fields.userId.value || 0}?form=ScheduleEventForm&XDEBUG_SESSION_START=PHPSTORM&\\`;
    const data = {
      from: form.fields.dateTimeFrom.value,
      to: form.fields.dateTimeTo.value,
      name: form.fields.name.value,
      lastName: form.fields.lastName.value,
      description: form.fields.description.value,
      phoneNumber: form.fields.phoneNumber.value,
      optionalPhoneNumber: form.fields.optionalPhoneNumber.value || null,
      email: form.fields.email.value || '',
      personId: form.fields.personId.value,
      visitTypeId: +form.fields.visitType.value,
      patientId: +form.fields.userId.value || 0,
    };

    return this.promiseDoRequest('post', url, data, true);
  };

  resizeEvent = (eventId, event, direction) => {
    this.makeDispatch('SCHEDULE_SET_DND', {
      eventId,
      event: { ...event },
      direction
    });
  }

  updateEvent = (event) => {
    this.makeDispatch('SCHEDULE_UPDATE_EVENT', {
      event
    });
  }

  promiseGetEvent = (eventId) => {
    const url = `/api/schedule/${eventId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  };

  promiseCancelEvent = (eventId) => {
    const url = `/api/schedule/cancel/${eventId}`;
    return this.promiseDoRequest(BaseAction.METHOD_PATCH, url);
  };

  promiseApproveEvent = (eventId) => {
    const url = `/api/schedule/approve/${eventId}`;
    return this.promiseDoRequest(BaseAction.METHOD_PATCH, url);
  };

  promiseUpdateEvent = (eventId, eventData) => {
    const url = `/api/schedule/${eventId}`;

    const formData = {
      visitTypeId: null,
      ...eventData
    };

    return this.promiseDoRequest(BaseAction.METHOD_PUT, url, formData);
  };

  promiseGetScheduleEventByToken = (token) => {
    const url = `/web/schedule/get/for-approval-token/${token}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseApproveScheduleEventByToken = (scheduleEventId) => {
    const url = `/web/schedule/approve/${scheduleEventId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }

  promiseCancelScheduleEventByToken = (scheduleEventId) => {
    const url = `/web/schedule/cancel/${scheduleEventId}`;
    return this.promiseDoRequest(BaseAction.METHOD_GET, url);
  }
}

export default ScheduleAction.getInstance();
