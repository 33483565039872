import React, { Component } from 'react';
import { connect } from 'react-redux';
import ClinicForm from './ClinicForm';
import ClinicAction from '../../actions/ClinicAction';
import formSelector from '../../helpers/selectors/formSelector';
import AddNewClinicForm from '../../helpers/Forms/AddNewClinicForm';
import Loader from '../../components/Loader';

class EditClinic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      clinicIsLoading: false,
    };
  }

  updateClinic = (addNewClinicForm) => {
    const { match: { params: { clinicId } }, user } = this.props;
    this.setState({
      isSaving: true,
    });
    ClinicAction.promiseUpdateClinic(clinicId, addNewClinicForm)
      .then(() => {
        ClinicAction.getUserClinics(user.user.id);
        this.setState({
          isSaving: false,
        });
      })
      .catch(() => {
        this.setState({
          isSaving: false,
        });
      });
  };

  onFormLoaded = (addNewClinicForm) => {
    const { userClinics } = this.props;
    addNewClinicForm.fillFormFromData(userClinics.currentClinic);
  };

  render() {
    const { isSaving, clinicIsLoading } = this.state;

    if (clinicIsLoading) {
      return <Loader />;
    }

    return (
      <div>
        <ClinicForm
          isSaving={isSaving}
          onSubmitHandler={this.updateClinic}
          onFormLoaded={this.onFormLoaded}
          buttonLabel="Zapisz zmiany"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  addNewClinicForm: formSelector(state.forms, AddNewClinicForm.FORM_NAME),
  userClinics: state.userClinics,
});

export default connect(mapStateToProps)(EditClinic);
