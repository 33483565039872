import React, { Component } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import AttachmentListItem from './AttachmentListItem';
import './attachments-list.css';
import ConfirmDialog from '../ConfirmDialog';
import PatientAction from '../../actions/PatientAction';
import ConfirmDialogAction from '../../actions/ConfirmDialogAction';

class AttachmentsList extends Component {
  onDeleteAttachment = (data) => {
    const { patientId, onDeleteAttachment } = this.props;

    PatientAction.promiseDeleteAttachment(patientId, data.id).then(() => {
      onDeleteAttachment();
      ConfirmDialogAction.closeDialog();
    });
  }

  render() {
    const { attachments } = this.props;
    return (
      <Row>
        <Col xs={12}>
          <div className="attachments-list">
            <Table striped bordered condensed hover>
              <thead>
                <tr>
                  <td />
                  <td>Nazwa pliku</td>
                  <td>Dodał</td>
                  <td>Data dodania</td>

                  <td className="actions" />
                </tr>
              </thead>
              <tbody>
                {attachments.map(item => <AttachmentListItem key={item.id} attachment={item} />)}
              </tbody>
            </Table>
          </div>
        </Col>
        <ConfirmDialog
          name="confirm-delete-attachment"
          cancelTextButton="Anuluj"
          confirmTextButton="Usuń"
          onConfirm={this.onDeleteAttachment}
        >
          Na pewno chcesz usunąć ten załącznik?
        </ConfirmDialog>
      </Row>
    )
  }
}

export default AttachmentsList;
