import React, { Component } from 'react';
import './style.css';
import TimelineItem from './TimelineItem';

class HorizontalTimeline extends Component {

  onClickHandler = (item) => {
    const {onChange} = this.props;

    if (onChange && onChange instanceof Function) {
      onChange(item);
    }
  }

  render() {
    const {years, keyPrefix, current} = this.props;

    if (!years) {
      return null;
    }

    return (
      <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
        {years.map((year) => (<TimelineItem
          className={year.year === current ? 'active' : ''}
          key={`${keyPrefix}-${year.year}`}
          year={year.year} label={year.label}
          onClick={this.onClickHandler}
        />))}
      </div>
    );
  }
}

export default HorizontalTimeline;
