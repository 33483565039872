import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Jumbotron,
  Row,
  Form,
  Button,
  ButtonToolbar,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';

import LinkButton from '../Button/LinkButton';
import "./style.css";

class SearchPatientPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchWord: '',
      clinicId: 0,
    };
  }

  onSearchHandler = (searchWord) => {
    const { onSearch } = this.props;
    const { clinicId } = this.state;
    this.setState({
      searchWord,
    });
    onSearch({
      searchWord,
      clinicId,
    });
  }

  onChangeClinicHandler = (clinicId) => {
    const { onSearch } = this.props;
    const { searchWord } = this.state;
    this.setState({
      clinicId: +clinicId,
    });
    onSearch({
      searchWord,
      clinicId,
    });
  }

  onSearchCallback = () => {
    const { onSearch } = this.props;
    const { searchWord, clinicId } = this.state;
    if (onSearch) {
      onSearch({
        searchWord,
        clinicId,
      });
    }
  }

  render() {
    const { searchWord } = this.state;

    return (
      <div className="patient-search">
        <Row>
          <Col xs={8} md={{ span: 7, offset: 2 }}>
            <Form.Group>
              <Form.Control
                className="search-patient-bar"
                type="text"
                placeholder="Wyszukaj pacjenta wpisując Imie/Nazwisko/PESEL/E-mail"
                value={searchWord}
                onChange={e => this.onSearchHandler(e.target.value)}

              />
            </Form.Group>
          </Col>
          <Col xs={4} md={3}>
            <LinkButton
              className="add-patient-btn"
              to="/panel/patients/register">
              <i className="fas fa-user-plus fa-lg" />
              &nbsp;
              Dodaj pacjenta
            </LinkButton>
          </Col>
        </Row>
      </div>

    );
  }
}

SearchPatientPanel.propTypes = {
  clinics: PropTypes.array,
  onSearch: PropTypes.func.isRequired,
  rootRow: PropTypes.bool,
};

export default SearchPatientPanel;
