import React, { Component } from 'react';
import { Col, Jumbotron } from 'react-bootstrap';
import UserAction from '../../actions/UserAction';
import OnlineActiveSelect from '../OnlineActiveSelect';
import UserEnum from '../../helpers/Enum/UserEnum';

class SearchPatient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  onSearchCallback = (word) => {
    UserAction.promiseSearchPatient(
      word,
      '',
      '',
      '',
      '',
      UserEnum.TYPE_PATIENT,
    ).then((response) => {
      this.setState({
        items: response.data,
      });
    });
  };

  onSelectCallback = (userItem) => {
    const { onSelect } = this.props;
    if (typeof onSelect === 'function') {
      onSelect(userItem);
    }
  };

  render() {
    const { items } = this.state;
    return (
      <Jumbotron>
        <Col md={12} xs={12}>
          <OnlineActiveSelect
            serachCallback={this.onSearchCallback}
            items={items}
            name="name;lastName;personId"
            onSelect={this.onSelectCallback}
          />
        </Col>
      </Jumbotron>
    );
  }
}

export default SearchPatient;
