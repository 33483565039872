import React, { Component } from 'react';
import TableHeader from './TableHeader';
import TableItems from './TableItems';

class TableView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
    };
    this.selectedItems = [];
  }

  onSelectAllHandler = (isSelected) => {
    this.setState({
      selectAll: isSelected,
    });
    if (isSelected) {
      this.selectedItems = [...this.props.items];
    } else {
      this.selectedItems = [];
    }
    // trigger event from parent
    this.props.selectAll(this.selectedItems);
  };

  onSingleSelectHandler = (state, item) => {
    const newSelectedList = this.selectedItems.filter(selectedItem => selectedItem.id !== item.id);
    if (state) {
      newSelectedList.push(item);
    }
    this.selectedItems = newSelectedList;

    // trigger event from parent
    this.props.selectAll(this.selectedItems);
  };

  hasButtons = () => (this.props.buttons && this.props.buttons.length);



  render() {
    const {
      selectAll,
      columns,
      items,
      buttons,
      onRowStyle,
      onRowClick,
      afterTable,
    } = this.props;

    return (
      <div>
        <table className="table table-hover">
          <thead>
            <TableHeader
              selectAll={selectAll}
              header={columns}
              onSelect={this.onSelectAllHandler}
              hasButtons={this.hasButtons()}
            />
          </thead>
          <TableItems
            selectAll={selectAll}
            columns={columns}
            items={items}
            isAllSelected={this.state.selectAll}
            onSelect={this.onSingleSelectHandler}
            buttons={buttons}
            onRowStyle={onRowStyle}
            onRowClick={onRowClick}
          />
        </table>
        {afterTable}
      </div>
    );
  }
}
export default TableView;
